import {
  computed,
} from '@vue/composition-api'

import state from './state'

export default () => {
  const turnOnModal = () => {
    state.changeStepModal.isVisible = true
  }
  const turnOffModal = () => {
    state.changeStepModal.isVisible = false
  }
  const reduceStep = (step, manageStep) => {
    if (step === 'PROGRESSED') {
      return 'PROGRESSED'
    }
    if (step === 'STOP') {
      return 'STOP'
    }
    if (step === 'PENDING') {
      return 'PENDING'
    }
    if (manageStep == null || manageStep === 'SELECT') {
      return 'RECRUIT'
    }
    if (manageStep === 'GUIDE') {
      return 'GUIDE'
    }
    if (manageStep === 'DELIVERY') {
      return 'DELIVERY'
    }
    if (manageStep === 'PRE_POSTING') {
      return 'PRE_POSTING'
    }
    if (manageStep === 'POSTING_RATING') {
      return 'POSTING_RATING'
    }
    if (manageStep === 'STAT_ESTIMATE') {
      return 'STAT_ESTIMATE'
    }
    return 'NOT_MATCHED'
  }
  const revertStep = () => {
    state.compt.step = state.changeStepModal.prevStep
    state.compt.manage_step = state.changeStepModal.prevManageStep
  }
  const changeStep = () => {
    const value = state.changeStepModal.inputValue
    if (value === 'RECRUIT') {
      state.compt.step = 'RECRUIT'
      state.compt.manage_step = 'SELECT'
    } else if (value === 'GUIDE') {
      state.compt.step = 'SELECTED'
      state.compt.manage_step = 'GUIDE'
    } else if (value === 'DELIVERY') {
      state.compt.step = 'SELECTED'
      state.compt.manage_step = 'DELIVERY'
    } else if (value === 'PRE_POSTING') {
      state.compt.step = 'SELECTED'
      state.compt.manage_step = 'PRE_POSTING'
    } else if (value === 'POSTING_RATING') {
      state.compt.step = 'SELECTED'
      state.compt.manage_step = 'POSTING_RATING'
    } else if (value === 'STAT_ESTIMATE') {
      state.compt.step = 'SELECTED'
      state.compt.manage_step = 'STAT_ESTIMATE'
    } else if (value === 'PROGRESSED') {
      state.compt.step = 'PROGRESSED'
      state.compt.manage_step = 'STAT_ESTIMATE'
    } else if (value === 'PENDING') {
      state.compt.step = 'PENDING'
    }
  }

  const savedReducedStep = computed(() => reduceStep(state.savedCompt.step, state.savedCompt.manage_step))

  // const getUpdateStepContent = (fromStep, toStep) => {
  //   if(fromStep === 'RECRUIT') {
  //     if(toStep === 'PROGRESSED') {
  //       return [
  //         '모든 사전원고는 '
  //       ]
  //     }
  //   }
  // }

  const inputStep = value => {
    if (state.isLoadingCompt) {
      return
    }
    state.changeStepModal.prevStep = state.compt.step
    state.changeStepModal.prevManageStep = state.compt.manage_step
    state.changeStepModal.inputValue = value
    changeStep()

    if (savedReducedStep.value !== value) {
      turnOnModal()
    }
  }

  return {
    turnOnModal,
    turnOffModal,
    reduceStep,
    revertStep,
    inputStep,
    changeStep,
  }
}
