<template>
  <BCard
    :border-variant="isHover ? 'primary' : 'secondary'"
    style="cursor: pointer"
    @click="$emit('click', value)"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div
      v-for="field in fields"
      :key="field"
    >
      {{ field }}: {{ value[field] }}
    </div>
  </BCard>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api"
import {
  BCard,
} from "bootstrap-vue"

export default defineComponent({
  components: {
    BCard,
  },
  props: {
    value: {
      type: Object,
    },
    fields: {
      type: Array,
    },
  },
  setup() {
    const isHover = ref(false)
    const mouseEnter = () => {
      isHover.value = true
    }
    const mouseLeave = () => {
      isHover.value = false
    }
    return {
      isHover,
      mouseEnter,
      mouseLeave,
    }
  },
})
</script>
