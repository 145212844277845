<template>
  <TagbyModal
    :isVisible="state.releasePendingModal.isVisible"
    :isBusy="state.releasePendingModal.isSaving"
    title="보류 해제"
    @hidden="turnOffModal"
    @ok="releasePending"
  >
    <div
      v-if="isEndless"
      class="text-info mb-1"
    >
      신청자에게 별다른 알림이 전송되지 않는 기능입니다.
    </div>
    <div v-else>
      <BFormGroup>
        <BFormCheckbox
          v-model="state.releasePendingModal.hasNoti"
          class="mb-1"
        >
          <span class="text-info">
            {{ isRecruitStep ? '신청한 모든 인원에게 알림이 전송됩니다. (진행완료한 인원 제외)' : '선정된 인원에게만 알림이 전송됩니다. (진행완료한 인원 제외)' }}
          </span>
        </BFormCheckbox>
      </BFormGroup>
    </div>
  </TagbyModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue"
import TagbyModal from "@/components/TagbyModal.vue"
import state from "../../state"
import useReleasePendingModal from "../../hooks/useReleasePendingModal"

export default defineComponent({
  components: {
    TagbyModal,
    BFormGroup,
    BFormCheckbox,
  },
  setup() {
    const {
      turnOffModal,
      releasePending,
      isEndless,
      isRecruitStep,
    } = useReleasePendingModal()

    return {
      state,
      turnOffModal,
      releasePending,
      isEndless,
      isRecruitStep,
    }
  },
})
</script>
