<template>
  <BCard title="리워드">
    <BTable
      :items="rewardItems"
      :fields="fields"
      small
      show-empty
    >
      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            icon="CheckIcon"
            text="수정"
            :disabled="disalbedUpdateButton"
            @click="turnOnSidebar(data.item)"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'
import { useRewardItems } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      rewardItems,
      fields,
      disalbedUpdateButton,
      turnOnSidebar,
    } = useRewardItems()

    return {
      rewardItems,
      fields,
      disalbedUpdateButton,
      turnOnSidebar,
    }
  },
})
</script>
