<template>
  <TagbyActionButtonV2
    text="알림 전송"
    variant="outline-warning"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import {
  useSendBulkNotification,
} from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useSendBulkNotification()

    return {
      turnOnSidebar,
    }
  },
})
</script>
