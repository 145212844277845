<template>
  <div>
    <TgFilter
      :searchText="searchText"
      :stateFilter="stateFilter"
      :pointStateFilter="pointStateFilter"
      @inputSearchText="inputSearchText"
      @inputState="inputStateFilter"
      @inputPointState="inputPointStateFilter"
    />
    <TgButtons
      :selectable="selectable"
      :fieldGroupList="fieldGroupList"
      :fieldsId="fieldsId"
      :selectedItemLength="selectedItemLength"
      :actions="actions"
      @inputSelectable="inputSelectable"
      @selectFieldGroup="selectFieldGroup"
    />
    <TgTable
      ref="tgTableRef"
      :selectable="selectable"
      :fields="fields"
      :items="items"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@vue/composition-api"
import {
  BCard,
  BTable,
} from "bootstrap-vue"
import TgFilter from "./TgFilter.vue"
import TgTable from "./TgTable.vue"
import TgButtons from "./TgButtons.vue"
import state from "../../state"
import useUpdateInvoiceAmount from "../../hooks/useUpdateInvoiceAmount"
import usePayInvoiceSidebar from "../../hooks/usePayInvoiceSidebar"
import useRefundInvoiceModal from "../../hooks/useRefundInvoiceModal"
import useAddPointModal from "../../hooks/useAddPointModal"
import useRevertPointModal from "../../hooks/useRevertPointModal"
import useUpdateInvoicePayDateSidebar from "../../hooks/useUpdateInvoicePayDateSidebar"

export default defineComponent({
  components: {
    BCard,
    BTable,
    TgFilter,
    TgTable,
    TgButtons,
  },
  setup() {
    const selectable = ref(true)
    const fieldGroupListTemplate = [
      {
        text: "정산정보",
        value: "invoice",
        fields: [
          { key: "idx", label: "idx" },
          { key: "username", label: "username" },
          { key: "amount", label: "결제금액" },
          { key: "expected_pay_date", label: "결제날짜" },
          { key: "state", label: "결제상태" },
          { key: "point_amount", label: "적립금액" },
          { key: "point_addition_expected_at", label: "적립예정날짜" },
          { key: "point_state", label: "적립상태" },
        ],
      },
      {
        text: "날짜정보",
        value: "date",
        fields: [
          { key: "idx", label: "idx" },
          { key: "username", label: "username" },
          { key: "expected_pay_date", label: "결제기준일" },
          { key: "point_addition_expected_at", label: "적립예정날짜" },
          { key: "point_created_at", label: "포스팅확인완료일" },
        ],
      },
      {
        text: "INF정보",
        value: "inf",
        fields: [
          { key: "idx", label: "idx" },
          { key: "username", label: "username" },
          { key: "has_ssn", label: "주민번호인증" },
          { key: "inf", label: "inf" },
          { key: "inf_phone", label: "inf_phone" },
          { key: "inf_caution", label: "inf_caution" },
        ],
      },
    ]

    const fieldGroupList = computed(() => {
      if (!selectable.value) {
        return fieldGroupListTemplate
      }
      return fieldGroupListTemplate.map(fieldGroup => ({
        text: fieldGroup.text,
        value: fieldGroup.value,
        fields: [
          { key: "checkbox" },
          ...fieldGroup.fields,
        ],
      }))
    })

    const fieldsId = ref(fieldGroupList.value[0].value)
    const fields = computed(() => fieldGroupList.value.find(fieldGroup => fieldGroup.value === fieldsId.value).fields)

    const searchText = ref()
    const stateFilter = ref([])
    const pointStateFilter = ref([])

    const tgTableRef = ref()

    const selectedItemLength = computed(() => tgTableRef.value?.getSelectedItemLength() ?? 0)

    const items = computed(() => state.invoiceList.filter(e => {
      if (!searchText.value) {
        return true
      }
      if (e.sns != null && e.sns.username.includes(searchText.value)) {
        return true
      }
      if (e.sns != null && e.sns.uid.includes(searchText.value)) {
        return true
      }
      if (e.inf != null && e.inf.name.includes(searchText.value)) {
        return true
      }
      if (e.inf != null && e.inf.idx.toString() === searchText.value) {
        return true
      }
      return false
    }).filter(e => {
      if (!stateFilter.value.length) {
        return true
      }
      return stateFilter.value.includes(e.state)
    }).filter(e => {
      if (!pointStateFilter.value.length) {
        return true
      }
      return pointStateFilter.value.includes(e.point_state)
    }))

    const inputSearchText = value => {
      searchText.value = value
    }
    const inputStateFilter = value => {
      stateFilter.value = value
    }
    const inputPointStateFilter = value => {
      pointStateFilter.value = value
    }
    const inputSelectable = value => {
      selectable.value = value
    }
    const selectFieldGroup = value => {
      fieldsId.value = value
    }

    const {
      turnOnSidebar: turnOnUpdateAmountSidebar,
      getIsAvailable: getAvailableUpdateAmount,
    } = useUpdateInvoiceAmount()

    const {
      turnOnSidebar: turnOnPayInvoiceSidebar,
      getIsAvailable: getIsAvailablePayInvoice,
    } = usePayInvoiceSidebar()

    const {
      turnOnModal: turnOnRefundInvoiceModal,
      getIsAvailable: getIsAvailableRefundInvoice,
    } = useRefundInvoiceModal()

    const {
      turnOnModal: turnOnAddPointModal,
      getIsAvailable: getIsAvailableAddPoint,
    } = useAddPointModal()

    const {
      turnOnModal: turnOnRevertPointModal,
      getIsAvailable: getIsAvailableRevertPoint,
    } = useRevertPointModal()

    const {
      turnOnSidebar: turnOnUpdateInvoicePayDateSidebar,
      getIsAvailable: getIsAvailableUpdateInvoicePayDate,
    } = useUpdateInvoicePayDateSidebar()

    const actions = computed(() => ([
      {
        title: "리워드 금액 변경",
        disabled: !getAvailableUpdateAmount(tgTableRef.value?.getSelectedItems()),
        func: turnOnUpdateAmountSidebar,
        args: tgTableRef.value?.getSelectedItems(),
        tooltip: (
          "일반 사용자 소유 캠페인의 경우, 결제가 완료된 상태라면 결제금액 수정을 할 수 없습니다. "
          + "태거 소유 캠페인의 경우, 인플루언서에게 포인트 적립완료하기 전까지는 결제금액 수정이 가능합니다."
        ),
      },
      {
        title: "리워드 결제 실행",
        disabled: !getIsAvailablePayInvoice(tgTableRef.value?.getSelectedItems()),
        func: turnOnPayInvoiceSidebar,
        args: tgTableRef.value?.getSelectedItems(),
        tooltip: (
          "일반 사용자 소유 캠페인의 경우, 등록된 카드로 결제를 즉시 실행합니다. "
          + "태거 소유 캠페인의 경우, 실제 결제는 실행하지 않으며 상태만 결제완료로 변경합니다."
        ),
      },
      {
        title: "리워드 결제 취소",
        disabled: !getIsAvailableRefundInvoice(tgTableRef.value?.getSelectedItems()),
        func: turnOnRefundInvoiceModal,
        args: tgTableRef.value?.getSelectedItems(),
        tooltip: (
          "사용자의 결제된 금액을 환불하는 기능입니다. "
          + "인플루언서의 적립 예정 및 완료 포인트는 취소하지 않으며, 정산 데이터의 상태는 \"결제대기\"로 수정됩니다. "
          + "해당 기능을 실행한 후 시간이 흐르면 자동으로 재결제가 실행되므로 \"결제날짜변경\"을 미리 해두셔야 합니다. "
        ),
      },
      // {
      //   title: "리워드 완전 취소",
      //   disabled: true,
      //   func: () => {},
      //   tooltip: (
      //     "[오픈예정] 일반 사용자 소유 캠페인의 경우, 등록된 카드로 환불을 실행합니다. "
      //     + "태거 소유 캠페인의 경우, 실제 환불 프로세스는 진행하지 않습니다. "
      //     + "지급 예정인 포인트는 취소처리하며, 정산 데이터의 상태는 \"결제취소\"로 수정됩니다. "
      //   ),
      // },
      {
        title: "포인트 지급 실행",
        disabled: !getIsAvailableAddPoint(tgTableRef.value?.getSelectedItems()),
        func: turnOnAddPointModal,
        args: tgTableRef.value?.getSelectedItems(),
        tooltip: (
          "포인트 지급이 이뤄지지 않은 데이터에 대해 포인트 지급을 수행합니다. "
          + "포인트 지급이 적립을 뜻하지는 않습니다. 포인트 적립 예정날짜에 맞춰 적립이 이뤄집니다. "
        ),
      },
      {
        title: "포인트 지급 취소",
        disabled: !getIsAvailableRevertPoint(tgTableRef.value?.getSelectedItems()),
        func: turnOnRevertPointModal,
        args: tgTableRef.value?.getSelectedItems(),
        tooltip: (
          "\"적립예정\" 상태의 포인트를 취소처리합니다. \"적립완료\"가 된 포인트는 취소하지 못합니다."
          + "포스팅을 \"확인완료\" 처리할 때만 포인트 지급이 생성되므로 만약 포스팅을 확인완료 처리할 일이 없다면, "
          + "따로 자동으로 인플루언서에게 포인트를 재지급하지 않습니다. "
          + "만약 포인트 지급 취소후에 따로 포스팅 확인완료 처리없이 포인트 지급이 필요하다면, "
          + "인플루언서 상페 페이지에서 \"포인트 관리\" 기능을 이용해주세요."
        ),
      },
      {
        title: "결제 날짜 변경",
        disabled: !getIsAvailableUpdateInvoicePayDate(tgTableRef.value?.getSelectedItems()),
        func: turnOnUpdateInvoicePayDateSidebar,
        args: tgTableRef.value?.getSelectedItems(),
        tooltip: (
          "\"결제예정\" 또는 \"결제실패\" 상태의 데이터만 날짜 변경 가능합니다."
          + "특히 \"결제실패\" 상태의 데이터는 \"결제예정\" 상태로 변경됩니다."
        ),
      },
    ]))

    return {
      tgTableRef,
      selectable,
      fieldGroupList,
      fieldsId,
      searchText,
      stateFilter,
      pointStateFilter,
      fields,
      items,
      selectedItemLength,
      inputSearchText,
      inputStateFilter,
      inputPointStateFilter,
      inputSelectable,
      selectFieldGroup,
      actions,
    }
  },
})
</script>
