<template>
  <BCard title="질문">
    <BTable
      :items="questions"
      :fields="fields"
      small
      show-empty
    />
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import { useQuestions } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BTable,
  },
  setup() {
    const {
      fields,
      questions,
    } = useQuestions()

    return {
      fields,
      questions,
    }
  },
})
</script>
