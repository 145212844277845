<template>
  <BListGroup>
    <BListGroupItem
      v-for="option in options"
      :key="option.idx"
      disabled
    >
      {{ option.option_type }} - {{ option.option_name }} - {{ option.option_value }}
    </BListGroupItem>
  </BListGroup>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    options: {
      type: Array,
    },
  },
  setup() {

  },
})
</script>
