import {
  ref,
  computed,
} from "@vue/composition-api"
import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"
import useFetch from "./useFetch"
import getStateOrigin from "../stateOrigin"
import {
  any,
} from "@/utils/value"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getItemList,
    getQuestionList,
    getApplyList,
    getTimelineList,
  } = useFetch()

  const turnOnSidebar = () => {
    state.addApplySimpleRecruitSidebar = getStateOrigin().addApplySimpleRecruitSidebar
    getQuestionList().then(() => {
      state.addApplySimpleRecruitSidebar.answerList = state.questionList.map(e => ({
        question_idx: e.idx,
        is_required: e.is_required,
        question: e.question,
        answer: null,
      }))
    })
    getItemList().then(() => {
      state.addApplySimpleRecruitSidebar.selectedRewardList = state.itemList.map(e => ({
        item_idx: e.idx,
        offer_item: e.offer_item,
        price: e.price,
        title: e.title,
        online_link: e.online_link,
        visit_company_name: e.visit_company_name,
        visit_address_basic: e.visit_address_basic,
        visit_address_detail: e.visit_address_detail,
        is_reward: e.is_reward,
        is_delivery: e.is_delivery,
        option_list: (e.option_list ?? []).map(o => ({
          ...o,
          select_value: null,
        })),
      }))
    })
    state.addApplySimpleRecruitSidebar.isVisible = true
  }
  const turnOffSidebar = () => {
    state.addApplySimpleRecruitSidebar.isVisible = false
  }

  const searchInfList = () => {
    state.addApplySimpleRecruitSidebar.infList = []
    state.addApplySimpleRecruitSidebar.destinationList = []
    state.addApplySimpleRecruitSidebar.selectedInf = null
    state.addApplySimpleRecruitSidebar.selectedDestination = null
    state.addApplySimpleRecruitSidebar.isLoading = true
    return api.searchInfList({
      search_text: state.addApplySimpleRecruitSidebar.searchText,
    }).then(response => {
      state.addApplySimpleRecruitSidebar.infList = response.data.data
    }).catch(() => {
      makeToast("danger", "인플루언서를 검색하는데 실패했습니다")
    }).finally(() => {
      state.addApplySimpleRecruitSidebar.isLoading = false
    })
  }

  const searchInfDestinationList = () => {
    state.addApplySimpleRecruitSidebar.isLoading = true
    return api.searchInfDestinationList({
      inf_idx: state.addApplySimpleRecruitSidebar.selectedInf.idx,
    }).then(response => {
      state.addApplySimpleRecruitSidebar.destinationList = response.data.data
    }).catch(() => {
      makeToast("danger", "인플루언서의 주소지를 검색하는데 실패했습니다")
    }).finally(() => {
      state.addApplySimpleRecruitSidebar.isLoading = false
    })
  }

  const selectInf = infObj => {
    state.addApplySimpleRecruitSidebar.infList = []
    state.addApplySimpleRecruitSidebar.selectedInf = infObj
    return searchInfDestinationList()
  }

  const selectInfDestination = destinationObj => {
    state.addApplySimpleRecruitSidebar.selectedDestination = destinationObj
  }

  const addApplySimpleRecruit = () => {
    state.addApplySimpleRecruitSidebar.isSaving = true

    return api.addApplySimpleRecruit({
      campaign_idx: state.idx,
      inf_idx: state.addApplySimpleRecruitSidebar.selectedInf.idx,
      inf_name: state.addApplySimpleRecruitSidebar.selectedInf.name,
      inf_birth: state.addApplySimpleRecruitSidebar.selectedInf.birth,
      destination_idx: state.addApplySimpleRecruitSidebar.selectedDestination?.idx,
      answer_list: state.addApplySimpleRecruitSidebar.answerList.map(e => ({
        question_idx: e.question_idx,
        answer: e.answer,
      })),
      selected_reward_list: state.addApplySimpleRecruitSidebar.selectedRewardList.filter(
        e => (e.option_list != null),
      ).reduce((p, c) => [...p, ...c.option_list], []).map(e => ({
        item_idx: e.item_idx,
        option_idx: e.idx,
        select_value: e.select_value,
      })),
    }).then(() => {
      getTimelineList()
      getApplyList()
      turnOffSidebar()
      makeToast("primary", "신청자를 추가하는데 성공했습니다")
    }).catch(() => {
      makeToast("danger", "신청자를 추가하는데 실패했습니다")
    }).finally(() => {
      state.addApplySimpleRecruitSidebar.isSaving = false
    })
  }

  const mustSelectDestination = computed(() => any(state.itemList.map(e => e.is_delivery)))
  const destinationOptions = computed(() => state.addApplySimpleRecruitSidebar.destinationList.map(e => ({
    text: e.address_total,
    value: e,
  })))
  const isSelectedInf = computed(() => state.addApplySimpleRecruitSidebar.selectedInf != null)
  const hasQuestion = computed(() => state.questionList.length > 0)
  const hasItem = computed(() => state.itemList.length > 0)

  return {
    turnOnSidebar,
    turnOffSidebar,
    addApplySimpleRecruit,
    searchInfList,
    selectInf,
    selectInfDestination,
    mustSelectDestination,
    destinationOptions,
    hasQuestion,
    hasItem,
    isSelectedInf,
  }
}
