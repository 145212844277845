<template>
  <BModal
    :visible="visible"
    ok-title="닫기"
    ok-only
    centered
    title="원고 다운로드"
    @hidden="turnOffModal"
  >
    <div class="mb-1">
      {{ message }}
    </div>
    <div v-if="downloadingStep === 0">
      <BProgress
        :max="totalCount"
        class="progress-bar-info"
      >
        <BProgressBar
          :value="downloadedCount"
          :label="`${((downloadedCount / totalCount) * 100).toFixed(2)}%`"
          variant="primary"
        />
      </BProgress>
    </div>
    <div v-else-if="downloadingStep === 1">
      <div class="text-center">
        <BSpinner
          variant="primary"
          label="Text Centered"
        />
      </div>
    </div>
  </BModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BModal,
  BProgress,
  BProgressBar,
  BSpinner,
} from 'bootstrap-vue'
import { useDownloadPrepostingImg } from '../viewModel'

export default defineComponent({
  components: {
    BModal,
    BProgress,
    BProgressBar,
    BSpinner,
  },
  setup() {
    const {
      visibleDownloadPrePostingImgModal: visible,
      countOfDownloadedPrePosting: downloadedCount,
      messageOfDownloadingPrePostingImageZip: message,
      totalCountOfDownloadingPrePosting: totalCount,
      downloadingStepOfPrePostingImageZip: downloadingStep,
      turnOffModal,
    } = useDownloadPrepostingImg()

    return {
      visible,
      downloadedCount,
      message,
      totalCount,
      downloadingStep,
      turnOffModal,
    }
  },
})
</script>
