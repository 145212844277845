<template>
  <TagbySidebarWithButton
    title="캠페인 복사 및 신청자 이동"
    :visible="visibleMoveAppliersSidebar"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="moveAppliers"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <div class="text-warning">
      - 자동으로 캠페인이 복사되어 생성됩니다.
    </div>
    <div class="text-info">
      - 복사된 캠페인은 "캠페인 이관" 기능을 통해 다른 매니저에게 이관해주세요.
    </div>
    <div class="text-info">
      - 복사된 캠페인으로 이동시킬 신청자를 선택한 후 확인 버튼을 눌러주세요.
    </div>
    <div class="text-info mb-1">
      - 신청자에게 별다른 알림이 전송되지 않는 기능입니다.
    </div>
    <BTable
      ref="moveAppliersTableRef"
      :fields="tableFields"
      :items="mappedApplies"
      selectable
      show-empty
    >
      <template #cell(username)="data">
        <TagbySnsColumn :sns="data.item.sns" />
      </template>
    </BTable>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import {
  useMoveAppliers,
} from '../viewModel'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    BTable,
    TagbySnsColumn,
  },
  setup() {
    return {
      ...useMoveAppliers(),
    }
  },
})
</script>
