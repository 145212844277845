<template>
  <TagbyActionButtonV2
    v-if="getters.isSnsCampaign"
    text="신청자 추가"
    variant="outline-secondary"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionButtonV2 from "@/components/TagbyActionButtonV2.vue"
import { useAddApply } from "../../viewModel"
import getters from "../../getters"

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const { turnOnSidebar } = useAddApply()
    return {
      getters,
      turnOnSidebar,
    }
  },
})
</script>
