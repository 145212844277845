import {
  computed,
} from "@vue/composition-api"
import moment from "moment"
import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"
import useFetch from "./useFetch"
import getStateOrigin from "../stateOrigin"
import useErrorReason from "../useErrorReason"
import { any } from "@/utils/value"
import useResolveValue from "@/utils/resolveValue"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getTimelineList,
    getInvoiceList,
  } = useFetch()

  const {
    resolveUtcDateTimeTz,
  } = useResolveValue()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErrorReason()

  const turnOnModal = items => {
    state.selectedItems = items
    state.addPointModal.isNowAdd = false
    const now = moment().add(1, "months")
    state.addPointModal.addExpectedAt = resolveUtcDateTimeTz(now)
    state.addPointModal.isVisible = true
  }
  const turnOffModal = () => {
    state.addPointModal.isVisible = false
  }
  const addPoint = () => {
    state.addPointModal.isSaving = true
    return api.addPoint({
      campaign_idx: state.idx,
      invoice_idx_list: state.selectedItems.map(e => e.idx),
      is_now_add: state.addPointModal.isNowAdd,
      add_expected_at: state.addPointModal.addExpectedAt,
    }).then(() => {
      makeToast("primary", "포인트 지급 실행에 성공했습니다")
      turnOffModal()
      getInvoiceList()
      getTimelineList()
    }).catch(err => {
      makeToast("danger", "포인트 지급 실행에 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.addPointModal.isSaving = false
    })
  }

  const getIsAvailable = items => {
    if (items == null || items.length === 0) {
      return false
    }
    if (any(items.map(e => e.point_idx != null))) {
      return false
    }
    return true
  }

  return {
    turnOnModal,
    turnOffModal,
    addPoint,
    getIsAvailable,
  }
}
