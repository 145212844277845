<template>
  <BTable
    responsive
    small
    :fields="fields"
    :items="mappedDeliveries"
    :filter="filterText"
    style="overflow-x: visible;"
  >
    <template #cell(username)="data">
      <TagbySnsColumn
        v-if="data.item.sns != null"
        :sns="data.item.sns"
      />
      <div v-else>
        {{ data.item.inf.name }}
      </div>
    </template>

    <template #cell(inf)="data">
      <InfLinkColumn :inf="data.item.inf" />
    </template>

    <template #cell(address)="data">
      {{ resolveAddress(data.item) }}
    </template>

    <template #cell(inf_phone)="data">
      {{ data.item.inf.phone }}
    </template>

    <template #cell(inf_caution)="data">
      {{ data.item.inf.caution_count }}
    </template>

    <template #cell(actions)="data">
      <TagbyTableAction>
        <TagbyTableActionItem
          icon="XSquareIcon"
          text="송장정보 초기화"
          @click="turnOnResetModal(data.item)"
        />
        <TagbyTableActionItem
          icon="CheckSquareIcon"
          text="송장정보 수정"
          @click="turnOnUpdateSidebar(data.item)"
        />
      </TagbyTableAction>
    </template>
  </BTable>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BTable,
} from "bootstrap-vue"
import {
  useDeliveries,
  useResetDelivery,
  useUpdateDelivery,
} from "../../viewModel"
import TagbySnsColumn from "@/components/TagbySnsColumn.vue"
import InfLinkColumn from "../../components/InfLinkColumn.vue"
import TagbyTableAction from "@/components/TagbyTableAction.vue"
import TagbyTableActionItem from "@/components/TagbyTableActionItem.vue"

export default defineComponent({
  components: {
    BTable,
    TagbySnsColumn,
    InfLinkColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      mappedDeliveries,
      fields,
      filterText,
    } = useDeliveries()

    const {
      turnOnModal: turnOnResetModal,
    } = useResetDelivery()

    const {
      turnOnSidebar: turnOnUpdateSidebar,
    } = useUpdateDelivery()

    const resolveAddress = item => {
      if (!item.address) {
        return null
      }
      return `${item.address} ${item.address_detail}`
    }

    return {
      mappedDeliveries,
      fields,
      filterText,
      turnOnResetModal,
      turnOnUpdateSidebar,
      resolveAddress,
    }
  },
})
</script>
