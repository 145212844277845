<template>
  <TagbyActionButtonV2
    text="끌어올리기"
    variant="outline-secondary"
    @click="turnOnModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import { usePullUp } from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnModal,
    } = usePullUp()

    return {
      turnOnModal,
    }
  },
})
</script>
