<template>
  <TagbyActionButtonV2
    variant="outline-warning"
    text="채팅 일괄 전송"
    :isValid="isActive"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import {
  useSendBulkChat,
} from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      isActive,
      turnOnSidebar,
    } = useSendBulkChat()

    return {
      isActive,
      turnOnSidebar,
    }
  },
})
</script>
