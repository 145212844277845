<template>
  <TagbyActionButtonV2
    text="보류 해제"
    variant="outline-secondary"
    :isValid="isValid"
    @click="turnOnModal"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionButtonV2 from "@/components/TagbyActionButtonV2.vue"
import useReleasePendingModal from "../../hooks/useReleasePendingModal"

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      isValid,
      turnOnModal,
    } = useReleasePendingModal()
    return {
      isValid,
      turnOnModal,
    }
  },
})
</script>
