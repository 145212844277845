<template>
  <TagbyDangerModal
    :visible="state.changeStepModal.isVisible"
    title="단계수정 주의사항"
    @hidden="turnOffModal"
    @cancel="revertStep"
  >
    <div class="text-info">
      캠페인저장을 하기전에 아래 내용들을 확인하십시오.
    </div>

    <div
      v-for="(text, i) in state.changeStepModal.content"
      :key="i"
      class="text-danger"
    >
      {{ text }}
    </div>
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import state from '../../state'
import useInputStep from '../../useInputStep'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      turnOffModal,
      changeStep,
      revertStep,
    } = useInputStep()

    return {
      state,
      turnOffModal,
      changeStep,
      revertStep,
    }
  },
})
</script>
