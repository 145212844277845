<template>
  <BCard>
    <DFilter />
    <DTable />
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
} from 'bootstrap-vue'
import DFilter from './Filter.vue'
import DTable from './Table.vue'

export default defineComponent({
  components: {
    BCard,
    DFilter,
    DTable,
  },
  setup() {

  },
})
</script>
