import {
  computed,
  reactive,
} from "@vue/composition-api"

import state from "./state"
import { indexing } from "@/utils/value"

const infByIdx = computed(() => indexing(state.createSuggestion.infList, "idx"))
const activeItemsForCreateSuggestion = computed(() => state.createSuggestion.snsList.filter(sns => ["ACTIVE", "RELOGIN"].includes(sns.state)))
const itemsForCreateSuggestion = computed(() => activeItemsForCreateSuggestion.value.map(sns => {
  const inf = infByIdx[sns.inf_idx]
  return {
    ...sns,
    infName: inf?.name,
    infPhone: inf?.phone,
    isBlind: inf?.is_blind,
  }
}))
const availableSnsChannels = computed(() => {
  const snsChannel = state.compt.sns_channel
  if (snsChannel.includes("INSTAGRAM")) {
    return ["INSTA_BASIC", "INSTA_BIZ"]
  }
  if (snsChannel.includes("BLOG")) {
    return ["NAVER_BLOG"]
  }
  if (snsChannel.includes("YOUTUBE")) {
    return ["YOUTUBE"]
  }
})
const isValidForCreateSuggestion = computed(() => state.createSuggestion.selectedSnsIdxList.length > 0)

const targetItemType = computed(() => {
  if (state.inputCategory.targetItem.offer_item === "PRODUCT") {
    return "제품"
  }
  if (state.inputCategory.targetItem.offer_item === "ONLINE") {
    return "온라인"
  }
  if (state.inputCategory.targetItem.offer_item === "OFFLINE") {
    return "오프라인"
  }
  return ""
})

const targetCategoryList = computed(() => state.categoryList.filter(c => c.class_1 === targetItemType.value).map(item => ({
  label: item.class_2, value: item.idx,
})))

const isSnsCampaign = computed(() => state.compt.type === "COMPT")
const comptSnsChannel = computed(() => state.compt.sns_channel?.[0])

export default reactive({
  createSuggestion: {
    snsList: itemsForCreateSuggestion,
    isValid: isValidForCreateSuggestion,
  },
  availableSnsChannels,
  targetCategoryList,
  isSnsCampaign,
  comptSnsChannel,
})
