<template>
  <TagbySidebarWithButton
    :visible="state.updateInvoicePayDateSidebar.isVisible"
    title="리워드 결제날짜 변경"
    :isValid="isValid"
    :isSaving="state.updateInvoicePayDateSidebar.isSaving"
    @ok="updateInvoicePayDate"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <BFormGroup label="결제날짜">
      <TagbyDatetimeInputV3 v-model="state.updateInvoicePayDateSidebar.expectedPayDate" />
    </BFormGroup>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormGroup,
} from "bootstrap-vue"
import TagbySidebarWithButton from "@/components/TagbySidebarWithButton.vue"
import state from "../../state"
import useUpdateInvoicePayDateSidebar from "../../hooks/useUpdateInvoicePayDateSidebar"
import TagbyDatetimeInputV3 from "@/components/TagbyDatetimeInputV3.vue"

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyDatetimeInputV3,
    BFormGroup,
  },
  setup() {
    const {
      isValid,
      updateInvoicePayDate,
      turnOffSidebar,
    } = useUpdateInvoicePayDateSidebar()

    return {
      state,
      isValid,
      updateInvoicePayDate,
      turnOffSidebar,
    }
  },
})
</script>
