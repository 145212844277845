<template>
  <div>
    <TFilter
      :fieldGroupOptions="fieldGroupOptions"
      :fieldGroupSelected="fieldGroupSelected"
      @inputFieldGroup="inputFieldGroup"
    />
    <Table
      :fields="tableFields"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "@vue/composition-api"
import TFilter from "./Filter.vue"
import Table from "./Table.vue"
import getters from "../../getters"

export default defineComponent({
  components: {
    TFilter,
    Table,
  },
  setup() {
    const xor = (a, b) => {
      if (a && b) {
        return false
      }
      if (!a && !b) {
        return false
      }
      return true
    }

    const conditioanlOption = (option, isOpposite = false) => {
      if (xor(getters.isSnsCampaign, isOpposite)) {
        return [option]
      }
      return []
    }

    const fieldGroupOptions = computed(() => [
      ...conditioanlOption({ text: "SNS 정보", value: "SNS_INFO" }),
      { text: "INF 정보", value: "INF_INFO" },
      ...conditioanlOption({ text: "카테고리 정보", value: "CATEGORY_INFO" }),
      ...conditioanlOption({ text: "파트너쉽 정보", value: "PARTNERSHIP" }),
      { text: "중재 정보", value: "CLAIM_INFO" },
      ...conditioanlOption({ text: "네이버 인플루언서", value: "NAVER_INF" }),
      { text: "리워드제안", value: "REWARD_SUGGEST" },
    ])

    const fieldGroupSelected = ref(fieldGroupOptions.value[0]?.value)

    const inputFieldGroup = value => {
      fieldGroupSelected.value = value
    }

    const tableFields = computed(() => {
      if (fieldGroupSelected.value === "SNS_INFO") {
        if (getters.comptSnsChannel === "BLOG") {
          return [
            { key: "idx" },
            { key: "username" },
            { key: "state", label: "신청상태" },
            { key: "report_info", label: "통계정보" },
            { key: "isi_info", label: "지수정보" },
            { key: "sns_info", label: "계정정보" },
            { key: "created_at", label: "신청일시" },
            { key: "actions" },
          ]
        }
        return [
          { key: "idx" },
          { key: "username" },
          { key: "state", label: "신청상태" },
          { key: "sns_info", label: "계정정보" },
          { key: "isi_info", label: "지수정보" },
          { key: "report_info", label: "통계정보" },
          { key: "created_at", label: "신청일시" },
          { key: "actions" },
        ]
      }
      if (fieldGroupSelected.value === "INF_INFO") {
        return [
          { key: "idx" },
          ...conditioanlOption({ key: "username" }),
          { key: "state", label: "신청상태" },
          { key: "inf", label: "inf" },
          { key: "inf_phone", label: "연락처" },
          { key: "inf_caution", label: "경고 수" },
          { key: "actions" },
        ]
      }
      if (fieldGroupSelected.value === "PARTNERSHIP") {
        return [
          { key: "idx" },
          { key: "username" },
          { key: "permission_status" },
          { key: "actions" },
        ]
      }
      if (fieldGroupSelected.value === "CATEGORY_INFO") {
        return [
          { key: "idx" },
          { key: "username" },
          { key: "categories" },
          { key: "actions" },
        ]
      }
      if (fieldGroupSelected.value === "CLAIM_INFO") {
        return [
          { key: "idx" },
          ...conditioanlOption({ key: "username" }),
          ...conditioanlOption({ key: "inf", label: "inf" }, true),
          { key: "claim_step" },
          { key: "actions" },
        ]
      }
      if (fieldGroupSelected.value === "NAVER_INF") {
        return [
          { key: "idx" },
          { key: "username" },
          { key: "naver_inf_idx", label: "네이버 인플루언서 여부" },
          { key: "actions" },
        ]
      }
      if (fieldGroupSelected.value === "REWARD_SUGGEST") {
        return [
          { key: "idx" },
          { key: "username" },
          { key: "reward_suggest_amount", label: "제안금액" },
          { key: "reward_suggest_comment", label: "제안코멘트" },
          { key: "reward_suggest_state", label: "제안상태" },
          { key: "actions" },
        ]
      }
      return []
    })

    return {
      fieldGroupOptions,
      fieldGroupSelected,
      tableFields,
      inputFieldGroup,
    }
  },
})
</script>
