import {
  computed,
} from "@vue/composition-api"
import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"
import useFetch from "./useFetch"
import getStateOrigin from "../stateOrigin"
import useErrorReason from "../useErrorReason"
import { any } from "@/utils/value"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getTimelineList,
    getInvoiceList,
  } = useFetch()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErrorReason()

  const turnOnModal = items => {
    state.selectedItems = items
    state.refundInvoiceModal.isVisible = true
  }
  const turnOffModal = () => {
    state.refundInvoiceModal.isVisible = false
  }
  const refundInvoice = () => {
    state.refundInvoiceModal.isSaving = true
    return api.refundInvoice({
      campaign_idx: state.idx,
      invoice_idx_list: state.selectedItems.map(e => e.idx),
    }).then(() => {
      makeToast("primary", "리워드 결제 취소에 성공했습니다")
      turnOffModal()
      getInvoiceList()
      getTimelineList()
    }).catch(err => {
      makeToast("danger", "리워드 결제 취소 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.refundInvoiceModal.isSaving = false
    })
  }

  const getIsAvailable = items => {
    if (items == null || items.length === 0) {
      return false
    }
    if (any(items.map(e => e.state !== "DONE"))) {
      return false
    }
    return true
  }

  return {
    turnOnModal,
    turnOffModal,
    refundInvoice,
    getIsAvailable,
  }
}
