import api from "./api"
import state from "./state"
import getters from "./getters"

import useToast from "@/utils/toast"
import useErrorReason from "./useErrorReason"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnModal,
  } = useErrorReason()

  const turnOnSidebar = () => {
    state.createSuggestion.isVisible = true
    state.createSuggestion.selectedSnsIdxList = []
  }
  const turnOffSidebar = () => {
    state.createSuggestion.isVisible = false
  }

  const searchSnsList = () => {
    state.createSuggestion.isBusy = true
    return api.searchSnsList({
      text: state.createSuggestion.searchText,
      service_list: getters.availableSnsChannels,
    }).then(response => {
      state.createSuggestion.snsList = response.data.data
    }).finally(() => {
      state.createSuggestion.isBusy = false
    })
  }

  const selectSns = sns => {
    state.createSuggestion.searchText = null
    state.createSuggestion.snsList = []
    state.createSuggestion.infList = []
    state.createSuggestion.selectedSnsIdxList = [
      ...state.createSuggestion.selectedSnsIdxList,
      sns.idx,
    ]
  }

  const createSuggestion = () => {
    state.createSuggestion.isSaving = true
    return api.createSuggestion({
      campaign_idx: state.idx,
      sns_idx_list: state.createSuggestion.selectedSnsIdxList,
    }).then(() => {
      makeToast("primary", "Successfully created suggestion")
      turnOffSidebar()
    }).catch(err => {
      makeToast("danger", "Failed to create suggestion")
      turnOnModal(err)
    }).finally(() => {
      state.createSuggestion.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    searchSnsList,
    selectSns,
    createSuggestion,
  }
}
