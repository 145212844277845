<template>
  <BCol :cols="cols">
    <BFormGroup :label="label">
      <BFormInput
        :value="value"
        readonly
      />
    </BFormGroup>
  </BCol>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    cols: {
      type: Number,
      default: 4,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String, Array],
    },
  },
  setup() {

  },
})
</script>
