<template>
  <TagbyPrimaryModal
    :visible="visibleResetDeliveryModal"
    title="송장정보 초기화"
    @hidden="turnOffModal"
    @ok="resetDelivery"
  >
    <NotifyNot />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import { useResetDelivery } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    NotifyNot,
  },
  setup() {
    const {
      visibleResetDeliveryModal,
      turnOffModal,
      resetDelivery,
    } = useResetDelivery()

    return {
      visibleResetDeliveryModal,
      turnOffModal,
      resetDelivery,
    }
  },
})
</script>
