<template>
  <BCard title="미션">
    <MissionTable v-if="!isEndless" />
    <EndlessMissionTable v-else />
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
} from 'bootstrap-vue'
import { useComptDetail } from '../../viewModel'
import MissionTable from './MissionTable.vue'
import EndlessMissionTable from './EndlessMissionTable.vue'

export default defineComponent({
  components: {
    BCard,
    MissionTable,
    EndlessMissionTable,
  },
  setup() {
    const { isEndless } = useComptDetail()
    return {
      isEndless,
    }
  },
})
</script>
