<template>
  <TagbyActionButtonV2
    text="좋아요/댓글 수 확인"
    variant="outline-secondary"
    @click="clickButton"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import { useGetLikeComment } from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      clickButton,
    } = useGetLikeComment()

    return {
      clickButton,
    }
  },
})
</script>
