<template>
  <BListGroup
    flush
    class="overflow-auto mt-1"
    style="max-height: 480px"
  >
    <BListGroupItem
      v-for="chatRoom in filteredChatRooms"
      :key="chatRoom.idx"
      href="#"
      class="d-flex no-wrap align-items-center"
      active-class=""
      :active="getIsActive(chatRoom.idx)"
      @click="clickChatRoom(chatRoom)"
    >
      <BAvatar
        :src="chatRoom.profileImgUrl"
        class="mr-1"
      />
      <div>
        {{ resolveUsername(chatRoom) }} <span v-if="chatRoom.state === 'INACTIVE'">[INACTIVE]</span>
      </div>
    </BListGroupItem>
  </BListGroup>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BListGroup,
  BListGroupItem,
  BAvatar,
} from "bootstrap-vue"
import { useChats } from "../../viewModel"

export default defineComponent({
  components: {
    BListGroup,
    BListGroupItem,
    BAvatar,
  },
  setup() {
    const {
      targetRoom,
      filteredChatRooms,
      clickChatRoom,
    } = useChats()

    const getIsActive = roomIdx => targetRoom.value?.idx === roomIdx
    const resolveUsername = roomObj => {
      if (!roomObj.username) {
        return roomObj.inf.name
      }
      return `${roomObj.username} (${roomObj.infName})`
    }

    return {
      filteredChatRooms,
      clickChatRoom,
      getIsActive,
      resolveUsername,
    }
  },
})
</script>
