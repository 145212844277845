<template>
  <TagbyActionCardV2>
    <!-- <TestButton /> -->
    <DivHeader text="기본기능" />
    <UpdateComptButton />
    <MemoButton />
    <DivHeader text="단계수정" />
    <PendingButton />
    <ReleasePendingButton />
    <SetProgressedButton />
    <SetStopButton />
    <DivHeader text="데이터컨트롤" />
    <AddGuideButton />
    <AddApplyButton />
    <AddApplySimpleRecruitButton />
    <PullUpButton />
    <SetAdvButton />
    <PayInvoiceButton />
    <MoveAppliersButton />
    <DivHeader text="다운로드" />
    <DownloadPrePostingImgButton />
    <DownloadApplyExcelButton />
    <DownloadApplyDetailExcelButton />
    <DivHeader text="기타" />
    <SendNotificationButton />
    <AddScreenshotJobButton />
    <SendBulkChatButton />
    <CreateSuggestionButton />
    <GetLikeCommentButton />
    <AddPartnershipButton />
    <!-- <UpdateHasGuideButton /> -->
  </TagbyActionCardV2>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionCardV2 from "@/components/TagbyActionCardV2.vue"
import UpdateComptButton from "./UpdateComptButton.vue"
import MemoButton from "./MemoButton.vue"
import PendingButton from "./PendingButton.vue"
import ReleasePendingButton from "./ReleasePendingButton.vue"
import AddApplyButton from "./AddApplyButton.vue"
import AddGuideButton from "./AddGuideButton.vue"
import AddScreenshotJobButton from "./AddScreenshotJobButton.vue"
import DownloadApplyExcelButton from "./DownloadApplyExcelButton.vue"
import DownloadPrePostingImgButton from "./DownloadPrePostingImgButton.vue"
import GetLikeCommentButton from "./GetLikeCommentButton.vue"
import SetProgressedButton from "./SetProgressedButton.vue"
import SetStopButton from "./SetStopButton.vue"
import SendNotificationButton from "./SendNotificationButton.vue"
import PullUpButton from "./PullUpButton.vue"
import AddPartnershipButton from "./AddPartnershipButton.vue"
import SetAdvButton from "./SetAdvButton.vue"
import PayInvoiceButton from "./PayInvoiceButton.vue"
import DownloadApplyDetailExcelButton from "./DownloadApplyDetailExcelButton.vue"
import SendBulkChatButton from "./SendBulkChatButton.vue"
import MoveAppliersButton from "./MoveAppliersButton.vue"
import UpdateHasGuideButton from "./UpdateHasGuideButton.vue"
import CreateSuggestionButton from "./CreateSuggestionButton.vue"
import TestButton from "./TestButton.vue"
import DivHeader from "./DivHeader.vue"
import AddApplySimpleRecruitButton from "./AddApplySimpleRecruitButton.vue"

export default defineComponent({
  components: {
    TagbyActionCardV2,
    UpdateComptButton,
    MemoButton,
    PendingButton,
    ReleasePendingButton,
    AddApplyButton,
    AddGuideButton,
    AddScreenshotJobButton,
    DownloadApplyExcelButton,
    DownloadPrePostingImgButton,
    GetLikeCommentButton,
    SetProgressedButton,
    SetStopButton,
    SendNotificationButton,
    PullUpButton,
    AddPartnershipButton,
    SetAdvButton,
    PayInvoiceButton,
    DownloadApplyDetailExcelButton,
    SendBulkChatButton,
    MoveAppliersButton,
    UpdateHasGuideButton,
    CreateSuggestionButton,
    TestButton,
    DivHeader,
    AddApplySimpleRecruitButton,
  },
  setup() {

  },
})
</script>
