<template>
  <div v-if="hasSelectedSns && hasDeliveryItem">
    <h4>배송지</h4>
    <BRow v-if="!isEmptySearchedDestinations">
      <BCol>
        <BFormGroup
          invalid-feedback="필수 항목입니다"
          :state="validationTargetDestination"
        >
          <vSelect
            v-model="targetDestinationIdx"
            :options="destinationOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow v-else>
      <BCol class="text-danger">
        등록된 주소지가 없습니다
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useAddApply } from '../../viewModel'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  setup() {
    const {
      hasSelectedSns,
      hasDeliveryItem,
      searchedDestinations,
      isEmptySearchedDestinations,
      targetDestinationIdx,
      validationTargetDestination,
    } = useAddApply()

    const destinationOptions = computed(() => searchedDestinations.value?.map(d => ({
      label: `${d.address} ${d.address_detail}`,
      value: d.idx,
    })))

    return {
      hasSelectedSns,
      hasDeliveryItem,
      searchedDestinations,
      isEmptySearchedDestinations,
      targetDestinationIdx,
      validationTargetDestination,
      destinationOptions,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
