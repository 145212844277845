<template>
  <BTable
    responsive
    small
    :fields="fields"
    :items="mappedInvoices"
    :filter="filterText"
    style="overflow-x: visible;"
  >
    <template #cell(username)="data">
      <TagbySnsColumn :sns="data.item.sns" />
    </template>

    <template #cell(ssn_exists)="data">
      {{ data.item.ssn == null ? '비인증' : '인증' }}
    </template>

    <template #cell(created_at)="data">
      <TagbyDateColumn :value="data.value" />
    </template>

    <template #cell(expected_pay_date)="data">
      <TagbyDateColumn :value="data.value" />
    </template>

    <template #cell(inf)="data">
      <InfLinkColumn :inf="data.item.sns.inf" />
    </template>

    <template #cell(inf_phone)="data">
      {{ data.item.sns.inf.phone }}
    </template>

    <template #cell(inf_caution)="data">
      {{ data.item.sns.inf.caution_count }}
    </template>

    <template #cell(point_addition_expected_date)="data">
      <TagbyDateColumn
        v-if="data.item.point != null"
        :value="data.item.point.addition_expected_date"
      />
    </template>

    <template #cell(point_apply_point)="data">
      <div v-if="data.item.point != null">
        {{ data.item.point.apply_point }}
      </div>
    </template>

    <template #cell(point_tax)="data">
      <div v-if="data.item.point != null">
        {{ data.item.point.tax }}
      </div>
    </template>

    <template #cell(point_state)="data">
      <div v-if="data.item.point != null">
        {{ data.item.point.state }}
      </div>
    </template>

    <template #cell(actions)="data">
      <TagbyTableAction>
        <TagbyTableActionItem
          :disabled="!getIsValidToUpdateAmound(data.item)"
          icon="DollarSignIcon"
          text="리워드 금액 변경"
          @click="turnOnUpdateAmountSidebar(data.item)"
        />
        <TagbyTableActionItem
          :disabled="!getIsValidToCancelReward(data.item)"
          icon="XSquareIcon"
          text="리워드 취소"
          @click="turnOnCancelRewardModal(data.item)"
        />
        <TagbyTableActionItem
          :disabled="!getIsValidToUpdatePayDate(data.item)"
          icon="CalendarIcon"
          text="결제 날짜 변경"
          @click="turnOnUpdatePayDateSidebar(data.item)"
        />
        <TagbyTableActionItem
          :disabled="!getIsValidCancelPoint(data.item)"
          icon="XSquareIcon"
          text="포인트 지급 취소"
          @click="turnOnCancelPointModal(data.item)"
        />
      </TagbyTableAction>
    </template>
  </BTable>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
} from 'bootstrap-vue'
import {
  useInvoices,
  useUpdateInvoiceAmount,
  useCancelReward,
  useUpdatePayDate,
  useCancelPoint,
} from '../../viewModel'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'
import InfLinkColumn from '../../components/InfLinkColumn.vue'
import useResolveValue from '@/utils/resolveValue'
import TagbyDateColumn from '@/components/TagbyDateColumn.vue'
import UrlColumn from '../../components/UrlColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'

export default defineComponent({
  components: {
    BTable,
    TagbySnsColumn,
    InfLinkColumn,
    TagbyDateColumn,
    UrlColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      mappedInvoices,
      fields,
      filterText,
    } = useInvoices()

    const { resolveDate } = useResolveValue()

    const {
      turnOnSidebar: turnOnUpdateAmountSidebar,
      getIsValidItem: getIsValidToUpdateAmound,
    } = useUpdateInvoiceAmount()

    const {
      turnOnModal: turnOnCancelRewardModal,
      getIsValidItem: getIsValidToCancelReward,
    } = useCancelReward()

    const {
      turnOnSidebar: turnOnUpdatePayDateSidebar,
      getIsValidItem: getIsValidToUpdatePayDate,
    } = useUpdatePayDate()

    const {
      turnOnModal: turnOnCancelPointModal,
      getIsValidItem: getIsValidCancelPoint,
    } = useCancelPoint()

    return {
      mappedInvoices,
      fields,
      filterText,
      resolveDate,
      turnOnUpdateAmountSidebar,
      getIsValidToUpdateAmound,
      turnOnCancelRewardModal,
      getIsValidToCancelReward,
      turnOnUpdatePayDateSidebar,
      getIsValidToUpdatePayDate,
      turnOnCancelPointModal,
      getIsValidCancelPoint,
    }
  },
})
</script>
