<template>
  <TagbySidebarWithButton
    title="create suggestion"
    :visible="state.createSuggestion.isVisible"
    :isSaving="state.createSuggestion.isSaving"
    :isValid="getters.createSuggestion.isValid"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
    @ok="createSuggestion"
  >
    <TagbyInputRow label="username">
      <BFormInput
        v-model="state.createSuggestion.searchText"
        placeholder="Input username"
        @keyup.enter="searchSnsList"
      />
    </TagbyInputRow>
    <TagbyInputRow label="selected sns">
      <BFormTags
        v-model="state.createSuggestion.selectedSnsIdxList"
        :tag-validator="tag => isInteger(tag)"
        invalid-tag-text="You must enter only numbers"
      />
    </TagbyInputRow>
    <SnsList />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
  BFormTags,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import state from '../../state'
import useCreateSuggestion from '../../useCreateSuggestion'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import SnsList from './SnsList.vue'
import getters from '../../getters'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    BFormTags,
    SnsList,
  },
  setup() {
    const {
      turnOffSidebar,
      searchSnsList,
      createSuggestion,
    } = useCreateSuggestion()

    const isInteger = value => /^[0-9]*$/.test(value)

    return {
      state,
      getters,
      turnOffSidebar,
      searchSnsList,
      isInteger,
      createSuggestion,
    }
  },
})
</script>
