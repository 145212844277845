<template>
  <TagbyDangerModal
    :visible="visibleSetStopModal"
    title="캠페인 모집중단"
    @hidden="turnOffModal"
    @ok="setStop"
  >
    <NotifyNot />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { useSetStop } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbyDangerModal,
    NotifyNot,
  },
  setup() {
    const {
      visibleSetStopModal,
      turnOffModal,
      setStop,
    } = useSetStop()

    return {
      visibleSetStopModal,
      turnOffModal,
      setStop,
    }
  },
})
</script>
