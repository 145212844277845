<template>
  <TagbyPrimaryModal
    :visible="visiblePayInvoiceModal"
    title="리워드 결제"
    @hidden="turnOffModal"
    @ok="payInvoice"
  >
    <NotifyNot />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import { usePayInvoice } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    NotifyNot,
  },
  setup() {
    const {
      visiblePayInvoiceModal,
      turnOffModal,
      payInvoice,
    } = usePayInvoice()

    return {
      visiblePayInvoiceModal,
      turnOffModal,
      payInvoice,
    }
  },
})
</script>
