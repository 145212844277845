<template>
  <TagbySidebarWithButton
    title="미션상세"
    :visible="visibleUpdateMissionSidebar"
    :isValid="true"
    :isSaving="isSaving"
    width="50%"
    @ok="updateMission"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <MissionContent v-if="!isEndless" />
    <EndlessMissionContent v-else />

    <NotifyNotPending
      v-if="comptStep === 'PENDING'"
    />
    <NotifyApply
      v-else-if="comptStep === 'RECRUIT'"
      label="알림전송"
    />
    <NotifySelect
      v-else
      label="알림전송"
    />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import { useUpdateMission } from '../../viewModel'
import MissionContent from './MissionContent.vue'
import EndlessMissionContent from './EndlessMissionContent.vue'
import NotifyNotPending from '../NotifyNotPending.vue'
import NotifyApply from '../NotifyApply.vue'
import NotifySelect from '../NotifySelect.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    MissionContent,
    EndlessMissionContent,
    NotifyNotPending,
    NotifyApply,
    NotifySelect,
  },
  setup() {
    const {
      visibleUpdateMissionSidebar,
      turnOffSidebar,
      updateMission,
      isEndless,
      isSaving,
      comptStep,
    } = useUpdateMission()

    return {
      visibleUpdateMissionSidebar,
      turnOffSidebar,
      updateMission,
      isEndless,
      isSaving,
      comptStep,
    }
  },
})
</script>
