<template>
  <TagbyActionButtonV2
    :isSaving="isDownloadingApplyExcel"
    text="배송지 엑셀 다운로드"
    variant="outline-success"
    @click="downloadApplyExcel"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import {
  useApplyExcel,
} from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      isDownloadingApplyExcel,
      downloadApplyExcel,
    } = useApplyExcel()

    return {
      isDownloadingApplyExcel,
      downloadApplyExcel,
    }
  },
})
</script>
