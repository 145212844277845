<template>
  <div class="d-flex">
    <div>
      {{ inf.name }}
    </div>
    <BLink
      :to="{ name: 'member-inf-detail', params: { idx: inf.idx } }"
    >
      ({{ inf.idx }})
    </BLink>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BLink,
  },
  props: {
    inf: {
      type: Object,
    },
  },
  setup() {

  },
})
</script>
