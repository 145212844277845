<template>
  <BInputGroup
    size="sm"
    class="p-1"
  >
    <BFormInput
      v-model="chatFilterText"
      type="search"
      placeholder="Type to Search"
    />
  </BInputGroup>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import { useChats } from '../../viewModel'

export default defineComponent({
  components: {
    BInputGroup,
    BFormInput,
  },
  setup() {
    const {
      chatFilterText,
    } = useChats()

    return {
      chatFilterText,
    }
  },
})
</script>
