import {
  computed,
} from "@vue/composition-api"
import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"
import useFetch from "./useFetch"
import useErrorReason from "../useErrorReason"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getComptDetail,
    getTimelineList,
  } = useFetch()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErrorReason()

  const turnOnModal = () => {
    state.releasePendingModal.isVisible = true
  }

  const turnOffModal = () => {
    state.releasePendingModal.isVisible = false
  }

  const releasePending = () => {
    if (!state.compt) {
      makeToast("danger", "보류해제에 실패했습니다", "캠페인 정보를 불러오지 못했습니다.")
    }
    state.releasePendingModal.isSaving = true
    if (state.compt.is_endless) {
      return api.releasePendingEndless({
        campaign_idx: state.idx,
      }).then(() => {
        makeToast("primary", "보류 해제에 성공했습니다")
        getComptDetail()
        getTimelineList()
      }).catch(err => {
        makeToast("danger", "보류 해제에 실패했습니다")
        turnOnErrorReasonModal(err)
      }).finally(() => {
        state.releasePendingModal.isSaving = false
        turnOffModal()
      })
    }
    return api.releasePending({
      campaign_idx: state.idx,
      has_noti: state.releasePendingModal.hasNoti,
    }).then(() => {
      makeToast("primary", "보류 해제에 성공했습니다")
      getComptDetail()
      getTimelineList()
    }).catch(err => {
      makeToast("danger", "보류 해제에 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.releasePendingModal.isSaving = false
      turnOffModal()
    })
  }

  const isEndless = computed(() => state.compt.is_endless ?? false)
  const isRecruitStep = computed(() => [null, "SELECT"].includes(state.compt.manage_step))
  const isValid = computed(() => state.compt.step === "PENDING")

  return {
    turnOnModal,
    turnOffModal,
    releasePending,
    isEndless,
    isRecruitStep,
    isValid,
  }
}
