<template>
  <TagbySidebarWithButton
    :isSaving="isSaving"
    :isValid="isValid"
    title="알림 전송"
    :visible="visibleSendBulkNotificationSidebar"
    @ok="sendBulkNotification"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="발송 대상">
      <BFormInput
        :value="notificationTarget"
        readonly
      />
    </TagbyInputRow>

    <TagbyInputRow label="메시지">
      <vSelect
        :options="notificationTemplates"
        :value="selectedNotificationTemplate"
        @input="selectTemplate"
      />
    </TagbyInputRow>
    <TagbyInputRow>
      <BFormTextarea
        v-model="notificationMessage"
        placeholder="메시지를 입력하세요"
        rows="5"
      />
    </TagbyInputRow>

    <TagbyInputRow label="즉시 전송">
      <BFormCheckbox
        v-model="isNowSendNotification"
        switch
      />
    </TagbyInputRow>

    <TagbyInputRow v-if="!isNowSendNotification">
      <TagbyDatetimeInputV2 v-model="notificationSendAt" />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import {
  useSendBulkNotification,
} from '../viewModel'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyDatetimeInputV2,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    TagbySnsColumn,
    TagbyInputRow,
    BFormCheckbox,
  },
  setup() {
    const {
      visibleSendBulkNotificationSidebar,
      isNowSendNotification,
      notificationMessage,
      notificationSendAt,
      notificationTemplates,
      selectedNotificationTemplate,
      notificationTarget,
      selectTemplate,
      sendBulkNotification,
      turnOffSidebar,
      isSaving,
      isValid,
    } = useSendBulkNotification()

    return {
      visibleSendBulkNotificationSidebar,
      isNowSendNotification,
      notificationMessage,
      notificationSendAt,
      notificationTemplates,
      selectedNotificationTemplate,
      notificationTarget,
      selectTemplate,
      sendBulkNotification,
      turnOffSidebar,
      isSaving,
      isValid,
    }
  },
})
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
