<template>
  <TagbyPrimaryModal
    title="중재 해결처리"
    :visible="visibleResolveClaimModal"
    @hidden="turnOffModal"
    @ok="resolveClaim"
  >
    중재 해결처리 하시겠습니까
    <NotifyTarget />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyPrimaryModal from "@/components/TagbyPrimaryModal.vue"
import {
  useResolveClaim,
} from "../viewModel"
import TagbySnsColumn from "@/components/TagbySnsColumn.vue"
import NotifyTarget from "./NotifyTarget.vue"

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    TagbySnsColumn,
    NotifyTarget,
  },
  setup() {
    const {
      visibleResolveClaimModal,
      turnOffModal,
      resolveClaim,
    } = useResolveClaim()

    return {
      visibleResolveClaimModal,
      turnOffModal,
      resolveClaim,
    }
  },
})
</script>
