<template>
  <TagbyDangerModal
    :visible="visible"
    title="신청자 삭제"
    @hidden="turnOffModal"
    @ok="inactivateApply"
  >
    <div class="mb-1 text-danger">
      <div>- 신청자를 기록상에서 삭제합니다.</div>
    </div>
    <NotifyNot />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { useInactivateApply } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbyDangerModal,
    NotifyNot,
  },
  setup() {
    const {
      visible,
      turnOffModal,
      inactivateApply,
    } = useInactivateApply()

    return {
      visible,
      turnOffModal,
      inactivateApply,
    }
  },
})
</script>
