<template>
  <div v-if="state.createSuggestion.isBusy">
    <BRow class="mb-1">
      <BCol class="d-flex justify-content-center">
        <BSpinner />
      </BCol>
    </BRow>
  </div>
  <div v-else-if="getters.createSuggestion.snsList.length > 0">
    <SnsCard
      v-for="sns in getters.createSuggestion.snsList"
      :key="sns.idx"
      :sns="sns"
      @select="selectSns"
    />
  </div>
  <div v-else>
    <BRow class="mb-1">
      <BCol
        cols="12"
        class="text-muted font-italic d-flex justify-content-center"
      >
        There is no list
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import SnsCard from './SnsCard.vue'
import useCreateSuggestion from '../../useCreateSuggestion'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    SnsCard,
    BRow,
    BCol,
    BSpinner,
  },
  setup() {
    const {
      selectSns,
    } = useCreateSuggestion()

    return {
      state,
      getters,
      selectSns,
    }
  },
})
</script>
