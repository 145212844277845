<template>
  <TagbySidebarWithButton
    :visible="visibleUpdateGuideSidebar"
    title="가이드 수정"
    :isSaving="isSavingUpdateGuide"
    :isValid="true"
    @ok="updateGuide"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyOverlay
      :show="isUploadingGuideFile"
    >
      <BRow>
        <BCol>
          <BFormGroup label="IDX">
            <BFormInput
              :value="targetGuide.idx"
              readonly
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="TYPE">
            <BFormInput
              :value="targetGuide.type"
              readonly
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="STATE">
            <BFormInput
              :value="targetGuide.state"
              readonly
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="FILE">
            <BFormFile
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="uploadGuideUpdate"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol class="text-truncate mb-1">
          file: <a
            :href="targetGuideFile.url || targetGuideFile.file_url"
            target="_blank"
            download
          >
            {{ targetGuideFile.file_name }}
          </a>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="LINK">
            <TagbyLinkOpenInput
              v-model="targetGuide.guide_link"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <NotifyNotGuide v-if="isNotAssigned" />
      <NotifySelect v-else />
    </TagbyOverlay>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyOverlay from '@/components/TagbyOverlay.vue'
import NotifySelect from './NotifySelect.vue'
import NotifyNotGuide from './NotifyNotGuide.vue'
import { useUpdateGuide } from '../viewModel'
import TagbyLinkOpenInput from '@/components/TagbyLinkOpenInput.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyOverlay,
    NotifySelect,
    NotifyNotGuide,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    TagbyLinkOpenInput,
  },
  setup() {
    const {
      visibleUpdateGuideSidebar,
      targetGuide,
      targetGuideFile,
      isNotAssigned,
      isSavingUpdateGuide,
      isUploadingGuideFile,
      turnOffSidebar,
      uploadGuideUpdate,
      updateGuide,
    } = useUpdateGuide()

    return {
      visibleUpdateGuideSidebar,
      targetGuide,
      targetGuideFile,
      isNotAssigned,
      isSavingUpdateGuide,
      isUploadingGuideFile,
      turnOffSidebar,
      uploadGuideUpdate,
      updateGuide,
    }
  },
})
</script>
