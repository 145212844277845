<template>
  <TagbySidebarWithButton
    title="리워드 결제 날짜 변경"
    :visible="visibleUpdatePayDateSidebar"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="updatePayDate"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="결제 날짜">
      <TagbyDatetimeInputV2 v-model="invoicePayDate" />
    </TagbyInputRow>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import NotifyNot from './NotifyNot.vue'
import {
  useUpdatePayDate,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    TagbyDatetimeInputV2,
    NotifyNot,
  },
  setup() {
    return {
      ...useUpdatePayDate(),
    }
  },
})
</script>
