<template>
  <div>
    <h5>{{ title }}</h5>
    <BTable
      responsive
      small
      show-empty
      :fields="fields"
      :items="files"
    >
      <template #cell(file)="data">
        <DownloadFileColumn
          :file="data.item"
        />
      </template>
    </BTable>
    <BFormGroup>
      <BFormTextarea
        :value="text"
        readonly
      />
    </BFormGroup>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
  BButton,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import downloadFile from '@/utils/downloadFile'
import DownloadFileColumn from './DownloadFileColumn.vue'

export default defineComponent({
  components: {
    BTable,
    BButton,
    BFormGroup,
    BFormTextarea,
    DownloadFileColumn,
  },
  props: {
    title: {
      type: String,
    },
    files: {
      type: Array,
    },
    text: {
      type: String,
    },
  },
  setup() {
    const fields = [
      'idx', 'file',
    ]

    return {
      fields,
      downloadFile,
    }
  },
})
</script>
