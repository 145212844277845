<template>
  <BCol
    md="12"
    sm="12"
    cols="12"
    style="margin-bottom: 10px"
  >
    {{ text }}
  </BCol>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCol,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BCol,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup() {

  },
})
</script>
