<template>
  <div>
    <BBadge
      v-for="(category, i) in categories"
      :key="i"
      style="margin-right: 5px"
      variant="primary"
    >
      {{ category }}
    </BBadge>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BBadge,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BBadge,
  },
  props: {
    categories: {
      type: Array,
    },
  },
  setup() {

  },
})
</script>
