<template>
  <BCard title="스크린샷 정보">
    <TagbyTable
      :items="screenshotJobList"
      :fields="fields"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'screenshot-job-detail', params: { idx: data.value } }">
          {{ data.value }}
        </BLink>
      </template>
    </TagbyTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BLink,
} from 'bootstrap-vue'
import TagbyTable from '@/components/TagbyTable.vue'
import { useScreenshotJob } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BLink,
    TagbyTable,
  },
  setup() {
    const {
      fields,
      screenshotJobList,
    } = useScreenshotJob()

    return {
      fields,
      screenshotJobList,
    }
  },
})
</script>
