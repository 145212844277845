<template>
  <div v-if="hasQuestions">
    <h4>
      질문
    </h4>
    <BRow
      v-for="(data, i) in answersForAddApply"
      :key="i"
    >
      <BCol>
        <BFormGroup
          invalid-feedback="필수 항목입니다"
          :state="getState(data)"
        >
          <template #label>
            <div>
              <b>{{ i+1 }}.</b>
              <span
                v-if="data.question.is_required"
                class="tagby-required"
              >*</span>
              <span>{{ data.question.question }}</span>
            </div>
          </template>

          <BFormTextarea
            v-model="data.answer"
            :state="getState(data)"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import { useAddApply } from '../../viewModel'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
  },
  setup() {
    const {
      answersForAddApply,
    } = useAddApply()

    const getState = data => {
      if (!data.question.is_required) {
        return null
      }
      if (data.answer) {
        return null
      }
      return false
    }

    const hasQuestions = computed(() => answersForAddApply.value?.length > 0)

    return {
      getState,
      answersForAddApply,
      hasQuestions,
    }
  },
})
</script>

<style scoped>
.tagby-required {
  color: var(--danger);
  margin-right: 5px;
  margin-left: 5px;
}
</style>
