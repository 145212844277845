<template>
  <TagbyActionButtonV2
    text="파트너십 추가"
    variant="outline-secondary"
    @click="turnOnModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import { useAddBulkPartnership } from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const { turnOnModal } = useAddBulkPartnership()
    return {
      turnOnModal,
    }
  },
})
</script>
