<template>
  <TagbyDetailLayout>
    <template #error>
      <TagbyLoadError
        :hasLoadError="state.hasLoadError"
        subject="Campaign"
        :to="{ name: 'campaign-compt-list' }"
      />
    </template>

    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(campaign)>
          <ComptInfo />
          <RewardTable />
          <QuestionTable />
        </template>

        <template #tab(missions)>
          <Missions />
        </template>

        <template #tab(applies)>
          <Applies />
        </template>

        <template #tab(guides)>
          <Guides />
        </template>

        <template #tab(deliveries)>
          <Deliveries />
        </template>

        <template #tab(prePostings)>
          <PrePostings />
        </template>

        <template #tab(postings)>
          <Postings />
        </template>

        <template #tab(invoices)>
          <!-- <Invoices /> -->
          <InvoiceList />
        </template>

        <template #tab(etc)>
          <AdvInfo />
          <BrandSns />
          <ChatRooms />
          <ScreenshotJobList />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV3 :timelineList="timelines" />
    </template>

    <template #hidden>
      <UpdateRewardSidebar />
      <UpdateMissionSidebar />
      <ApplyDetailSidebar />
      <PrePostingDetailSidebar />
      <UpdateComptModal />
      <TagbyMemo
        v-model="memoContent"
        :visible="visibleMemo"
        :isSaving="isSavingMemo"
        @hidden="turnOffMemo"
        @save="createMemo"
      />
      <AddApplySidebar />
      <PendingModal />
      <ReleasePendingModal />
      <PullUpModal />
      <SetProgressedModal />
      <DownloadPrePostingImgModal />
      <AddScreenshotJobSidebar />
      <SelectApplyModal />
      <TempSelectApplyModal />
      <InactivateApplyModal />
      <QuitApplyModal />
      <UpdateGuideSidebar />
      <ResetDeliveryModal />
      <UpdateDeliverySidebar />
      <SkipPrePostingModal />
      <RequestPrePostingSidebar />
      <DeleteCommentFileModal />
      <PrePostingExpiredAtSidebar />
      <ResetPostingModal />
      <RegisterPostingSidebar />
      <SkipPostingModal />
      <GetLikeCommnetModal />
      <PostingExpiredAtSidebar />
      <SendNotificationSidebar />
      <SendBulkNotificationSidebar />
      <AddPartnershipModal />
      <AddBulkPartnershipModal />
      <SetStopModal />
      <SetAdvSidebar />
      <UpdateInvoiceAmountSidebar />
      <PayInvoiceModal />
      <CreateClaimSidebar />
      <ResolveClaimModal />
      <SendBulkChatSidebar />
      <MoveAppliersSidebar />
      <CancelRewardModal />
      <CreateGuideSidebar />
      <UpdatePayDateSidebar />
      <CancelPointModal />
      <DownloadApplyDetailSidebar />
      <UpdateHasGuideModal />
      <CreateSuggestionSidebar />
      <Sidebars />
      <Modals />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  useInitialize,
  useTabs,
  useTimelines,
  useMemo,
} from "./viewModel"
import {
  resetState,
} from "./useState"
import { getRouterIdx } from "@/utils/router"
import TagbyDetailLayout from "@/components/TagbyDetailLayout.vue"
import TagbyTabs from "@/components/TagbyTabs.vue"
import TagbyLoadError from "@/components/TagbyLoadError.vue"
import TagbyTimelinesV3 from "@/components/TagbyTimelinesV3.vue"
import ActionCard from "./blocks/ActionCard"
import ComptInfo from "./blocks/ComptInfo"
import RewardTable from "./blocks/RewardTable.vue"
import QuestionTable from "./blocks/QuestionTable.vue"
import UpdateRewardSidebar from "./blocks/UpdateRewardSidebar.vue"
import Missions from "./blocks/Missions"
import UpdateMissionSidebar from "./blocks/UpdateMissionSidebar"
import Applies from "./blocks/Applies"
import ApplyDetailSidebar from "./blocks/ApplyDetailSidebar"
import Guides from "./blocks/Guides"
import Deliveries from "./blocks/Deliveries"
import PrePostings from "./blocks/PrePostings"
import PrePostingDetailSidebar from "./blocks/PrePostingDetailSidebar.vue"
import Postings from "./blocks/Postings"
import Invoices from "./blocks/Invoices"
import ChatRooms from "./blocks/ChatRooms"
import UpdateComptModal from "./blocks/UpdateComptModal.vue"
import TagbyMemo from "@/components/TagbyMemo.vue"
import AddApplySidebar from "./blocks/AddApplySidebar"
import PendingModal from "./blocks/PendingModal.vue"
import ReleasePendingModal from "./blocks/ReleasePendingModal.vue"
import PullUpModal from "./blocks/PullUpModal.vue"
import SetProgressedModal from "./blocks/SetProgressedModal.vue"
import DownloadPrePostingImgModal from "./blocks/DownloadPrePostingImgModal.vue"
import AddScreenshotJobSidebar from "./blocks/AddScreenshotJobSidebar.vue"
import ScreenshotJobList from "./blocks/ScreenshotJobList.vue"
import AdvInfo from "./blocks/AdvInfo.vue"
import SelectApplyModal from "./blocks/SelectApplyModal.vue"
import TempSelectApplyModal from "./blocks/TempSelectApplyModal.vue"
import InactivateApplyModal from "./blocks/InactivateApplyModal.vue"
import QuitApplyModal from "./blocks/QuitApplyModal.vue"
import UpdateGuideSidebar from "./blocks/UpdateGuideSidebar.vue"
import ResetDeliveryModal from "./blocks/ResetDeliveryModal.vue"
import UpdateDeliverySidebar from "./blocks/UpdateDeliverySidebar.vue"
import SkipPrePostingModal from "./blocks/SkipPrePostingModal.vue"
import RequestPrePostingSidebar from "./blocks/RequestPrePostingSidebar.vue"
import DeleteCommentFileModal from "./blocks/DeleteCommentFileModal.vue"
import PrePostingExpiredAtSidebar from "./blocks/PrePostingExpiredAtSidebar.vue"
import PostingExpiredAtSidebar from "./blocks/PostingExpiredAtSidebar.vue"
import ResetPostingModal from "./blocks/ResetPostingModal.vue"
import RegisterPostingSidebar from "./blocks/RegisterPostingSidebar.vue"
import SkipPostingModal from "./blocks/SkipPostingModal.vue"
import GetLikeCommnetModal from "./blocks/GetLikeCommnetModal.vue"
import SendNotificationSidebar from "./blocks/SendNotificationSidebar.vue"
import SendBulkNotificationSidebar from "./blocks/SendBulkNotificationSidebar.vue"
import BrandSns from "./blocks/BrandSns.vue"
import AddPartnershipModal from "./blocks/AddPartnershipModal.vue"
import AddBulkPartnershipModal from "./blocks/AddBulkPartnershipModal.vue"
import SetStopModal from "./blocks/SetStopModal.vue"
import SetAdvSidebar from "./blocks/SetAdvSidebar.vue"
import UpdateInvoiceAmountSidebar from "./blocks/UpdateInvoiceAmountSidebar.vue"
import PayInvoiceModal from "./blocks/PayInvoiceModal.vue"
import CreateClaimSidebar from "./blocks/CreateClaimSidebar.vue"
import ResolveClaimModal from "./blocks/ResolveClaimModal.vue"
import SendBulkChatSidebar from "./blocks/SendBulkChatSidebar.vue"
import MoveAppliersSidebar from "./blocks/MoveAppliersSidebar.vue"
import CancelRewardModal from "./blocks/CancelRewardModal.vue"
import CreateGuideSidebar from "./blocks/CreateGuideSidebar.vue"
import UpdatePayDateSidebar from "./blocks/UpdatePayDateSidebar.vue"
import CancelPointModal from "./blocks/CancelPointModal.vue"
import DownloadApplyDetailSidebar from "./blocks/DownloadApplyDetailSidebar.vue"
import UpdateHasGuideModal from "./blocks/UpdateHasGuideModal.vue"
import CreateSuggestionSidebar from "./blocks/CreateSuggestionSidebar/Main.vue"
import useInputCategory from "./useInputCategory"
import InvoiceList from "./blocks/InvoiceList/Main.vue"
import Sidebars from "./blocks/Sidebars"
import state from "./state"
import Modals from "./blocks/Modals"

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    TagbyLoadError,
    TagbyTimelinesV3,
    ActionCard,
    ComptInfo,
    RewardTable,
    QuestionTable,
    UpdateRewardSidebar,
    Missions,
    UpdateMissionSidebar,
    Applies,
    ApplyDetailSidebar,
    Guides,
    Deliveries,
    PrePostings,
    PrePostingDetailSidebar,
    Postings,
    Invoices,
    ChatRooms,
    UpdateComptModal,
    TagbyMemo,
    AddApplySidebar,
    PendingModal,
    ReleasePendingModal,
    PullUpModal,
    SetProgressedModal,
    DownloadPrePostingImgModal,
    AddScreenshotJobSidebar,
    ScreenshotJobList,
    AdvInfo,
    SelectApplyModal,
    TempSelectApplyModal,
    InactivateApplyModal,
    QuitApplyModal,
    UpdateGuideSidebar,
    ResetDeliveryModal,
    UpdateDeliverySidebar,
    SkipPrePostingModal,
    RequestPrePostingSidebar,
    DeleteCommentFileModal,
    PrePostingExpiredAtSidebar,
    ResetPostingModal,
    RegisterPostingSidebar,
    SkipPostingModal,
    GetLikeCommnetModal,
    PostingExpiredAtSidebar,
    SendNotificationSidebar,
    SendBulkNotificationSidebar,
    BrandSns,
    AddPartnershipModal,
    AddBulkPartnershipModal,
    SetStopModal,
    SetAdvSidebar,
    UpdateInvoiceAmountSidebar,
    PayInvoiceModal,
    CreateClaimSidebar,
    ResolveClaimModal,
    SendBulkChatSidebar,
    MoveAppliersSidebar,
    CancelRewardModal,
    CreateGuideSidebar,
    UpdatePayDateSidebar,
    CancelPointModal,
    DownloadApplyDetailSidebar,
    UpdateHasGuideModal,
    CreateSuggestionSidebar,
    InvoiceList,
    Sidebars,
    Modals,
  },
  setup() {
    resetState()
    const { initialize } = useInitialize()
    initialize()
    state.idx = getRouterIdx()
    const { tabList } = useTabs()
    const { timelines } = useTimelines()
    const {
      getCategoryList,
    } = useInputCategory()
    getCategoryList()

    const {
      visibleMemo,
      memoContent,
      isSavingMemo,
      turnOffMemo,
      createMemo,
    } = useMemo()

    return {
      state,
      tabList,
      timelines,

      visibleMemo,
      memoContent,
      isSavingMemo,
      turnOffMemo,
      createMemo,
    }
  },
})
</script>
