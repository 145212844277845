<template>
  <BCard title="광고주 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="IDX">
          <BFormInput
            :value="advertiser.idx"
            class="custom-hyperlink"
            readonly
            @click="linkToAdvertiser"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="이름">
          <BFormInput
            :value="advertiser.name"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="회사">
          <BFormInput
            :value="advertiser.company_name"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import router from '@/router'
import { useAdvertiser } from '../viewModel'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  setup() {
    const {
      advertiser,
    } = useAdvertiser()
    const linkToAdvertiser = () => {
      router.push({ name: 'member-adv-view', params: { idx: advertiser.value.idx } })
    }
    return {
      advertiser,
      linkToAdvertiser,
    }
  },
})
</script>

<style>
.custom-hyperlink {
  font-weight: bold;
  color: var(--primary) !important;
}
.custom-hyperlink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
