<template>
  <BCard>
    <BRow>
      <BCol cols="4">
        <BInputGroup size="sm">
          <BFormInput
            v-model="tempFilterText"
            type="search"
            placeholder="Type to Search"
            @keyup.enter="searchApplies"
          />
          <BInputGroupAppend>
            <BButton
              variant="outline-primary"
              @click="searchApplies"
            >
              Search
            </BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </BCol>
      <BCol
        cols="8"
        class="d-flex align-items-center"
      >
        <BFormCheckboxGroup
          v-model="stateSelected"
          :options="stateOptions"
        />
        <BFormRadioGroup
          :checked="fieldGroupSelected"
          :options="fieldGroupOptions"
          @input="$emit('inputFieldGroup', $event)"
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckboxGroup,
  BFormRadioGroup,
} from "bootstrap-vue"
import { useApplies } from "../../viewModel"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckboxGroup,
    BFormRadioGroup,
  },
  props: {
    fieldGroupSelected: {
      type: String,
    },
    fieldGroupOptions: {
      type: Array,
    },
  },
  emit: [
    "inputFieldGroup",
  ],
  setup() {
    const {
      tempFilterText,
      stateSelected,
      searchApplies,
    } = useApplies()

    const stateOptions = [
      { text: "지원", value: "APPLY" },
      { text: "임시선정", value: "TEMP" },
      { text: "선정", value: "SELECTED" },
      { text: "신청취소", value: "CANCELED" },
      { text: "이탈", value: "QUIT" },
    ]

    return {
      tempFilterText,
      stateOptions,
      stateSelected,
      searchApplies,
    }
  },
})
</script>
