<template>
  <TagbySidebarWithButton
    :isSaving="isSaving"
    :isValid="isValid"
    title="캠페인 이관"
    :visible="visibleSetAdvSidebar"
    @ok="setAdv"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <TagbyInputRow label="ADV_IDX">
      <BFormInput
        v-model="targetAdvIdx"
        number
        type="number"
      />
    </TagbyInputRow>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import {
  useSetAdv,
} from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    NotifyNot,
  },
  setup() {
    const {
      visibleSetAdvSidebar,
      isSaving,
      isValid,
      setAdv,
      targetAdvIdx,
      turnOffSidebar,
    } = useSetAdv()

    return {
      visibleSetAdvSidebar,
      isSaving,
      isValid,
      setAdv,
      targetAdvIdx,
      turnOffSidebar,
    }
  },
})
</script>
