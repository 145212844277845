<template>
  <div>
    <BRow>
      <ReadOnlyInput
        :cols="2"
        label="사전검수 여부"
        :value="mission.is_precheck ? '예' : '아니오'"
      />
      <BCol
        v-if="mission.is_precheck"
        cols="5"
      >
        <BFormGroup label="검수 기간">
          <TagbyDateRangeInputV2
            :value="[mission.pre_posting_start_at, mission.pre_posting_end_at]"
            @input="inputPrePostingPeriod"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="5">
        <BFormGroup label="포스팅 기간">
          <TagbyDateRangeInputV2
            :value="[mission.start_at, mission.end_at]"
            @input="inputPeriod"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="해시태그">
          <BFormTags
            v-model="mission.hashtag_list"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="제공항목">
          <OfferItemList
            :items="missionItems"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="상세설명">
          <BFormTextarea
            v-model="mission.description"
            rows="3"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="주의사항">
          <BFormTextarea
            v-model="mission.caution_text"
            rows="3"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTags,
  BFormTextarea,
} from 'bootstrap-vue'
import ReadOnlyInput from '../../components/ReadOnlyInput.vue'
import { useUpdateMission } from '../../viewModel'
import TagbyDateRangeInputV2 from '@/components/TagbyDateRangeInputV2.vue'
import OfferItemList from '../../components/OfferItemList.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTags,
    BFormTextarea,
    ReadOnlyInput,
    TagbyDateRangeInputV2,
    OfferItemList,
  },
  setup() {
    const {
      targetMission,
      inputPeriod,
      inputPrePostingPeriod,
      missionItems,
      updateMission,
    } = useUpdateMission()

    return {
      mission: targetMission,
      inputPeriod,
      inputPrePostingPeriod,
      missionItems,
      updateMission,
    }
  },
})
</script>
