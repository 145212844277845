<template>
  <div class="d-flex">
    <div
      v-if="chat.msg_type === 'NORMAL'"
      class="d-flex flex-column"
    >
      <div class="d-flex">
        <div
          class="left-chat left-chat-normal"
          :class="[computedSkin]"
        >
          <div v-if="chat.content_type === 'TEXT'">
            {{ chat.content }}
          </div>
          <div v-else>
            <div
              v-for="file in chat.files"
              :key="file.idx"
              class="d-flex flex-column"
            >
              <div>
                {{ file.file_name }}
              </div>
              <div class="d-flex file-text">
                <div style="margin-right: 5px;">
                  {{ file.file_size }}KB
                </div>
                <div>
                  <a
                    @click="downloadFile(file.url)"
                  >저장</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isNotRead"
          class="d-flex flex-column justify-content-center"
        >
          <div class="chat-right-dot" />
        </div>
      </div>
      <div class="d-flex justify-content-end chat-time">
        <span>{{ resolveDateTime(chat.created_at) }}</span>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column"
    >
      <div class="d-flex">
        <div
          class="left-chat left-chat-system"
          :class="[computedSkin]"
        >
          <div>
            [{{ chat.state }}]
          </div>
          <div>
            {{ chat.content }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end chat-time">
        <span>{{ resolveDateTime(chat.created_at) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import downloadFile from '@/utils/downloadFile'
import store from '@/store'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  props: {
    chat: {
      type: Object,
      required: true,
    },
    isNotRead: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const computedSkin = computed(() => store.state.appConfig.layout.skin)
    const { resolveDateTime } = useResolveValue()
    return {
      computedSkin,
      downloadFile,
      resolveDateTime,
    }
  },
})
</script>

<style scoped>
.left-chat {
  padding: 1rem;
  max-width: 300px;
  border-radius: 1rem;
  margin: 0;
}
.left-chat-normal.dark {
  color: white;
  background-color: #283046;
}
.left-chat-normal.light {
  color: black;
  background-color: white;
}
.left-chat-system.dark {
  color: white;
  background-color: var(--warning);
}
.left-chat-system.light {
  color: black;
  background-color: var(--warning);
}
.chat-time {
  color: var(--secondary);
}
.file-text {
  font-weight: lighter;
  font-size: 14px;
  color: var(--secondary)
}
.chat-right-dot {
  width: 10px;
  height: 10px;
  background-color: var(--danger);
  border-radius: 100%;
  margin-left: 10px;
}
</style>
