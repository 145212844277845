<template>
  <div>
    <ChangeStepModal />
    <ErrorReasonModal />
    <RevertConfirmedPostingModal />
    <RefundInvoiceModal />
    <AddPointModal />
    <RevertPointModal />
    <ReleasePending />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import ChangeStepModal from "./ChangeStepModal.vue"
import ErrorReasonModal from "./ErrorReasonModal.vue"
import RevertConfirmedPostingModal from "./RevertConfirmedPostingModal.vue"
import RefundInvoiceModal from "./RefundInvoiceModal.vue"
import AddPointModal from "./AddPointModal.vue"
import RevertPointModal from "./RevertPointModal.vue"
import ReleasePending from "./ReleasePending.vue"

export default defineComponent({
  components: {
    ChangeStepModal,
    ErrorReasonModal,
    RevertConfirmedPostingModal,
    RefundInvoiceModal,
    AddPointModal,
    RevertPointModal,
    ReleasePending,
  },
  setup() {

  },
})
</script>
