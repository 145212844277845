<template>
  <div
    style="max-width:200px;"
    class="dot-ellipsis"
  >
    <a
      href="#"
      @click="downloadFile(file.url)"
    >
      {{ file.url }}
    </a>
    <div>
      <span>
        [{{ file.file_type }}]
      </span>
      <span>
        ({{ file.file_size }}KB)
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import downloadFile from '@/utils/downloadFile'

export default defineComponent({
  props: {
    file: {
      type: Object,
    },
  },
  setup() {
    return {
      downloadFile,
    }
  },
})
</script>

<style scoped>
.dot-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
