<template>
  <TagbyDangerModal
    :visible="visibleSetProgressedModal"
    title="진행 완료"
    @hidden="turnOffModal"
    @ok="setProgressed"
  >
    <div class="mb-1 text-danger">
      <div>- 스킵이나 승인완료 상태가 아닌 원고가 모두 스킵됩니다</div>
      <div>- 등록완료 상태의 포스팅은 모두 승인완료로 변경됩니다</div>
      <div>- 등록되지 않은 포스팅은 모두 스킵됩니다</div>
    </div>
    <NotifySelect />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { useSetProgressed } from '../viewModel'
import NotifySelect from './NotifySelect.vue'

export default defineComponent({
  components: {
    TagbyDangerModal,
    NotifySelect,
  },
  setup() {
    const {
      visibleSetProgressedModal,
      turnOffModal,
      setProgressed,
    } = useSetProgressed()

    return {
      visibleSetProgressedModal,
      turnOffModal,
      setProgressed,
    }
  },
})
</script>
