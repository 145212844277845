<template>
  <TagbyModal
    :isVisible="state.refundInvoiceModal.isVisible"
    :isBusy="state.refundInvoiceModal.isSaving"
    :isDanger="true"
    title="리워드 결제 취소"
    @ok="refundInvoice"
    @hidden="turnOffModal"
  >
    <div class="text-info">
      <div>
        * 사용자의 결제된 금액을 환불하는 기능입니다.
      </div>
      <div>
        * 인플루언서의 적립 예정 및 완료 <span class="text-danger">포인트는 취소하지 않으며</span>, 정산 데이터의 상태는 "결제대기"로 수정됩니다.
      </div>
      <div>
        * 해당 기능을 실행한 후 <span class="text-danger">시간이 흐르면 자동으로 재결제가 실행되므로</span> "결제날짜변경"을 미리 해두셔야 합니다.
      </div>
    </div>
  </TagbyModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyModal from "@/components/TagbyModal.vue"
import state from "../../state"
import useRefundInvoiceModal from "../../hooks/useRefundInvoiceModal"

export default defineComponent({
  components: {
    TagbyModal,
  },
  setup() {
    const {
      turnOffModal,
      refundInvoice,
    } = useRefundInvoiceModal()
    return {
      state,
      turnOffModal,
      refundInvoice,
    }
  },
})
</script>
