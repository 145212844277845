<template>
  <div class="font-italic text-center text-secondary">
    {{ text }}
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  setup() {

  },
})
</script>
