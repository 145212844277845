<template>
  <TagbyPrimaryModal
    :visible="visibleSkipPrePostingModal"
    title="사전원고 스킵"
    @hidden="turnOffModal"
    @ok="skipPrePosting"
  >
    <NotifyTarget />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import { useSkipPrePosting } from '../viewModel'
import NotifyTarget from './NotifyTarget.vue'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    NotifyTarget,
  },
  setup() {
    const {
      visibleSkipPrePostingModal,
      turnOffModal,
      skipPrePosting,
    } = useSkipPrePosting()

    return {
      visibleSkipPrePostingModal,
      turnOffModal,
      skipPrePosting,
    }
  },
})
</script>
