<template>
  <TagbyPrimaryModal
    :visible="visible"
    title="포스팅 스킵"
    @hidden="turnOffModal"
    @ok="skipPosting"
  >
    <div class="text-danger">
      <div>
        - URL이 제출된 포스팅은 확인완료처리 됩니다.
      </div>
    </div>
    <NotifyTarget />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import { useSkipPosting } from '../viewModel'
import NotifyTarget from './NotifyTarget.vue'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    NotifyTarget,
  },
  setup() {
    const {
      visible,
      turnOffModal,
      skipPosting,
    } = useSkipPosting()

    return {
      visible,
      turnOffModal,
      skipPosting,
    }
  },
})
</script>
