<template>
  <TagbyDangerModal
    title="파일 삭제"
    :visible="visibleDeleteCommentFile"
    :content="`${targetCommentFile.file_name} 파일을 삭제하시겠습니까?`"
    @ok="deleteCommentFile"
    @hidden="turnOffDeleteCommentFileModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import {
  useRequestPrePosting,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    const {
      visibleDeleteCommentFile,
      targetCommentFile,
      turnOffDeleteCommentFileModal,
      deleteCommentFile,
    } = useRequestPrePosting()

    return {
      visibleDeleteCommentFile,
      targetCommentFile,
      turnOffDeleteCommentFileModal,
      deleteCommentFile,
    }
  },
})
</script>
