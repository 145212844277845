<template>
  <TagbySidebarWithButton
    :visible="state.addApplySimpleRecruitSidebar.isVisible"
    :isSaving="state.addApplySimpleRecruitSidebar.isSaving"
    :isValid="true"
    title="신청자 추가"
    @ok="addApplySimpleRecruit"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <TagbyOverlay :show="state.addApplySimpleRecruitSidebar.isLoading">
      <BFormGroup>
        <template #label>
          <h4>인플루언서</h4>
        </template>
        <BInputGroup>
          <BFormInput
            v-model="state.addApplySimpleRecruitSidebar.searchText"
            placeholder="인플루언서 IDX 또는 이름을 검색해주세요"
            @keydown.enter="searchInfList"
          />
          <BInputGroupAppend>
            <BButton
              variant="outline-secondary"
              @click="searchInfList"
            >
              검색
            </BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </BFormGroup>
      <ListCard
        :items="state.addApplySimpleRecruitSidebar.infList"
        :fields="['idx', 'name', 'birth']"
        @select="selectInf"
      />

      <div v-if="state.addApplySimpleRecruitSidebar.selectedInf != null">
        <div>
          [선택완료]
        </div>
        <BFormGroup label="idx">
          <BFormInput
            :value="state.addApplySimpleRecruitSidebar.selectedInf.idx"
            readonly
          />
        </BFormGroup>
        <BFormGroup label="name">
          <BFormInput v-model="state.addApplySimpleRecruitSidebar.selectedInf.name" />
        </BFormGroup>
        <BFormGroup label="birth">
          <BFormDatepicker
            v-model="state.addApplySimpleRecruitSidebar.selectedInf.birth"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </BFormGroup>
      </div>

      <BFormGroup
        v-if="mustSelectDestination && isSelectedInf"
      >
        <template #label>
          <h4>배송지</h4>
        </template>
        <BFormSelect
          v-model="state.addApplySimpleRecruitSidebar.selectedDestination"
          :options="destinationOptions"
        />
      </BFormGroup>

      <BFormGroup v-if="hasQuestion">
        <template #label>
          <h4>질문</h4>
        </template>

        <BFormGroup
          v-for="(answer, i) in state.addApplySimpleRecruitSidebar.answerList"
          :key="i"
          invalid-feedback="필수 항목입니다"
          :state="getQuestionState(answer)"
        >
          <template #label>
            <div>
              <b>{{ i+1 }}.</b>
              <span
                v-if="answer.is_required"
                class="tagby-required"
              >*</span>
              <span>{{ answer.question }}</span>
            </div>
          </template>

          <BFormTextarea
            v-model="answer.answer"
            :state="getQuestionState(answer)"
          />
        </BFormGroup>
      </BFormGroup>

      <BFormGroup v-if="hasItem">
        <template #label>
          <h4>상품</h4>
        </template>

        <BFormGroup
          v-for="(item, i) in state.addApplySimpleRecruitSidebar.selectedRewardList"
          :key="i"
        >
          <template #label>
            <div>
              <b>{{ i+1 }}. {{ item.offer_item }}</b>
              <span> {{ item.title }}</span>
              <span v-if="item.is_reward">
                (REWARD)
              </span>
            </div>
          </template>

          <div v-if="item.option_list.length > 0">
            <BFormGroup
              v-for="option in item.option_list"
              :key="option.idx"
            >
              <template #label>
                <span>옵션: {{ option.option_name }}</span>
              </template>

              <BFormInput
                v-if="option.option_type === 'INPUT'"
                v-model="option.select_value"
              />
              <BFormSelect
                v-if="option.option_type === 'SELECT'"
                v-model="option.select_value"
                :options="option.option_value"
              />
            </BFormGroup>
          </div>
          <div
            v-else
            class="font-italic text-center text-secondary"
          >
            -- 옵션 없음 --
          </div>
        </BFormGroup>
      </BFormGroup>

    </TagbyOverlay>
    <div class="text-info mb-1">
      신청자에게 별다른 알림이 전송되지 않는 기능입니다.
    </div>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCard,
  BFormSelect,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue"
import TagbySidebarWithButton from "@/components/TagbySidebarWithButton.vue"
import TagbyOverlay from "@/components/TagbyOverlay.vue"
import state from "../../state"
import useAddApplySimpleRecruit from "../../hooks/useAddApplySimpleRecruit"
import ListCard from "@/views/campaign/components/ListCard"
import TagbySelect from "@/components/TagbySelect.vue"

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ListCard,
    BCard,
    BFormSelect,
    TagbySelect,
    BFormDatepicker,
    BFormTextarea,
  },
  props: {

  },
  setup() {
    const {
      turnOffSidebar,
      addApplySimpleRecruit,
      searchInfList,
      selectInf,
      selectInfDestination,
      mustSelectDestination,
      destinationOptions,
      hasQuestion,
      hasItem,
      isSelectedInf,
    } = useAddApplySimpleRecruit()

    const getQuestionState = data => {
      if (!data.is_required) {
        return null
      }
      if (data.answer) {
        return null
      }
      return false
    }

    return {
      state,
      turnOffSidebar,
      addApplySimpleRecruit,
      searchInfList,
      selectInf,
      selectInfDestination,
      mustSelectDestination,
      destinationOptions,
      getQuestionState,
      hasQuestion,
      hasItem,
      isSelectedInf,
    }
  },
})
</script>

<style scoped>
.tagby-required {
  color: var(--danger);
  margin-right: 5px;
  margin-left: 5px;
}
</style>
