<template>
  <TagbySidebar
    title="신청자 정보"
    :visible="visibleApplyDetailSidebar"
    @hidden="turnOffSidebar"
  >
    <template #content>
      <div v-if="applyQnA.length > 0">
        <h5>질문답변</h5>
        <QnA />
      </div>
      <div v-if="applyItems.length > 0">
        <h5>선택한 상품</h5>
        <Items />
      </div>
    </template>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbySidebar from '@/components/TagbySidebar.vue'
import { useApplyDetail } from '../../viewModel'
import QnA from './QnA.vue'
import Items from './Items.vue'

export default defineComponent({
  components: {
    TagbySidebar,
    QnA,
    Items,
  },
  setup() {
    const {
      visibleApplyDetailSidebar,
      turnOffSidebar,
      applyQnA,
      applyItems,
    } = useApplyDetail()

    return {
      visibleApplyDetailSidebar,
      turnOffSidebar,
      applyQnA,
      applyItems,
    }
  },
})
</script>
