<template>
  <BBadge
    :variant="resolveBcStatusVariant(status)"
    pill
  >
    {{ status }}
  </BBadge>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BBadge,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BBadge,
  },
  props: {
    status: {
      type: String,
    },
  },
  setup() {
    const resolveBcStatusVariant = status => {
      switch (status) {
        case 'TAGGED':
          return 'primary'
        case 'NOT_YET':
          return 'secondary'
        default:
          return 'secondary'
      }
    }

    return {
      resolveBcStatusVariant,
    }
  },
})
</script>
