<template>
  <TagbyDangerModal
    :visible="visible"
    title="중도 이탈"
    @hidden="turnOffModal"
    @ok="quitApply"
  >
    <div class="text-danger">
      - 신청자의 선정을 취소합니다.
    </div>
    <div
      v-if="hasPAUG"
      class="text-danger"
    >
      - 건별결제가 부분 취소 됩니다.
    </div>
    <div class="text-danger">
      - 리워드결제가 부분 취소 됩니다.
    </div>
    <div class="text-danger">
      - 만약 적립 예정인 포인트가 있다면 포인트 적립이 취소됩니다.
    </div>
    <NotifyNot />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyDangerModal from "@/components/TagbyDangerModal.vue"
import { useQuitApply } from "../viewModel"
import NotifyNot from "./NotifyNot.vue"

export default defineComponent({
  components: {
    TagbyDangerModal,
    NotifyNot,
  },
  setup() {
    const {
      visible,
      turnOffModal,
      quitApply,
      hasPAUG,
    } = useQuitApply()

    return {
      visible,
      turnOffModal,
      quitApply,
      hasPAUG,
    }
  },
})
</script>
