<template>
  <TagbySidebarWithButton
    title="채팅 일괄 전송"
    :isSaving="isSaving"
    :isValid="isValid"
    :visible="visibleSendBulkChatSidebar"
    @ok="sendBulkChat"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <div class="text-danger">
      - 채팅 전송이 완료되기 전까지 창을 닫지 말아주세요.
    </div>
    <div class="text-info">
      - 엑셀을 업로드 해주세요. 아래 형식을 따라주세요.
    </div>
    <div class="text-info">
      - 첫 행에 타이틀 username, message라고 각각 적어주세요.
    </div>
    <div class="text-info">
      - 이후 행에 타켓이 될 유저네임과 메세지를 적어주면 됩니다.
    </div>
    <h3 class="mt-1">
      Status: {{ statusSendBulkChat }}
    </h3>
    <TagbyInputRow label="엑셀 파일">
      <BFormFile
        :value="fileSendBulkChat"
        @input="inputFile"
      />
    </TagbyInputRow>
    <BTable
      :fields="tableFields"
      :items="mappedSendChatRows"
      :tbody-tr-class="rowClass"
      show-empty
    />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormTextarea,
  BFormInput,
  BFormFile,
  BTable,
  BButton,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import {
  useSendBulkChat,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BTable,
    BButton,
  },
  setup() {
    return {
      ...useSendBulkChat(),
    }
  },
})
</script>
