<template>
  <TagbyDangerModal
    :visible="visibleCancelRewardModal"
    title="리워드 취소"
    @ok="cancelReward"
    @hidden="turnOffModal"
  >
    <div class="text-info">
      리워드 결제가 완료되었다면 금액이 환불됩니다.
    </div>
    <div class="text-danger">
      인플루언서에게 포인트 지급이 예정되어 있다면 포인트 지급 취소를 따로 실행해야 합니다.
    </div>
    <NotifyNot />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { useCancelReward } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbyDangerModal,
    NotifyNot,
  },
  setup() {
    return {
      ...useCancelReward(),
    }
  },
})
</script>
