<template>
  <TagbyActionButtonV2
    text="캠페인 이관"
    variant="outline-secondary"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import {
  useSetAdv,
} from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useSetAdv()

    return {
      turnOnSidebar,
    }
  },
})
</script>
