import api from "./api"
import state from "./state"
import getters from "./getters"

import useToast from "@/utils/toast"
import useErrorReason from "./useErrorReason"
import {
  useTimelines,
  usePostings,
} from "./viewModel"

import useFetch from "./hooks/useFetch"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnModal: turnOnErrorModal,
  } = useErrorReason()

  const {
    fetchTimelines,
  } = useTimelines()

  const {
    fetchPostings,
  } = usePostings()

  const {
    getInvoiceList,
  } = useFetch()

  const turnOnModal = item => {
    state.revertConfirmedPostingModal.isVisible = true
    state.revertConfirmedPostingModal.targetPosting = item
  }
  const turnOffModal = () => {
    state.revertConfirmedPostingModal.isVisible = false
  }

  const getIsValid = item => {
    if (["CONFIRMED", "SKIP"].includes(item.state)) {
      return true
    }
    return false
  }

  const revertConfirmedPosting = () => api.revertConfirmedPosting({
    posting_idx: state.revertConfirmedPostingModal.targetPosting.idx,
  }).then(() => {
    makeToast("primary", "포스팅 스킵을 되돌리는데 성공했습니다")
    fetchTimelines()
    fetchPostings()
    getInvoiceList()
  }).catch(err => {
    makeToast("danger", "포스팅 스킵을 되돌리는데 실패했습니다")
    turnOnErrorModal(err)
  })

  return {
    turnOnModal,
    turnOffModal,
    getIsValid,
    revertConfirmedPosting,
  }
}
