<template>
  <div>
    <BRow>
      <ReadOnlyInput
        :cols="2"
        label="사전검수 여부"
        :value="mission.is_precheck ? '예' : '아니오'"
      />
      <BCol cols="5">
        <BFormGroup label="미션수행 기간">
          <BInputGroup
            append="일"
            class="input-group-merge"
          >
            <BFormInput
              v-model="mission.end_days"
              type="number"
              number
            />
          </BInputGroup>
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="해시태그">
          <BFormTags
            v-model="mission.hashtag_list"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="제공항목">
          <OfferItemList
            :items="missionItems"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="상세설명">
          <BFormTextarea
            v-model="mission.description"
            rows="3"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="주의사항">
          <BFormTextarea
            v-model="mission.caution_text"
            rows="3"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTags,
  BFormTextarea,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import ReadOnlyInput from '../../components/ReadOnlyInput.vue'
import { useUpdateMission } from '../../viewModel'
import OfferItemList from '../../components/OfferItemList.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormTags,
    BFormTextarea,
    BInputGroup,
    BFormInput,

    ReadOnlyInput,
    OfferItemList,
  },
  setup() {
    const {
      targetMission,
      missionItems,
      updateMission,
    } = useUpdateMission()

    return {
      mission: targetMission,
      missionItems,
      updateMission,
    }
  },
})
</script>
