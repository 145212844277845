<template>
  <TagbySidebarWithButton
    title="스크린샷 잡 추가"
    :visible="visibleAddScreenshotJobSidebar"
    width="30%"
    :isSaving="isSavingScreenshotJob"
    :isValid="isValidScreenshotJob"
    @ok="addScreenshotJob"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <BRow>
      <BCol>
        <BFormGroup label="keywords">
          <BFormTags v-model="screenshotKeyword" />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="interval">
          <BFormInput
            v-model="screenshotInterval"
            type="number"
            number
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="start_at">
          <TagbyDatetimeInputV2
            v-model="screenshotStartAt"
            :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="end_at">
          <TagbyDatetimeInputV2
            v-model="screenshotEndAt"
            :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="posting_count">
          <BFormInput
            :value="postingCount"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="max_screenshot_ranking">
          <BFormInput
            v-model="maxScreenshotRanking"
            type="number"
            number
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="max_ranking">
          <BFormInput
            v-model="maxRanking"
            type="number"
            number
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTags,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import { useAddScreenshotJob } from '../viewModel'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTags,
    TagbySidebarWithButton,
    TagbyDatetimeInputV2,
    NotifyNot,
  },
  setup() {
    return {
      ...useAddScreenshotJob(),
    }
  },
})
</script>
