<template>
  <BRow>
    <BCol>
      <BCard
        v-for="item in items"
        :key="item.idx"
        class="flex-column align-items-start"
        border-variant="secondary"
      >
        <BRow>
          <BCol
            cols="6"
            md="2"
          >
            <BBadge class="mr-1">
              {{ item.offer_item }}
            </BBadge>
          </BCol>
          <BCol
            cols="6"
            md="3"
          >
            idx {{ item.idx }}
          </BCol>
          <BCol
            cols="6"
            md="7"
          >
            title {{ item.title }}
          </BCol>
          <BCol
            cols="6"
            md="4"
          >
            price {{ resolveMoney(item.price) }}
          </BCol>
          <BCol
            cols="12"
            md="8"
          >
            <div v-if="item.idx !== state.inputCategory.targetItem.idx">
              category {{ item.category }}
              <span
                class="my-edit-icon"
              >
                <feather-icon
                  icon="EditIcon"
                  @click="startEditCategory(item)"
                />
              </span>
            </div>
            <div
              v-else
              class="d-flex align-items-center"
            >
              <TagbySelect
                :options="getters.targetCategoryList"
                :value="item.category"
                :reduce="opt => opt.value"
                @input="inputCategory(item.idx, $event)"
              />
              <span
                class="my-edit-icon ml-1"
              >
                <feather-icon
                  icon="XIcon"
                  @click="endEditCategory"
                />
              </span>
            </div>
          </BCol>
          <BCol
            v-if="item.offer_item === 'ONLINE'"
            cols="12"
          >
            <BLink
              :href="item.online_link"
              target="_blank"
            >
              {{ item.online_link }}
            </BLink>
            <TagbyCopyIcon :value="item.online_link" />
          </BCol>
          <BCol
            v-if="item.offer_item === 'OFFLINE'"
            cols="12"
          >
            {{ item.visit_company_name }} / {{ item.visit_address_basic }} {{ item.visit_address_detail }}
          </BCol>

        </BRow>
        <OfferItemOptionList
          v-if="item.options"
          :options="item.options"
        />
      </BCard>
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BBadge,
  BLink,
  BFormSelect,
} from 'bootstrap-vue'
import OfferItemOptionList from './OfferItemOptionList.vue'
import useResolveValue from '@/utils/resolveValue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'
import state from '../state'
import getters from '../getters'
import useInputCategory from '../useInputCategory'
import TagbySelect from '@/components/TagbySelect.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BBadge,
    OfferItemOptionList,
    BLink,
    BFormSelect,
    TagbyCopyIcon,
    TagbySelect,
  },
  props: {
    items: {
      type: Array,
    },
  },
  setup() {
    const {
      resolveMoney,
    } = useResolveValue()

    const {
      startEditCategory,
      endEditCategory,
      inputCategory,
    } = useInputCategory()

    return {
      state,
      getters,
      resolveMoney,
      startEditCategory,
      endEditCategory,
      inputCategory,
    }
  },
})
</script>

<style scoped>
.my-edit-icon {
  cursor: pointer;
}

.my-edit-icon:hover {
  color: var(--primary)
}
</style>
