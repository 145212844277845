<template>
  <TagbySidebarWithButton
    title="신청자 다운로드"
    :visible="visibleDownloadApplyExcelSidebar"
    :isSaving="isDownloadingApplyDetailExcel"
    :isValid="true"
    @ok="downloadApplyDetailExcel"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <BFormGroup label="TYPE">
      <TagbySelect
        v-model="applyState"
        :options="applyStateOptions"
      />
    </BFormGroup>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import {
  useApplyDetailExcel,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbySelect,
    BFormGroup,
  },
  setup() {
    const {
      visibleDownloadApplyExcelSidebar,
      turnOffSidebar,
      downloadApplyDetailExcel,
      applyState,
      applyStateOptions,
      isDownloadingApplyDetailExcel,
    } = useApplyDetailExcel()

    return {
      visibleDownloadApplyExcelSidebar,
      turnOffSidebar,
      downloadApplyDetailExcel,
      applyState,
      applyStateOptions,
      isDownloadingApplyDetailExcel,
    }
  },
})
</script>
