<template>
  <TagbySidebarWithButton
    title="중재 접수"
    :visible="visibleCreateClaimSidebar"
    :isSaving="isSaving"
    :isValid="isValid"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
    @ok="createClaim"
  >
    <TagbyInputRow label="사유">
      <BFormTextarea v-model="reasonCreateClaim" />
    </TagbyInputRow>
    <div class="text-danger">
      해당 인플루언서의 포인트 출금이 제한됩니다
    </div>
    <NotifyTarget />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormTextarea,
} from "bootstrap-vue"
import TagbySidebarWithButton from "@/components/TagbySidebarWithButton.vue"
import {
  useCreateClaim,
} from "../viewModel"
import TagbyInputRow from "@/components/TagbyInputRow.vue"
import TagbySnsColumn from "@/components/TagbySnsColumn.vue"
import NotifyTarget from "./NotifyTarget.vue"

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormTextarea,
    TagbySnsColumn,
    NotifyTarget,
  },
  setup() {
    const {
      visibleCreateClaimSidebar,
      turnOffSidebar,
      createClaim,
      isSaving,
      isValid,
      reasonCreateClaim,
    } = useCreateClaim()

    return {
      visibleCreateClaimSidebar,
      turnOffSidebar,
      createClaim,
      isSaving,
      isValid,
      reasonCreateClaim,
    }
  },
})
</script>
