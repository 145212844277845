<template>
  <div
    v-if="url"
    class="d-flex"
  >
    <div
      class="dot-ellipsis"
      style="max-width:200px;"
    >
      <BLink
        :href="url"
        target="_blank"
      >
        {{ url }}
      </BLink>
    </div>
    <TagbyCopyIcon :value="url" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
} from 'bootstrap-vue'
import TagbyCopyIcon from '@/components/TagbyCopyIcon.vue'

export default defineComponent({
  components: {
    TagbyCopyIcon,
    BLink,
  },
  props: {
    url: {
      type: String,
    },
  },
  setup() {

  },
})
</script>

<style scoped>
.dot-ellipsis{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
</style>
