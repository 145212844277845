<template>
  <BCard no-body>
    <BRow
      v-for="(data, i) in applyItems"
      :key="i"
    >
      <BCol>
        <div>
          <b>{{ i+1 }}. {{ data.offer_item }}</b>
          <span> {{ data.title }}</span>
        </div>
        <div v-if="data.options.length > 0">
          <BFormGroup
            v-for="option in data.options"
            :key="option.idx"
          >
            <template #label>
              <span>옵션: {{ option.option_name }}</span>
            </template>

            <BFormInput
              :value="option.rewardSelect.select_value"
              readonly
            />
          </BFormGroup>
        </div>
        <EmptyText
          v-else
          text="-- 옵션 없음 --"
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { useApplyDetail } from '../../viewModel'
import EmptyText from '../../components/EmptyText.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    EmptyText,
  },
  setup() {
    const {
      applyItems,
    } = useApplyDetail()

    return {
      applyItems,
    }
  },
})
</script>
