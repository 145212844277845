<template>
  <BCard
    no-body
    class="mb-2"
  >
    <BFormGroup
      v-for="(data, i) in applyQnA"
      :key="i"
    >
      <template #label>
        <span>{{ i+1 }}. {{ data.question }}</span>
      </template>
      <BFormTextarea
        v-if="data.answer"
        :value="data.answer.answer"
        readonly
      />
      <EmptyText
        v-else
        text="-- 답변 없음 --"
      />
    </BFormGroup>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import { useApplyDetail } from '../../viewModel'
import EmptyText from '../../components/EmptyText.vue'

export default defineComponent({
  components: {
    BCard,
    BFormGroup,
    BFormTextarea,
    EmptyText,
  },
  setup() {
    const {
      applyQnA,
    } = useApplyDetail()

    return {
      applyQnA,
    }
  },
})
</script>
