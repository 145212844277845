<template>
  <BTable
    :items="missions"
    :fields="fields"
    small
    show-empty
  >
    <template #cell(idx)="data">
      <a
        href="#"
        @click="turnOnSidebar(data.item)"
      >
        {{ data.value }}
      </a>
    </template>

    <template #cell(mission_type)>
      {{ missionType }}
    </template>

    <template #cell(posting_period)="data">
      <div style="width:90px">
        {{ resolvePeriod(data.item.start_at, data.item.end_at) }}
      </div>
    </template>

    <template #cell(pre_posting_period)="data">
      <div style="width:90px">
        {{ resolvePeriod(data.item.pre_posting_start_at, data.item.pre_posting_end_at) }}
      </div>
    </template>
  </BTable>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
  BFormCheckbox,
} from 'bootstrap-vue'
import { useMissions, useUpdateMission } from '../../viewModel'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'

export default defineComponent({
  components: {
    BTable,
    BFormCheckbox,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      missions,
      missionType,
      resolvePeriod,
    } = useMissions()

    const {
      turnOnSidebar,
    } = useUpdateMission()

    const fields = [
      { key: 'idx', label: 'idx' },
      { key: 'mission_type', label: '미션유형' },
      { key: 'pre_posting_period', label: '검수기간' },
      { key: 'posting_period', label: '포스팅기간' },
      { key: 'action_type_list', label: '수행미션' },
      { key: 'required_action_list', label: '필수포함' },
    ]

    return {
      missions,
      fields,
      missionType,
      resolvePeriod,
      turnOnSidebar,
    }
  },
})
</script>
