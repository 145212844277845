<template>
  <TagbyDangerModal
    :visible="visiblePendingModal"
    title="캠페인 보류"
    @hidden="turnOffModal"
    @ok="pending"
  >
    <NotifySelect />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import { usePending } from '../viewModel'
import NotifySelect from './NotifySelect.vue'

export default defineComponent({
  components: {
    TagbyDangerModal,
    NotifySelect,
  },
  setup() {
    const {
      visiblePendingModal,
      turnOffModal,
      pending,
    } = usePending()

    return {
      visiblePendingModal,
      turnOffModal,
      pending,
    }
  },
})
</script>
