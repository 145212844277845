<template>
  <TagbyPrimaryModal
    :visible="visibleAddBulkPartnershipModal"
    title="브랜드 파트너십 요청"
    @ok="addBulkPartnership"
    @hidden="turnOffModal"
  >
    <div>
      선정자 전체에게 파트너십 요청을 하겠습니까
    </div>
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'
import {
  useAddBulkPartnership,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    TagbySnsColumn,
  },
  setup() {
    const {
      visibleAddBulkPartnershipModal,
      addBulkPartnership,
      turnOffModal,
    } = useAddBulkPartnership()

    return {
      visibleAddBulkPartnershipModal,
      addBulkPartnership,
      turnOffModal,
    }
  },
})
</script>
