import axios from '@axios'

const STORE_MODULE_NAME = 'compt'

const actions = {
  fetchAdminMe(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/admin/me/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchComptDetail(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/detail/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchTimelines(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/timelines/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchImgFiles(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/img-files/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchMissions(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/missions/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateThumbImg(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-thumb-img/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateDetailImg(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-detail-img/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchRewards(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/rewards/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchItems(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/items/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchItemOptions(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/item-options/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateRewardItem(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-reward-item/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchQuestions(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/questions/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateMission(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-mission/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateEndlessMission(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-endless-mission/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchNotificationMessage(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/notification-message/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchApplies(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/applies/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchSnsList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/sns-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchSnsCategories(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/sns-categories/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchClaims(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/claims/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchApplyAnswers(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/apply-answers/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchApplyRewardSelects(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/apply-reward-selects/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchGuides(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/guides/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchGuideFiles(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/guide-files/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchDeliveries(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/deliveries/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchDestinations(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/destinations/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPrePostings(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/pre-postings/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPrePostingFiles(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/pre-posting-files/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPostings(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/postings/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchInvoices(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/invoices/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchChatRooms(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/chat-rooms/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchChats(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/chats/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchChatFiles(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/chat-files/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateCompt(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-compt/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  createMemo(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/create-memo/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  searchSnsList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/search-sns-list/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  searchDestinations(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/search-destinations/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  addApply(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/add-apply/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  pending(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/pending/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  releasePending(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/release-pending/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  releasePendingEndless(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/release-pending-endless/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  pullUp(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/pull-up/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  setProgressed(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/set-progressed/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchAllPrePostingFiles(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/all-pre-posting-files/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchScreenshotJobList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/screenshot-job-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  addScreenshotJob(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/add-screenshot-job/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchAdvertiser(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/advertiser/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  tempSelectApply(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/temp-select-apply/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  selectApply(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/select-apply/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  inactivateApply(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/inactivate-apply/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  quitApply(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/quit-apply/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateGuide(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-guide/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  resetDelivery(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/reset-delivery/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateDelivery(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-delivery/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchShipmentCompanyList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/shipment-company-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  skipPrePosting(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/skip-pre-posting/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  requestPrePosting(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/request-pre-posting/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updatePrePostingExpiredAt(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-pre-posting-expired-at/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  resetPosting(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/reset-posting/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  registerPosting(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/register-posting/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  skipPosting(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/skip-posting/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updatePostingExpiredAt(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-posting-expired-at/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  getApplyExcel(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/apply-excel/`, { params, responseType: 'blob' })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchNotificationTemplates(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/notification-templates/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  sendNotification(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/send-notification/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  sendBulkNotification(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/send-bulk-notification/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchBrandSns(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/brand-sns/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchBrandPartnershipList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/brand-partnership-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchBrandedContents(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/branded-contents/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  addPartnership(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/add-partnership/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  addBulkPartnership(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/add-bulk-partnership/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  setStop(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/set-stop/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchAdvList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/adv-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  setAdv(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/set-adv/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPointList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/point-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateInvoiceAmount(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-invoice-amount/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  payInvoice(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/pay-invoice/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  createClaim(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/create-claim/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  resolveClaim(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/resolve-claim/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  getApplyDetailExcel(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        // .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/apply-detail-excel/`, { params, responseType: 'blob' })
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/campaign-apply/inf/download/`, params, { responseType: 'blob' })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  getApplyDetailExcelV2(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/campaign-apply/v2/inf/download/`, params, { responseType: 'blob' })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPostingExtraList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/posting-extra-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchPrePostingExtraList(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/pre-posting-extra-list/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  sendChat(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/send-chat/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  createBulkChatHistory(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/create-bulk-chat-history/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  moveAppliers(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/move-appliers/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchHasPAUG(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/has-paug/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  cancelReward(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt/cancel-reward/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchDifferentSnsLog(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/different-sns-log/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  createGuide(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/create-guide/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updatePayDate(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-pay-date/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  cancelPoint(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/cancel-point/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateHasGuide(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/compt-re/update-has-guide/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}
