<template>
  <BCard title="공통 가이드">
    <BTable
      :fields="commonGuideFields"
      :items="commonGuides"
      responsive
      small
      style="overflow-x: visible"
    >
      <template #cell(item)="data">
        <div v-if="data.item.item != null">
          {{ data.item.item.title }}({{ data.item.item.offer_item }})
        </div>
      </template>

      <template #cell(guide_file)="data">
        <DownloadFileColumn
          v-if="data.item.file != null"
          :file="data.item.file"
        />
      </template>

      <template #cell(guide_link)="data">
        <BLink
          target="_blank"
          :href="data.item.guide_link"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            icon="EditIcon"
            text="수정"
            @click="turnOnSidebar(data.item)"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import { useGuides, useUpdateGuide } from '../../viewModel'
import DownloadFileColumn from '../../components/DownloadFileColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    DownloadFileColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      commonGuideFields,
      commonGuides,
    } = useGuides()

    const {
      turnOnSidebar,
    } = useUpdateGuide()

    return {
      commonGuideFields,
      commonGuides,
      turnOnSidebar,
    }
  },
})
</script>
