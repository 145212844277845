<template>
  <BTable
    responsive
    small
    :fields="fields"
    :items="mappedPostings"
    :filter="filterText"
    style="overflow-x: visible;"
  >
    <template #cell(username)="data">
      <TagbySnsColumn :sns="data.item.sns" />
    </template>

    <template #cell(mission_idx)="data">
      <a
        href="#"
        @click="turnOnMissionSidebar(data.item.mission)"
      >
        {{ data.value }}
      </a>
    </template>

    <template #cell(url)="data">
      <TagbyConditionalLink
        :href="data.value"
        :text="data.value"
        :stateList="['LINK', 'TEXT', 'COPY']"
      />
    </template>

    <template #cell(state)="data">
      <div>
        <div>
          {{ resolveState(data.item) }}
        </div>
        <div v-if="getVisibleCertState(data.item)">
          ({{ data.item.cert_state }})
        </div>
      </div>
    </template>

    <template #cell(registered_at)="data">
      <TagbyDatetimeColumn :value="data.value" />
    </template>

    <template #cell(confirmed_at)="data">
      <TagbyDatetimeColumn :value="data.value" />
    </template>

    <template #cell(expired_at)="data">
      <div>
        <TagbyDateColumn :value="data.value" />
        <div
          v-if="data.item.extra != null"
          class="d-flex"
        >
          <div>연장: </div>
          <TagbyDateColumn
            :value="data.item.extra.expired_at"
          />
        </div>
      </div>
    </template>

    <template #cell(bc_status)="data">
      <BcStatusColumn :status="data.item.brandedContent != null ? 'TAGGED' : 'NOT_YET'" />
    </template>

    <template #cell(inf)="data">
      <InfLinkColumn :inf="data.item.sns.inf" />
    </template>

    <template #cell(inf_phone)="data">
      {{ data.item.sns.inf.phone }}
    </template>

    <template #cell(inf_caution)="data">
      {{ data.item.sns.inf.caution_count }}
    </template>

    <template #cell(actions)="data">
      <TagbyTableAction>
        <TagbyTableActionItem
          :disabled="!getIsValidReset(data.item)"
          icon="XSquareIcon"
          text="포스팅 초기화"
          @click="turnOnResetModal(data.item)"
        />
        <TagbyTableActionItem
          icon="InstagramIcon"
          text="포스팅 수정"
          @click="turnOnRegisterSidebar(data.item)"
        />
        <TagbyTableActionItem
          :disabled="!getIsValidSkip(data.item)"
          icon="SkipForwardIcon"
          text="포스팅 스킵"
          @click="turnOnSkipModal(data.item)"
        />
        <TagbyTableActionItem
          icon="WatchIcon"
          text="제출기한 수정"
          @click="turnOnPostingExpiredAtSidebar(data.item)"
        />
        <TagbyTableActionItem
          :disabled="!getIsValidRevertConfirmedPosting(data.item)"
          icon="RepeatIcon"
          text="승인 되돌리기"
          @click="turnOnRevertConfirmedPostingModal(data.item)"
        />
      </TagbyTableAction>
    </template>
  </BTable>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BTable,
} from "bootstrap-vue"
import {
  usePostings,
  useUpdateMission,
  useResetPosting,
  useRegisterPosting,
  useSkipPosting,
  usePostingExpiredAt,
} from "../../viewModel"
import TagbySnsColumn from "@/components/TagbySnsColumn.vue"
import InfLinkColumn from "../../components/InfLinkColumn.vue"
import useResolveValue from "@/utils/resolveValue"
import TagbyDatetimeColumn from "@/components/TagbyDatetimeColumn.vue"
import TagbyDateColumn from "@/components/TagbyDateColumn.vue"
import UrlColumn from "../../components/UrlColumn.vue"
import BcStatusColumn from "../../components/BcStatusColumn.vue"
import TagbyTableAction from "@/components/TagbyTableAction.vue"
import TagbyTableActionItem from "@/components/TagbyTableActionItem.vue"
import TagbyConditionalLink from "@/components/TagbyConditionalLink.vue"
import useRevertConfirmedPostingModal from "../../useRevertConfirmedPostingModal"

export default defineComponent({
  components: {
    BTable,
    TagbySnsColumn,
    InfLinkColumn,
    TagbyDatetimeColumn,
    TagbyDateColumn,
    UrlColumn,
    BcStatusColumn,
    TagbyTableAction,
    TagbyTableActionItem,
    TagbyConditionalLink,
  },
  setup() {
    const {
      mappedPostings,
      fields,
      filterText,
    } = usePostings()

    const { turnOnSidebar: turnOnMissionSidebar } = useUpdateMission()
    const { resolveDate } = useResolveValue()

    const {
      turnOnModal: turnOnResetModal,
      getIsValid: getIsValidReset,
    } = useResetPosting()

    const {
      turnOnSidebar: turnOnRegisterSidebar,
      getIsValid: getIsValidRegister,
    } = useRegisterPosting()

    const {
      turnOnModal: turnOnSkipModal,
      getIsValid: getIsValidSkip,
    } = useSkipPosting()

    const {
      turnOnSidebar: turnOnPostingExpiredAtSidebar,
    } = usePostingExpiredAt()

    const {
      turnOnModal: turnOnRevertConfirmedPostingModal,
      getIsValid: getIsValidRevertConfirmedPosting,
    } = useRevertConfirmedPostingModal()

    const getVisibleCertState = item => {
      if (["NOT_POSTING", "WAITING"].includes(item.state)) {
        return false
      }
      if (item.cert_state == null || item.cert_state === "SUCCESS") {
        return false
      }
      return true
    }

    const resolveState = item => {
      if (item.state === "NOT_POSTING") {
        return "미등록"
      }
      if (item.state === "WAITING") {
        return "등록중"
      }
      if (item.state === "NOT_CERT") {
        return "등록실패"
      }
      if (item.state === "REGISTERED") {
        return "등록완료"
      }
      if (item.state === "SKIP") {
        return "스킵"
      }
      if (item.state === "DELETED") {
        return "삭제됨"
      }
      if (item.state === "CONFIRMED" && item.confirmed_at == null) {
        return "스킵"
      }
      if (item.state === "CONFIRMED" && item.confirmed_at != null) {
        return "확인완료"
      }
      return item.state
    }

    return {
      mappedPostings,
      fields,
      filterText,
      turnOnMissionSidebar,
      resolveDate,
      resolveState,
      getVisibleCertState,

      turnOnResetModal,
      getIsValidReset,
      turnOnRegisterSidebar,
      getIsValidRegister,
      turnOnSkipModal,
      getIsValidSkip,
      turnOnPostingExpiredAtSidebar,
      turnOnRevertConfirmedPostingModal,
      getIsValidRevertConfirmedPosting,
    }
  },
})
</script>
