<template>
  <TagbySidebarWithButton
    :visible="visible"
    title="포스팅 등록"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="registerPosting"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <BRow>
      <BCol>
        <BFormGroup label="URL">
          <BFormInput v-model="targetPosting.url" />
        </BFormGroup>
      </BCol>
    </BRow>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import { useRegisterPosting } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    NotifyNot,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  setup() {
    const {
      targetPosting,
      visible,
      isSaving,
      isValid,
      turnOffSidebar,
      registerPosting,
    } = useRegisterPosting()

    return {
      targetPosting,
      visible,
      isSaving,
      isValid,
      turnOffSidebar,
      registerPosting,
    }
  },
})
</script>
