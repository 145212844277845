<template>
  <div>
    <h4>SNS</h4>
    <BRow>
      <BCol>
        <BFormGroup
          :state="hasSelectedSns && !isEmptySearchedSns"
          :valid-feedback="`${targetUsername} [${targetSnsService}]`"
          :invalid-feedback="invalidFeedback"
        >
          <VueAutosuggest
            :value="textForSearchSns"
            :suggestions="snsSuggestion"
            :limit="100"
            :input-props="inputProps"
            @keyup.enter="searchSnsList"
            @selected="selectSns"
            @input="inpuText"
          >
            <template slot-scope="{suggestion}">
              <div class="d-flex align-items-center">
                <BAvatar :src="suggestion.item.profile_img_url" />
                <div class="detail ml-50">
                  <BCardText class="mb-0">
                    {{ suggestion.item.username }}
                  </BCardText>
                  <small class="text-muted">
                    {{ suggestion.item.service }}
                  </small>
                </div>
              </div>
            </template>
          </VueAutosuggest>
        </BFormGroup>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BAvatar,
  BCardText,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { useAddApply } from '../../viewModel'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    VueAutosuggest,
    BAvatar,
    BCardText,
  },
  setup() {
    const {
      targetSns,
      textForSearchSns,
      searchedSnsList,
      hasSelectedSns,
      isEmptySearchedSns,
      invalidFeedback,
      targetSnsService,
      targetUsername,
      searchSnsList,
      selectSns,
      inpuText,
    } = useAddApply()

    const snsSuggestion = computed(() => ([{
      data: searchedSnsList.value,
    }]))

    const inputProps = {
      id: 'autosuggest__input',
      class: 'form-control',
      placeholder: 'Type sns_id and input enter key',
    }

    return {
      snsSuggestion,
      inputProps,

      targetSns,
      textForSearchSns,
      searchedSnsList,
      hasSelectedSns,
      isEmptySearchedSns,
      invalidFeedback,
      targetSnsService,
      targetUsername,

      searchSnsList,
      selectSns,
      inpuText,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
