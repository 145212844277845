<template>
  <TagbyActionButtonV2
    v-if="isEndless"
    text="진행 중단"
    variant="outline-danger"
    :isValid="isValid"
    @click="turnOnModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import { useSetStop } from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      isEndless,
      isValid,
      turnOnModal,
    } = useSetStop()
    return {
      isEndless,
      isValid,
      turnOnModal,
    }
  },
})
</script>
