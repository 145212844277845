<template>
  <BBadge
    v-if="status != null"
    :variant="resolveStatusVariant(status)"
    pill
  >
    {{ status }}
  </BBadge>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BBadge,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BBadge,
  },
  props: {
    status: {
      type: String,
    },
  },
  setup() {
    const resolveStatusVariant = status => {
      if (status === 'Approved') {
        return 'primary'
      } if (status === 'Pending Approval') {
        return 'success'
      } if (status === 'Canceled' || status === 'Unable') {
        return 'secondary'
      } if (status === 'Not Applied') {
        return 'warning'
      }
      return 'danger'
    }

    return {
      resolveStatusVariant,
    }
  },
})
</script>
