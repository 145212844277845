import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"

export default () => {
  const {
    makeToast,
  } = useToast()

  const getComptDetail = () => {
    state.isLoadingCompt = true
    state.hasLoadError = false
    return api.getComptDetail({
      campaign_idx: state.idx,
    }).then(response => {
      state.compt = response.data.data
      state.savedCompt = JSON.parse(JSON.stringify(response.data.data))
    }).catch(() => {
      state.hasLoadError = true
    }).finally(() => {
      state.isLoadingCompt = false
    })
  }

  const getTimelineList = () => api.getTimelineList({
    info_table: "campaign_info",
    info_idx: state.idx,
  }).then(response => {
    state.timelineList = response.data.data
  }).catch(() => {
    makeToast("danger", "타임라인을 가져오는데 실패했습니다")
  })

  const getInvoiceList = () => api.getInvoiceList({
    campaign_idx: state.idx,
  }).then(response => {
    state.invoiceList = response.data.data
  }).catch(() => {
    makeToast("danger", "정산 목록을 가져오는데 실패했습니다")
  })

  const getAdv = () => api.getAdv({
    campaign_idx: state.idx,
  }).then(response => {
    state.adv = response.data.data
  }).catch(() => {
    makeToast("danger", "광고주 정보를 가져오는데 실패했습니다")
  })

  const getApplyList = () => api.getApplyList({
    campaign_idx: state.idx,
  }).then(response => {
    state.applyList = response.data.data
  }).catch(() => {
    makeToast("danger", "지원자 목록을 가져오는데 실패했습니다")
  })

  const getQuestionList = () => api.getQuestionList({
    campaign_idx: state.idx,
  }).then(response => {
    state.questionList = response.data.data
  }).catch(() => {
    makeToast("danger", "질문 목록을 가져오는데 실패했습니다")
  })

  const getItemList = () => api.getItemList({
    campaign_idx: state.idx,
  }).then(response => {
    state.itemList = response.data.data
  }).catch(() => {
    makeToast("danger", "상품 목록을 가져오는데 실패했습니다")
  })

  return {
    getComptDetail,
    getTimelineList,
    getInvoiceList,
    getAdv,
    getApplyList,
    getQuestionList,
    getItemList,
  }
}
