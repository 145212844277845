<template>
  <TagbyActionButtonV2
    text="캠페인 복사 및 신청자 이동"
    variant="outline-secondary"
    :isValid="true"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import { useMoveAppliers } from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useMoveAppliers()
    return {
      turnOnSidebar,
    }
  },
})
</script>
