<template>
  <TagbySidebar
    :visible="visiblePrePostingDetailSidebar"
    title="사전원고 상세"
    @hidden="turnOffSidebar"
  >
    <template #content>
      <FilesTable
        title="제출정보"
        :files="targetPrePostingFiles"
        :text="targetPrePosting.text"
      />
      <FilesTable
        title="수정요청"
        :files="targetPrePostingCommentFiles"
        :text="targetPrePosting.request_comment"
      />
    </template>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebar from '@/components/TagbySidebar.vue'
import { usePrePostingDetail } from '../viewModel'
import FilesTable from '../components/FilesTable.vue'

export default defineComponent({
  components: {
    TagbySidebar,
    BFormGroup,
    BFormTextarea,
    FilesTable,
  },
  setup() {
    const {
      visiblePrePostingDetailSidebar,
      turnOffSidebar,
      targetPrePosting,
      targetPrePostingFiles,
      targetPrePostingCommentFiles,
    } = usePrePostingDetail()

    return {
      visiblePrePostingDetailSidebar,
      turnOffSidebar,
      targetPrePosting,
      targetPrePostingFiles,
      targetPrePostingCommentFiles,
    }
  },
})
</script>
