<template>
  <TagbyDangerModal
    title="포인트 지급 취소"
    :visible="visibleCancelPointModal"
    @ok="cancelPoint"
    @hidden="turnOffModal"
  >
    <div class="text-danger">
      리워드 결제가 취소되지는 않습니다.
    </div>
    <NotifyNot />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'
import NotifyNot from './NotifyNot.vue'
import {
  useCancelPoint,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbyDangerModal,
    NotifyNot,
  },
  setup() {
    return {
      ...useCancelPoint(),
    }
  },
})
</script>
