<template>
  <TagbyActionButtonV2
    text="원고 파일 일괄 다운로드"
    variant="outline-secondary"
    @click="turnOnModal"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionButtonV2 from "@/components/TagbyActionButtonV2.vue"
import { useDownloadPrepostingImg } from "../../viewModel"

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnModal,
    } = useDownloadPrepostingImg()

    return {
      turnOnModal,
    }
  },
})
</script>
