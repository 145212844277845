<template>
  <div>
    <CommonGuides />
    <IndividualGuides />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import CommonGuides from './CommonGuides.vue'
import IndividualGuides from './IndividualGuides.vue'

export default defineComponent({
  components: {
    CommonGuides,
    IndividualGuides,
  },
  setup() {

  },
})
</script>
