import {
  ref,
  computed,
} from "@vue/composition-api"
import JSZip from "jszip"
import moment from "moment"
import readXlsxFile from "read-excel-file"
import { Semaphore } from "@shopify/semaphore"
import useToast from "@/utils/toast"
import useStore from "@/utils/store"
import useResolveValue from "@/utils/resolveValue"
import { getRouterIdx } from "@/utils/router"
import comptReStoreModule from "@/store/tagby/comptRe"
import downloadFile from "@/utils/downloadFile"
import { code } from "@/utils/instaConsole"
import uploadFile from "@/utils/uploadFile"
import state from "./state"
import getters from "./getters"
import useFetch from "./hooks/useFetch"

const routerIdx = ref()
const adminData = ref({})
const adminPermission = computed(() => adminData.value?.permission)
const hasCampaignPermission = computed(() => ["ADMIN", "C_MANAGER"].includes(adminPermission.value))
export function useInitialize() {
  const initialize = () => {
    routerIdx.value = getRouterIdx()

    const { registerModule } = useStore(comptReStoreModule.STORE_MODULE_NAME, comptReStoreModule)
    registerModule()

    const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
    dispatch("fetchAdminMe").then(response => {
      adminData.value = response.data.data
    })
  }

  return {
    initialize,
    adminPermission,
  }
}

const isEndless = computed(() => state.compt.is_endless ?? false)
const comptStep = computed(() => state.compt.step)
const isRecruitStep = computed(() => [null, "SELECT"].includes(state.compt.manage_step))
export function useComptDetail() {
  return {
    isEndless,
  }
}

export function useTimelines() {
  const {
    getTimelineList,
  } = useFetch()
  const fetchTimelines = getTimelineList
  const timelines = computed(() => state.timelineList)

  return {
    timelines,
    fetchTimelines,
  }
}

const willBeSentNotification = ref(false)
const notificationMessage = ref("")
export function useNotification() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchNotificationMessage = templateType => {
    dispatch("fetchNotificationMessage", {
      campaign_idx: routerIdx.value,
      template_type: templateType,
    }).then(response => {
      notificationMessage.value = response.data.data
    }).catch(() => {
      makeToast("danger", "인플루언서에게 전달할 알림 내용을 가져오는데 실패했습니다")
    })
  }
  return {
    willBeSentNotification,
    message: notificationMessage,
    fetchNotificationMessage,
  }
}
const getIndexedData = (data, key = "idx") => {
  if (data == null) {
    return {}
  }
  return data.reduce((p, c) => ({ ...p, [c[key]]: c }), {})
}
const filesIndexing = ref({})
const files = computed(() => Object.values(filesIndexing.value))
const thumbImgFile = computed(() => {
  const imgIdx = state.compt.thumb_img_idx
  return filesIndexing.value[imgIdx]
})
const detailImgFile = computed(() => {
  const imgIdx = state.compt.detail_img_idx
  return filesIndexing.value[imgIdx]
})
export function useImgFiles() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchImgFiles = () => {
    dispatch("fetchImgFiles", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      const { data } = response.data
      const indexedData = getIndexedData(data)
      filesIndexing.value = {
        ...filesIndexing.value,
        ...indexedData,
      }
    }).catch(() => {
      makeToast("danger", "캠페인 이미지를 불러오는데 실패했습니다")
    })
  }
  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()
  const isSavingImgFile = ref(false)
  const thumbImgRef = ref()
  const updateThumbImg = imgObj => {
    isSavingImgFile.value = true
    dispatch("updateThumbImg", {
      campaign_idx: routerIdx.value,
      thumb_img_idx: imgObj.idx,
    }).then(() => {
      thumbImgRef.value.turnOffSidebar()
      const indexedData = getIndexedData([imgObj])
      filesIndexing.value = {
        ...filesIndexing.value,
        ...indexedData,
      }
      getComptDetail()
      fetchTimelines()
      makeToast("primary", "캠페인 대표 이미지를 변경하는데 성공했습니다")
    }).catch(() => {
      makeToast("danger", "캠페인 대표 이미지를 변경하는데 실패했습니다")
    }).finally(() => {
      isSavingImgFile.value = false
    })
  }

  const detailImgRef = ref()
  const updateDetailImg = imgObj => {
    isSavingImgFile.value = true
    dispatch("updateDetailImg", {
      campaign_idx: routerIdx.value,
      detail_img_idx: imgObj.idx,
    }).then(() => {
      detailImgRef.value.turnOffSidebar()
      const indexedData = getIndexedData([imgObj])
      filesIndexing.value = {
        ...filesIndexing.value,
        ...indexedData,
      }
      getComptDetail()
      fetchTimelines()
      makeToast("primary", "캠페인 상세 이미지를 변경하는데 성공했습니다")
    }).catch(() => {
      makeToast("danger", "캠페인 상세 이미지를 변경하는데 실패했습니다")
    }).finally(() => {
      isSavingImgFile.value = false
    })
  }

  return {
    filesIndexing,
    files,
    thumbImgFile,
    detailImgFile,
    isSavingImgFile,
    fetchImgFiles,

    thumbImgRef,
    updateThumbImg,

    detailImgRef,
    updateDetailImg,
  }
}

const rewards = ref([])
export function useRewards() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchRewards = () => dispatch("fetchRewards", {
    campaign_idx: routerIdx.value,
  }).then(response => {
    rewards.value = response.data.data
    return new Promise(resolve => resolve())
  }).catch(() => {
    makeToast("danger", "리워드 정보를 가져오는데 실패했습니다")
  })

  return {
    rewards,
    fetchRewards,
  }
}

const itemIndexing = computed(() => getIndexedData(state.items))
export function useItems() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchItems = () => dispatch("fetchItems", {
    campaign_idx: routerIdx.value,
  }).then(response => {
    state.items = response.data.data
    return new Promise(resolve => resolve())
  }).catch(() => {
    makeToast("danger", "아이템 정보를 가져오는데 실패했습니다")
  })

  return {
    items: state.items,
    fetchItems,
  }
}

const itemOptions = ref([])
const itemsWithOption = computed(() => state.items.map(item => {
  const options = itemOptions.value.filter(o => o.item_idx === item.idx)
  return {
    ...item,
    options,
  }
}))
export function useItemOptions() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchItemOptions = () => dispatch("fetchItemOptions", {
    campaign_idx: routerIdx.value,
  }).then(response => {
    itemOptions.value = response.data.data
    return new Promise(resolve => resolve())
  }).catch(() => {
    makeToast("danger", "아이템 옵션 정보를 가져오는데 실패했습니다")
  })

  return {
    itemOptions,
    fetchItemOptions,
  }
}

const rewardItems = computed(() => [
  ...rewards.value.map(r => ({ ...r, table: "reward_info" })),
  ...state.items.filter(i => i.is_reward).map(i => ({ ...i, table: "item_info" })),
])
const visibleUpdateRewardSidebar = ref(false)
const selectedRewardItem = ref({})
export function useRewardItems() {
  const fields = computed(() => {
    if (hasCampaignPermission.value) {
      return [
        "idx", "offer_item", "price", "title", "actions",
      ]
    }
    return [
      "idx", "offer_item", "price", "title",
    ]
  })

  const turnOnSidebar = item => {
    selectedRewardItem.value = JSON.parse(JSON.stringify(item))
    visibleUpdateRewardSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleUpdateRewardSidebar.value = false
  }

  const isSaving = ref(false)
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchRewards } = useRewards()
  const { fetchItems } = useItems()
  const { fetchTimelines } = useTimelines()
  const updateRewardItem = () => {
    isSaving.value = true
    dispatch("updateRewardItem", {
      campaign_idx: routerIdx.value,
      item_idx: selectedRewardItem.value.idx,
      price: selectedRewardItem.value.price,
      title: selectedRewardItem.value.title,
      table: selectedRewardItem.value.table,
    }).then(() => {
      fetchRewards()
      fetchItems()
      fetchTimelines()
      turnOffSidebar()
      makeToast("primary", "리워드 수정에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "리워드 수정에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  const disalbedUpdateButton = computed(() => (state.compt.manage_step === "STAT_ESTIMATE"))

  return {
    rewardItems,
    fields,
    selectedRewardItem,
    disalbedUpdateButton,

    visible: visibleUpdateRewardSidebar,
    isSaving,
    turnOnSidebar,
    turnOffSidebar,
    updateRewardItem,
  }
}

const questions = ref([])
export function useQuestions() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchQuestions = () => dispatch("fetchQuestions", {
    campaign_idx: routerIdx.value,
  }).then(response => {
    questions.value = response.data.data
    return new Promise(resolve => resolve())
  }).catch(() => {
    makeToast("danger", "질문 목록을 가져오는데 실패했습니다")
  })
  const fields = [
    "idx", "question", { key: "is_required", label: "필수" },
  ]

  return {
    questions,
    fields,
    fetchQuestions,
  }
}

const missions = ref([])
const missionIndexing = computed(() => getIndexedData(missions.value))
export function useMissions() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchMissions = () => dispatch("fetchMissions", {
    campaign_idx: routerIdx.value,
  }).then(response => {
    missions.value = response.data.data
  }).catch(() => {
    makeToast("danger", "미션 정보를 가져오는데 실패했습니다")
  })

  const missionType = computed(() => {
    if (state.compt.basic_mission_type === "PRODUCTION") {
      return "제작"
    }
    if (state.compt.basic_mission_type === "SHARE") {
      return "공유"
    }
    if (state.compt.basic_mission_type === "BOTH") {
      return "제작+공유"
    }
    return ""
  })

  const { resolveDate } = useResolveValue()
  const resolvePeriod = (startAt, endAt) => {
    const resolvedStartAt = resolveDate(startAt)
    const resolvedEndAt = resolveDate(endAt)
    if (resolvedStartAt == null) {
      return null
    }
    if (resolvedEndAt == null) {
      return resolvedStartAt
    }
    return `${resolvedStartAt} ~ ${resolvedEndAt}`
  }

  return {
    missions,
    fetchMissions,

    missionType,
    resolvePeriod,
  }
}

const visibleUpdateMissionSidebar = ref(false)
const targetMission = ref({})
export function useUpdateMission() {
  const { fetchNotificationMessage } = useNotification()
  const turnOnSidebar = item => {
    if (comptStep.value !== "PENDING") {
      willBeSentNotification.value = true
      const templateType = comptStep.value === "RECRUIT" ? "UPDATE_MISSION_RECRUIT_STEP" : "UPDATE_MISSION_PROGRESSING_STEP"
      fetchNotificationMessage(templateType)
    }
    targetMission.value = JSON.parse(JSON.stringify(item))
    visibleUpdateMissionSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleUpdateMissionSidebar.value = false
  }

  const inputPeriod = dateArray => {
    const [startAt, endAt] = dateArray
    targetMission.value.start_at = startAt
    targetMission.value.end_at = endAt
  }

  const inputPrePostingPeriod = dateArray => {
    const [startAt, endAt] = dateArray
    targetMission.value.pre_posting_start_at = startAt
    targetMission.value.pre_posting_end_at = endAt
  }

  const missionItems = computed(() => {
    const itemIdxList = targetMission.value.item_idx_list ?? []
    const temp = state.items
      .filter(i => itemIdxList.includes(i.idx))
      .map(i => {
        const missionItemOptions = itemOptions.value.filter(o => o.item_idx === i.idx)
        return {
          ...i,
          options: missionItemOptions,
        }
      })
    return temp
  })

  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { resolveUtcDateTime } = useResolveValue()
  const { getComptDetail } = useFetch()
  const { fetchMissions } = useMissions()
  const { fetchTimelines } = useTimelines()
  const isSaving = ref(false)
  const updateMission = () => {
    isSaving.value = true
    if (!isEndless.value) {
      dispatch("updateMission", {
        campaign_idx: routerIdx.value,
        mission_idx: targetMission.value.idx,
        start_at: resolveUtcDateTime(targetMission.value.start_at),
        end_at: resolveUtcDateTime(targetMission.value.end_at),
        pre_posting_start_at: resolveUtcDateTime(targetMission.value.pre_posting_start_at),
        pre_posting_end_at: resolveUtcDateTime(targetMission.value.pre_posting_end_at),
        end_days: targetMission.value.end_days,
        hashtag_list: targetMission.value.hashtag_list,
        description: targetMission.value.description,
        caution_text: targetMission.value.caution_text,
        will_be_sent_notification: willBeSentNotification.value,
      }).then(() => {
        getComptDetail()
        fetchMissions()
        fetchTimelines()
        turnOffSidebar()
        makeToast("primary", "미션 수정에 성공했습니다")
      }).catch(() => {
        makeToast("danger", "미션 수정에 실패했습니다")
      }).finally(() => {
        isSaving.value = false
      })
    } else {
      dispatch("updateEndlessMission", {
        campaign_idx: routerIdx.value,
        mission_idx: targetMission.value.idx,
        end_days: targetMission.value.end_days,
        hashtag_list: targetMission.value.hashtag_list,
        description: targetMission.value.description,
        caution_text: targetMission.value.caution_text,
        will_be_sent_notification: willBeSentNotification.value,
      }).then(() => {
        fetchMissions()
        fetchTimelines()
        turnOffSidebar()
        makeToast("primary", "미션 수정에 성공했습니다")
      }).catch(() => {
        makeToast("danger", "미션 수정에 성공했습니다")
      }).finally(() => {
        isSaving.value = false
      })
    }
  }

  return {
    visibleUpdateMissionSidebar,
    turnOnSidebar,
    turnOffSidebar,

    targetMission,
    inputPeriod,
    inputPrePostingPeriod,
    missionItems,
    isSaving,
    updateMission,

    isEndless,
    comptStep,
  }
}

const differentSnsLogList = ref([])
const differentSnsLogListIndexing = computed(() => getIndexedData(differentSnsLogList.value, "sns_idx"))
export function useDifferentSnsLog() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const fetchDifferentSnsLog = () => {
    dispatch("fetchDifferentSnsLog", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      differentSnsLogList.value = response.data.data
    }).catch(() => {

    })
  }
  return {
    differentSnsLogListIndexing,
    fetchDifferentSnsLog,
  }
}

const snsList = ref([])
const snsListIndexing = computed(() => getIndexedData(snsList.value))
export function useSnsList() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchSnsList = () => dispatch("fetchSnsList", {
    campaign_idx: routerIdx.value,
  }).then(response => {
    snsList.value = response.data.data
  }).catch(() => {
    makeToast("danger", "SNS 목록을 가져오는데 실패했습니다")
  })
  return {
    snsListIndexing,
    fetchSnsList,
  }
}

const snsCategories = ref([])
const categoriesIndexing = computed(() => getIndexedData(snsCategories.value, "sns_idx"))
export function useCategories() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchSnsCategories = () => {
    dispatch("fetchSnsCategories", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      snsCategories.value = response.data.data
    }).catch(() => {
      makeToast("danger", "SNS 카테고리 목록을 가져오는데 실패했습니다")
    })
  }

  return {
    categoriesIndexing,
    fetchSnsCategories,
  }
}

const claims = ref([])
const claimsIndexing = computed(() => getIndexedData(claims.value, "target_idx"))
export function useClaims() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchClaims = () => {
    dispatch("fetchClaims", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      claims.value = response.data.data
    }).catch(() => {
      makeToast("danger", "중재 정보를 가져오는데 실패했습니다")
    })
  }
  return {
    claims,
    claimsIndexing,
    fetchClaims,
  }
}

const brandPartnershipList = ref([])
const brandPartnerIndexing = computed(() => getIndexedData(brandPartnershipList.value, "creator_username"))
export function useBrandPartnershipList() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)

  const fetchBrandPartnershipList = () => {
    dispatch("fetchBrandPartnershipList", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      brandPartnershipList.value = response.data.data
    }).catch(() => {
      brandPartnershipList.value = []
    })
  }
  return {
    brandPartnershipList,
    fetchBrandPartnershipList,
  }
}

const appliesIndexing = computed(() => getIndexedData(state.applyList))
const tempFilterText = ref()
const filterText = ref()
const stateSelected = ref([])
export function useApplies() {
  const stateFilteredApplies = computed(() => {
    if (stateSelected.value.length > 0) {
      return state.applyList.filter(a => stateSelected.value.includes(a.state))
    }
    return state.applyList
  })

  const mappedApplies = computed(() => stateFilteredApplies.value.map(a => {
    const sns = snsListIndexing.value[a.sns_idx] ?? {}
    const categories = categoriesIndexing.value[a.sns_idx]?.categories ?? []
    const claim = claimsIndexing.value[a.inf_idx] ?? {}
    const partnership = brandPartnerIndexing.value[sns.username] ?? { permission_status: sns.service === "INSTA_BASIC" ? "Unable" : null }
    const differentLog = differentSnsLogListIndexing.value[sns.idx]
    return {
      ...a,
      sns,
      categories,
      claim,
      partnership,
      differentLog,
    }
  }))

  const searchApplies = () => {
    filterText.value = tempFilterText.value
  }

  return {
    mappedApplies,

    stateSelected,
    stateFilteredApplies,
    tempFilterText,
    filterText,
    searchApplies,
  }
}

const targetApply = ref({})
const applyAnswersIndexing = ref({})
const applyRewardSelectsIndexing = ref({})
const visibleApplyDetailSidebar = ref(false)
const applyQnA = computed(() => questions.value.map(q => {
  const answer = applyAnswersIndexing.value[q.idx]
  return {
    ...q,
    answer,
  }
}))
const applyItems = computed(() => state.items.map(i => {
  const options = itemOptions.value
    .filter(o => o.item_idx === i.idx)
    .map(o => {
      const rewardSelect = applyRewardSelectsIndexing.value[o.idx] ?? {}
      return {
        ...o,
        rewardSelect,
      }
    })
  return {
    ...i,
    options,
  }
}))
export function useApplyDetail() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchApplyAnswers = applyIdx => {
    dispatch("fetchApplyAnswers", {
      apply_idx: applyIdx,
    }).then(response => {
      const indexedData = getIndexedData(response.data.data, "question_idx")
      applyAnswersIndexing.value = indexedData
    }).catch(() => {
      makeToast("danger", "신청자 답변 정보를 얻어오는데 실패했습니다")
    })
  }

  const fetchApplyRewardSelects = applyIdx => {
    dispatch("fetchApplyRewardSelects", {
      apply_idx: applyIdx,
    }).then(response => {
      const indexedData = getIndexedData(response.data.data, "option_idx")
      applyRewardSelectsIndexing.value = indexedData
    }).catch(() => {
      makeToast("danger", "신청자 아이템 선택 정보를 얻어오는데 실패했습니다")
    })
  }

  const { fetchItems } = useItems()
  const { fetchItemOptions } = useItemOptions()
  const { fetchQuestions } = useQuestions()

  const turnOnSidebar = item => {
    targetApply.value = JSON.parse(JSON.stringify(item))
    if (!state.items.length) {
      fetchItems()
    }
    if (!itemOptions.value.length) {
      fetchItemOptions()
    }
    if (!questions.value.length) {
      fetchQuestions()
    }
    fetchApplyAnswers(targetApply.value.idx)
    fetchApplyRewardSelects(targetApply.value.idx)
    visibleApplyDetailSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleApplyDetailSidebar.value = false
  }

  return {
    visibleApplyDetailSidebar,
    turnOnSidebar,
    turnOffSidebar,
    applyQnA,
    applyItems,
  }
}

const guides = ref([])
const commonGuides = computed(() => guides.value
  .filter(g => g.state === "MAIN")
  .map(g => {
    const file = filesIndexing.value[g.guide_file_idx]
    const item = itemIndexing.value[g.item_idx]
    return {
      ...g,
      file,
      item,
    }
  }))
const individualGuides = computed(() => guides.value
  .filter(g => g.state !== "MAIN")
  .map(g => {
    const sns = snsListIndexing.value[g.sns_idx]
    const file = filesIndexing.value[g.guide_file_idx]
    const item = itemIndexing.value[g.item_idx]
    const mission = missionIndexing.value[g.mission_idx]
    return {
      ...g,
      sns,
      file,
      item,
      mission,
    }
  }))
export function useGuides() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchGuides = () => {
    dispatch("fetchGuides", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      guides.value = response.data.data
    }).catch(() => {
      makeToast("danger", "가이드 목록을 가져오는데 실패했습니다")
    })
  }

  const fetchGuideFiles = () => {
    dispatch("fetchGuideFiles", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      const indexedData = getIndexedData(response.data.data)
      filesIndexing.value = {
        ...filesIndexing.value,
        ...indexedData,
      }
    }).catch(() => {
      makeToast("danger", "가이드 파일을 가져오는데 실패했습니다")
    })
  }

  const commonGuideFields = computed(() => {
    const fields = [
      { key: "idx" },
      { key: "type" },
      { key: "item" },
      { key: "guide_file" },
      { key: "guide_link" },
      { key: "state" },
      { key: "actions" },
    ]
    // if (hasCampaignPermission.value) {
    //   return fields.concat([{ key: 'actions' }])
    // }
    return fields
  })

  const individualGuideFields = computed(() => {
    const fields = [
      { key: "idx" },
      { key: "type" },
      { key: "mission_idx", label: "mission" },
      { key: "sns" },
      { key: "item" },
      { key: "guide_file" },
      { key: "guide_link" },
      { key: "state" },
      { key: "actions" },
    ]
    // if (hasCampaignPermission.value) {
    //   return fields.concat([{ key: 'actions' }])
    // }
    return fields
  })

  return {
    fetchGuides,
    fetchGuideFiles,
    commonGuides,
    individualGuides,
    commonGuideFields,
    individualGuideFields,
  }
}

const deliveries = ref([])
const destinations = ref([])
const destinationsIndexing = computed(() => getIndexedData(destinations.value, "member_idx"))
const mappedDeliveries = computed(() => deliveries.value.map(d => {
  const apply = appliesIndexing.value[d.campaign_apply_idx]
  const sns = snsListIndexing.value[apply?.sns_idx]
  const destination = destinationsIndexing.value[apply?.inf_idx]
  return {
    ...d,
    sns,
    destination,
  }
}))

const deliveryFilterText = ref()
const deliveryFiledsSelected = ref("DELIVERY_INFO")
export function useDeliveries() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchDeliveries = () => {
    dispatch("fetchDeliveries", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      deliveries.value = response.data.data
    }).catch(() => [
      makeToast("danger", "배송정보를 가져오는데 실패했습니다"),
    ])
  }

  const fetchDestinations = () => {
    dispatch("fetchDestinations", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      destinations.value = response.data.data
    }).catch(() => [
      makeToast("danger", "배송지정보를 가져오는데 실패했습니다"),
    ])
  }

  const fieldsOptions = [
    { text: "배송 정보", value: "DELIVERY_INFO" },
    { text: "INF 정보", value: "INF_INFO" },
  ]
  const infoFields = computed(() => {
    if (deliveryFiledsSelected.value === "DELIVERY_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "address", label: "주소" },
        { key: "shipment_company", label: "택배사" },
        { key: "tracking_no", label: "송장번호" },
        { key: "state", label: "배송상태" },
      ]
    }
    if (deliveryFiledsSelected.value === "INF_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "inf", label: "inf" },
        { key: "inf_phone", label: "inf_phone" },
        { key: "inf_caution", label: "inf_caution" },
      ]
    }
    return []
  })
  const fields = computed(() => {
    if (hasCampaignPermission.value) {
      return infoFields.value.concat([{ key: "actions" }])
    }
    return infoFields.value
  })
  return {
    mappedDeliveries,
    fetchDeliveries,
    fetchDestinations,

    filterText: deliveryFilterText,
    fieldsOptions,
    fieldsSelected: deliveryFiledsSelected,
    fields,
  }
}

const prePostingExtraList = ref([])
const prePostingExtraIndexing = computed(() => prePostingExtraList.value.reduce((p, c) => {
  const key = `${c.mission_idx}&${c.sns_idx}`
  return {
    ...p,
    [key]: c,
  }
}, {}))
export function usePrePostingExtra() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchPrePostingExtraList = () => {
    dispatch("fetchPrePostingExtraList", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      prePostingExtraList.value = response.data.data
    }).catch(() => {
      makeToast("danger", "사전 원고 연장 정보를 가져오는데 실패했습니다")
    })
  }

  return {
    prePostingExtraList,
    prePostingExtraIndexing,
    fetchPrePostingExtraList,
  }
}

const postingExtraList = ref([])
const postingExtraIndexing = computed(() => postingExtraList.value.reduce((p, c) => {
  const key = `${c.mission_idx}&${c.sns_idx}`
  return {
    ...p,
    [key]: c,
  }
}, {}))
export function usePostingExtra() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchPostingExtraList = () => {
    dispatch("fetchPostingExtraList", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      postingExtraList.value = response.data.data
    }).catch(() => {
      makeToast("danger", "포스팅 연장 정보를 가져오는데 실패했습니다")
    })
  }

  return {
    postingExtraList,
    postingExtraIndexing,
    fetchPostingExtraList,
  }
}

const prePostings = ref([])
const mappedPrePostings = computed(() => prePostings.value.map(d => {
  const sns = snsListIndexing.value[d.sns_idx]
  const mission = missionIndexing.value[d.mission_idx]
  const extra = prePostingExtraIndexing.value[`${d.mission_idx}&${d.sns_idx}`]
  return {
    ...d,
    sns,
    mission,
    extra,
  }
}))

const prePostingFilterText = ref()
const prePostingFiledsSelected = ref("PRE_POSTING_INFO")
export function usePrePostings() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchPrePostings = () => {
    dispatch("fetchPrePostings", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      prePostings.value = response.data.data
    }).catch(() => {
      makeToast("danger", "사전 원고 목록을 가져오는데 실패했습니다")
    })
  }

  const fieldsOptions = [
    { text: "제출 정보", value: "PRE_POSTING_INFO" },
    { text: "INF 정보", value: "INF_INFO" },
    { text: "날짜 정보", value: "DATE_INFO" },
  ]
  const fields = computed(() => {
    if (prePostingFiledsSelected.value === "PRE_POSTING_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "mission_idx" },
        { key: "expired_at" },
        { key: "state" },
        { key: "actions" },
      ]
    }
    if (prePostingFiledsSelected.value === "INF_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "inf", label: "inf" },
        { key: "inf_phone", label: "inf_phone" },
        { key: "inf_caution", label: "inf_caution" },
        { key: "actions" },
      ]
    }
    if (prePostingFiledsSelected.value === "DATE_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "expired_at", label: "만료일" },
        { key: "created_at", label: "제출일시" },
        { key: "confirmed_at", label: "확인일시" },
        { key: "request_at", label: "재요청일시" },
        { key: "actions" },
      ]
    }
    return []
  })

  return {
    prePostings,
    mappedPrePostings,
    fetchPrePostings,
    filterText: prePostingFilterText,
    fieldsSelected: prePostingFiledsSelected,
    fieldsOptions,
    fields,
  }
}

const targetPrePosting = ref({})
const targetPrePostingFiles = computed(() => {
  const postingFileIdxList = targetPrePosting.value.posting_file_idx_list ?? []
  return postingFileIdxList
    .map(fileIdx => filesIndexing.value[fileIdx])
    .filter(f => f != null)
})
const targetPrePostingCommentFiles = computed(() => {
  const commentFileIdxList = targetPrePosting.value.comment_file_idx_list ?? []
  return commentFileIdxList
    .map(fileIdx => filesIndexing.value[fileIdx])
    .filter(f => f != null)
})
const visiblePrePostingDetailSidebar = ref(false)
export function usePrePostingDetail() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchPrePostingFiles = prePostingIdx => {
    dispatch("fetchPrePostingFiles", {
      pre_posting_idx: prePostingIdx,
    }).then(response => {
      const indexedData = getIndexedData(response.data.data)
      filesIndexing.value = {
        ...filesIndexing.value,
        ...indexedData,
      }
    }).catch(() => {
      makeToast("danger", "사전 원고 파일을 가져오는데 실패했습니다")
    })
  }

  const turnOnSidebar = item => {
    fetchPrePostingFiles(item.idx)
    targetPrePosting.value = JSON.parse(JSON.stringify(item))
    visiblePrePostingDetailSidebar.value = true
  }
  const turnOffSidebar = () => {
    visiblePrePostingDetailSidebar.value = false
  }

  return {
    targetPrePosting,
    targetPrePostingFiles,
    targetPrePostingCommentFiles,
    visiblePrePostingDetailSidebar,
    turnOnSidebar,
    turnOffSidebar,
  }
}

const brandedContents = ref([])
const brandedContentsIndexing = computed(() => getIndexedData(brandedContents.value, "id"))
export function useBrandedContents() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)

  const fetchBrandedContents = () => {
    dispatch("fetchBrandedContents", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      brandedContents.value = response.data.data
    }).catch(() => {
      brandedContents.value = []
    })
  }
  return {
    brandedContents,
    fetchBrandedContents,
  }
}

const postings = ref([])
const mappedPostings = computed(() => postings.value.map(p => {
  const sns = snsListIndexing.value[p.sns_idx]
  const mission = missionIndexing.value[p.mission_idx]
  const brandedContent = brandedContentsIndexing.value[p.media_id]
  const extra = postingExtraIndexing.value[`${p.mission_idx}&${p.sns_idx}`]
  return {
    ...p,
    sns,
    mission,
    brandedContent,
    extra,
  }
}))
const postingFilterText = ref()
const postingFiledsSelected = ref("POSTING_INFO")
export function usePostings() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchPostings = () => {
    dispatch("fetchPostings", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      postings.value = response.data.data
    }).catch(() => {
      makeToast("danger", "포스팅 목록을 가져오는데 실패했습니다")
    })
  }

  const fieldsOptions = [
    { text: "제출 정보", value: "POSTING_INFO" },
    { text: "날짜 정보", value: "DATE_INFO" },
    { text: "INF 정보", value: "INF_INFO" },
    { text: "BC 정보", value: "BC_INFO", disabled: false },
  ]
  const fields = computed(() => {
    if (postingFiledsSelected.value === "POSTING_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "mission_idx" },
        { key: "url" },
        { key: "state", label: "상태" },
        { key: "actions" },
      ]
    }
    if (postingFiledsSelected.value === "DATE_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "expired_at" },
        { key: "registered_at", label: "둥록일시" },
        { key: "confirmed_at", label: "확인일시" },
        { key: "state", label: "상태" },
        { key: "actions" },
      ]
    }
    if (postingFiledsSelected.value === "INF_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "inf", label: "inf" },
        { key: "inf_phone", label: "inf_phone" },
        { key: "inf_caution", label: "inf_caution" },
        { key: "actions" },
      ]
    }
    if (postingFiledsSelected.value === "BC_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "url", label: "url" },
        { key: "bc_status", label: "status" },
        { key: "actions" },
      ]
    }
    return []
  })

  return {
    postings,
    mappedPostings,
    fetchPostings,
    filterText: postingFilterText,
    fieldsSelected: postingFiledsSelected,
    fieldsOptions,
    fields,
  }
}

const pointList = ref([])
export function usePointList() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchPointList = () => {
    dispatch("fetchPointList", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      pointList.value = response.data.data
    }).catch(() => {
      makeToast("danger", "포인트 목록을 가져오는데 실패했습니다")
    })
  }

  return {
    pointList,
    fetchPointList,
  }
}

const snsByinfIdx = computed(() => getIndexedData(snsList.value, "inf_idx"))
const pointIndexing = computed(() => getIndexedData(pointList.value, "idx"))
const mappedInvoices = computed(() => state.invoiceList.map(p => {
  const sns = snsByinfIdx.value[p.target_inf_idx]
  const point = pointIndexing.value[p.point_idx]
  return {
    ...p,
    sns,
    point,
  }
}))
const invoiceFilterText = ref()
const invoiceFiledsSelected = ref("INVOICE_INFO")
export function useInvoices() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const {
    getInvoiceList,
  } = useFetch()

  const fieldsOptions = [
    { text: "정산 정보", value: "INVOICE_INFO" },
    { text: "INF 정보", value: "INF_INFO" },
    { text: "포인트 지급 정보", value: "POINT_INFO" },
  ]
  const fields = computed(() => {
    if (invoiceFiledsSelected.value === "INVOICE_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "ssn_exists", label: "주민번호 인증" },
        { key: "created_at", label: "포스팅 확인 완료일" },
        { key: "amount", label: "결제 금액" },
        { key: "expected_pay_date", label: "정산 기준일" },
        // { key: 'point_addition_expected_date', label: '지급일' },
        { key: "state", label: "상태" },
        { key: "actions" },
      ]
    }
    if (invoiceFiledsSelected.value === "INF_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "inf", label: "inf" },
        { key: "inf_phone", label: "inf_phone" },
        { key: "inf_caution", label: "inf_caution" },
        { key: "actions" },
      ]
    }
    if (invoiceFiledsSelected.value === "POINT_INFO") {
      return [
        { key: "idx" },
        { key: "username" },
        { key: "point_addition_expected_date", label: "지급일" },
        { key: "point_apply_point", label: "포인트" },
        { key: "point_tax", label: "tax" },
        { key: "point_state", label: "지급 상태" },
        { key: "actions" },
      ]
    }
    return []
  })

  const invoices = computed(() => state.invoiceList)

  return {
    invoices,
    mappedInvoices,
    fetchInvoices: getInvoiceList,
    filterText: invoiceFilterText,
    fieldsSelected: invoiceFiledsSelected,
    fieldsOptions,
    fields,
  }
}

const chatRooms = ref([])
const chatFilterText = ref()
const mappedChatRooms = computed(() => chatRooms.value.map(r => {
  const infIdx = r.inf?.member_idx
  const sns = snsByinfIdx.value[infIdx] ?? {}
  const username = sns.service === "NAVER_BLOG" ? sns.uid : sns.username
  return {
    idx: r.idx,
    state: r.state,
    profileImgUrl: sns.profile_img_url,
    username,
    infName: sns.inf?.name,
    inf: r.inf,
    adv: r.adv,
  }
}))
const filteredChatRooms = computed(() => {
  if (!chatFilterText.value) {
    return mappedChatRooms.value
  }
  return mappedChatRooms.value.filter(r => (
    r.username.includes(chatFilterText.value)
    || r.infName.includes(chatFilterText.value)
  ))
})
const targetRoom = ref()
const targetRoomAdv = computed(() => targetRoom.value?.adv)
const targetRoomInf = computed(() => targetRoom.value?.inf)
const chats = ref([])
const mappedChats = computed(() => chats.value.map(c => {
  if (c.content_type !== "FILE") {
    return c
  }
  const fileIdxList = c.extra
  const chatFiles = fileIdxList.map(fileIdx => filesIndexing.value[fileIdx])
  return {
    ...c,
    files: chatFiles,
  }
}))
export function useChats() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchChatRooms = () => {
    dispatch("fetchChatRooms", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      chatRooms.value = response.data.data
    }).catch(() => {
      makeToast("danger", "채팅 목록을 가져오는데 실패했습니다")
    })
  }

  const fetchChats = roomIdx => {
    dispatch("fetchChats", {
      room_idx: roomIdx,
    }).then(response => {
      chats.value = response.data.data
    }).catch(() => {
      makeToast("danger", "채팅 내용을 가져오는데 실패했습니다")
    })
  }

  const fetchChatFiles = roomIdx => {
    dispatch("fetchChatFiles", {
      room_idx: roomIdx,
    }).then(response => {
      const indexedData = getIndexedData(response.data.data)
      filesIndexing.value = {
        ...filesIndexing.value,
        ...indexedData,
      }
    }).catch(() => {
      makeToast("danger", "채팅 파일을 가져오는데 실패했습니다")
    })
  }

  const clickChatRoom = item => {
    chats.value = []
    fetchChats(item.idx)
    fetchChatFiles(item.idx)
    targetRoom.value = item
  }

  return {
    chatRooms,
    mappedChats,
    chatFilterText,
    filteredChatRooms,
    targetRoom,
    targetRoomAdv,
    targetRoomInf,
    fetchChatRooms,
    clickChatRoom,
    fetchChatFiles,
  }
}

const visibleUpdateComptModal = ref(false)
export function useUpdateCompt() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const turnOnSidebar = () => {
    visibleUpdateComptModal.value = true
  }
  const turnOffSidebar = () => {
    visibleUpdateComptModal.value = false
  }

  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()

  const updateCompt = () => {
    dispatch("updateCompt", {
      campaign_idx: routerIdx.value,
      title: state.compt.title,
      state: state.compt.state,
      gender: state.compt.gender,
      age_group: state.compt.age_group,
      brand_name: state.compt.brand_name,
      selection_at: state.compt.selection_at,
      has_guide: state.compt.has_guide,
      guide_pending_at: state.compt.guide_pending_at,
      has_sponsor_banner: state.compt.has_sponsor_banner,
      utilization_channel_list: state.compt.utilization_channel_list,
      utilization_plan_period: state.compt.utilization_plan_period,
      detail_link: state.compt.detail_link,
      detail_text: state.compt.detail_text,
    }).then(() => {
      getComptDetail()
      fetchTimelines()
      makeToast("primary", "캠페인 수정에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "캠페인 수정에 실패했습니다")
    }).finally(() => {
      turnOffSidebar()
    })
  }

  return {
    visibleUpdateComptModal,
    turnOnSidebar,
    turnOffSidebar,
    updateCompt,
  }
}

const visibleMemo = ref(false)
const isSavingMemo = ref(false)
const memoContent = ref()
export function useMemo() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchTimelines } = useTimelines()

  const turnOnMemo = () => {
    visibleMemo.value = true
  }
  const turnOffMemo = () => {
    visibleMemo.value = false
  }
  const inputMemoContent = value => {
    memoContent.value = value
  }
  const createMemo = () => {
    isSavingMemo.value = true
    dispatch("createMemo", {
      idx: routerIdx.value,
      content: memoContent.value,
    }).then(() => {
      makeToast("primary", "메모를 생성하는데 성공했습니다")
      turnOffMemo()
    }).catch(() => {
      makeToast("danger", "메모를 생성하는데 실패했습니다")
    }).finally(() => {
      isSavingMemo.value = false
      fetchTimelines()
    })
  }

  return {
    visibleMemo,
    isSavingMemo,
    memoContent,
    turnOnMemo,
    turnOffMemo,
    inputMemoContent,
    createMemo,
  }
}

const visibleAddApplySidebar = ref(false)
const answersForAddApply = ref([])
const selectedItemsForAddApply = ref([])

const textForSearchSns = ref()
const searchedSnsList = ref()
const isEmptySearchedSns = computed(() => searchedSnsList.value?.length === 0)
const targetSns = ref()
const hasSelectedSns = computed(() => Boolean(targetSns.value))
const targetSnsService = computed(() => targetSns.value?.service)
const targetUsername = computed(() => targetSns.value?.username)

const hasDeliveryItem = computed(() => state.items.reduce((p, c) => p || c.is_delivery, false))
const isLoadingDestinations = ref(false)
const searchedDestinations = ref()
const isEmptySearchedDestinations = computed(() => searchedDestinations.value?.length === 0)
const targetDestinationIdx = ref()
const validationTargetDestination = computed(() => (targetDestinationIdx.value == null ? false : null))

const isSavingAddApply = ref(false)
const isValidAddApply = computed(() => (
  hasSelectedSns.value
  && answersForAddApply.value.reduce((p, c) => p && (!c.question.is_required || Boolean(c.answer)), true)
  && selectedItemsForAddApply.value
    .reduce((p, c) => p.concat(c.options), [])
    .reduce((p, c) => p && Boolean(c.selectValue), true)
  && (!hasDeliveryItem.value || validationTargetDestination.value == null)
))

export function useAddApply() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchQuestions } = useQuestions()
  const { fetchItems } = useItems()
  const { fetchItemOptions } = useItemOptions()
  const { fetchTimelines } = useTimelines()
  const { getApplyList } = useFetch()
  const { fetchSnsList } = useSnsList()

  const turnOnSidebar = () => {
    Promise.all([
      fetchQuestions(),
      fetchItems(),
      fetchItemOptions(),
    ]).then(() => {
      answersForAddApply.value = questions.value.map(question => ({
        answer: null,
        question,
      }))
      selectedItemsForAddApply.value = itemsWithOption.value.map(item => {
        const options = item.options.map(option => ({
          ...option,
          selectValue: null,
        }))
        return {
          ...item,
          options,
        }
      })
    })
    textForSearchSns.value = null
    targetSns.value = null
    searchedDestinations.value = null
    targetDestinationIdx.value = null
    visibleAddApplySidebar.value = true
  }

  const turnOffSidebar = () => {
    visibleAddApplySidebar.value = false
  }

  const searchSnsList = () => {
    dispatch("searchSnsList", {
      text: textForSearchSns.value,
    }).then(response => {
      searchedSnsList.value = response.data.data
    }).catch(() => {
      makeToast("danger", "SNS 목록을 찾는데 실패했습니다")
    })
  }

  const searchDestinations = infIdx => {
    isLoadingDestinations.value = true
    dispatch("searchDestinations", {
      inf_idx: infIdx,
    }).then(response => {
      searchedDestinations.value = response.data.data
    }).catch(() => {
      makeToast("danger", "SNS 목록을 찾는데 실패했습니다")
    }).finally(() => {
      isLoadingDestinations.value = false
    })
  }

  const selectSns = ({ item }) => {
    targetSns.value = item
    textForSearchSns.value = targetUsername.value
    searchedDestinations.value = null
    searchDestinations(item.inf_idx)
  }

  const addApply = () => {
    isSavingAddApply.value = true
    dispatch("addApply", {
      campaign_idx: routerIdx.value,
      sns_idx: targetSns.value.idx,
      inf_idx: targetSns.value.inf_idx,
      username: targetSns.value.username,
      delivery_destination_idx: targetDestinationIdx.value,
      answers: answersForAddApply.value.map(a => ({
        question_idx: a.question.idx,
        answer: a.answer,
      })),
      select_values: selectedItemsForAddApply.value
        .filter(i => i.options.length > 0)
        .reduce((p, c) => p.concat(c.options), [])
        .map(o => ({
          item_idx: o.item_idx,
          option_idx: o.idx,
          select_value: o.selectValue,
        })),
    }).then(() => {
      fetchTimelines()
      getApplyList()
      fetchSnsList()
      turnOffSidebar()
      makeToast("primary", "신청자를 추가하는데 성공했습니다")
    }).catch(() => {
      makeToast("danger", "신청자를 추가하는데 실패했습니다")
    }).finally(() => {
      isSavingAddApply.value = false
    })
  }

  const invalidFeedback = computed(() => {
    if (isEmptySearchedSns.value) {
      return "SNS 검색 결과가 없습니다"
    }
    if (!hasSelectedSns.value) {
      return "필수 항목입니다"
    }
    return ""
  })

  const inpuText = value => {
    searchedSnsList.value = null
    textForSearchSns.value = value
  }

  return {
    visibleAddApplySidebar,
    answersForAddApply,
    selectedItemsForAddApply,
    textForSearchSns,
    searchedSnsList,
    isEmptySearchedSns,
    targetSns,
    hasSelectedSns,
    isLoadingDestinations,
    targetSnsService,
    targetUsername,
    isEmptySearchedDestinations,
    isSavingAddApply,
    isValidAddApply,
    invalidFeedback,
    searchedDestinations,
    targetDestinationIdx,
    hasDeliveryItem,

    turnOnSidebar,
    turnOffSidebar,

    searchSnsList,
    searchDestinations,
    selectSns,
    addApply,
    inpuText,
  }
}

const visiblePendingModal = ref(false)
export function usePending() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()
  const { fetchNotificationMessage } = useNotification()

  const isValid = computed(() => !["PROGRESSED", "PENDING"].includes(state.compt.step))

  const turnOnModal = () => {
    fetchNotificationMessage("PENDING")
    willBeSentNotification.value = true
    visiblePendingModal.value = true
  }
  const turnOffModal = () => {
    visiblePendingModal.value = false
  }

  const pending = () => {
    dispatch("pending", {
      campaign_idx: routerIdx.value,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      fetchTimelines()
      getComptDetail()
      makeToast("primary", "캠페인 보류에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "캠페인 보류에 실패했습니다")
    })
  }

  return {
    visiblePendingModal,
    isValid,
    turnOnModal,
    turnOffModal,
    pending,
  }
}

const visibleReleasePendingModal = ref(false)
export function useReleasePending() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()
  const { fetchNotificationMessage } = useNotification()

  const isValid = computed(() => state.compt.step === "PENDING")

  const turnOnModal = () => {
    if (!isEndless.value) {
      const templateType = isRecruitStep.value ? "RELEASE_PENDING_RECRUIT_STEP" : "RELEASE_PENDING_PROGRESSING_STEP"
      fetchNotificationMessage(templateType)
      willBeSentNotification.value = true
    }
    visibleReleasePendingModal.value = true
  }
  const turnOffModal = () => {
    visibleReleasePendingModal.value = false
  }

  const releasePending = () => {
    if (!isEndless.value) {
      dispatch("releasePending", {
        campaign_idx: routerIdx.value,
        will_be_sent_notification: willBeSentNotification.value,
      }).then(() => {
        fetchTimelines()
        getComptDetail()
        makeToast("primary", "보류 해제에 성공했습니다")
      }).catch(error => {
        const errorMessage = error.response.data.message
        if (errorMessage === "The start date of the mission must be later than today") {
          makeToast("danger", "보류해제 실패", "미션의 시작일이 오늘보다 늦어야 합니다")
        } else if (errorMessage === "Guide assignment expiry date must be later than today") {
          makeToast("danger", "보류해제 실패", "가이드 배정 만료일이 오늘보다 늦어야 합니다")
        } else {
          makeToast("danger", "보류 해제에 실패했습니다")
        }
      })
    } else {
      dispatch("releasePendingEndless", {
        campaign_idx: routerIdx.value,
      }).then(() => {
        fetchTimelines()
        getComptDetail()
        makeToast("primary", "보류 해제에 성공했습니다")
      }).catch(() => {
        makeToast("danger", "보류 해제에 실패했습니다")
      })
    }
  }

  return {
    visibleReleasePendingModal,
    isValid,
    isEndless,
    isRecruitStep,
    turnOnModal,
    turnOffModal,
    releasePending,
  }
}

const visiblePullUpModal = ref(false)
export function usePullUp() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()

  const turnOnModal = () => {
    visiblePullUpModal.value = true
  }
  const turnOffModal = () => {
    visiblePullUpModal.value = false
  }

  const pullUp = () => {
    dispatch("pullUp", {
      campaign_idx: routerIdx.value,
    }).then(() => {
      fetchTimelines()
      getComptDetail()
      makeToast("primary", "캠페인 끌어올리기에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "캠페인 끌어올리기에 실패했습니다")
    })
  }

  return {
    visiblePullUpModal,
    turnOnModal,
    turnOffModal,
    pullUp,
  }
}

const visibleSetProgressedModal = ref(false)
export function useSetProgressed() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchPrePostings } = usePrePostings()
  const { fetchPostings } = usePostings()
  const { fetchInvoices } = useInvoices()
  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()
  const { fetchRewards } = useRewards()

  const { fetchNotificationMessage } = useNotification()

  const isValid = computed(() => !["PROGRESSED", "PENDING"].includes(state.compt.step))
  const hasCashReward = computed(() => (rewards.value.find(r => r.price > 0) != null))

  const turnOnModal = () => {
    fetchRewards().then(() => {
      const templateType = hasCashReward.value ? "SKIP_WITH_CASH" : "SKIP_NO_CASH"
      fetchNotificationMessage(templateType)
      willBeSentNotification.value = true
    })
    visibleSetProgressedModal.value = true
  }

  const turnOffModal = () => {
    visibleSetProgressedModal.value = false
  }

  const setProgressed = () => {
    dispatch("setProgressed", {
      campaign_idx: routerIdx.value,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      fetchTimelines()
      fetchPrePostings()
      fetchPostings()
      fetchInvoices()
      getComptDetail()
      makeToast("primary", "캠페인 진행완료에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "캠페인 진행완료에 실패했습니다")
    })
  }

  return {
    visibleSetProgressedModal,
    isValid,
    turnOnModal,
    turnOffModal,
    setProgressed,
  }
}

const visibleDownloadPrePostingImgModal = ref(false)
const countOfDownloadedPrePosting = ref(0)
const totalCountOfDownloadingPrePosting = ref(0)
const messageOfDownloadingPrePostingImageZip = ref()
const downloadingStepOfPrePostingImageZip = ref(0)
export function useDownloadPrepostingImg() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchAllPrePostingFiles = () => dispatch("fetchAllPrePostingFiles", {
    campaign_idx: routerIdx.value,
  }).then(response => new Promise(resolve => resolve(response.data.data)))

  const downloadPrePostingImgZip = prePostingWithFiles => {
    if (prePostingWithFiles.length === 0) {
      makeToast("danger", "원고 파일 일괄 다운로드 실패", "다운로드 가능한 원고가 없습니다.")
      return null
    }

    visibleDownloadPrePostingImgModal.value = true
    countOfDownloadedPrePosting.value = 0
    messageOfDownloadingPrePostingImageZip.value = "파일 가져오는 중..."
    downloadingStepOfPrePostingImageZip.value = 0

    const zip = new JSZip()
    const totalFiles = prePostingWithFiles
      .reduce((p, c) => {
        const taggedFiles = c.files.map(file => ({
          username: c.username,
          mission_idx: c.mission_idx,
          ...file,
        }))
        console.log(c)
        const textBlob = new Blob([c.text], { type: "text/plain" })
        const textFileUrl = window.URL.createObjectURL(textBlob)
        const textFile = {
          url: textFileUrl,
          username: c.username,
          file_name: "comment.txt",
          mission_idx: c.mission_idx,
        }
        return [
          ...p,
          ...taggedFiles,
          textFile,
        ]
        // return p.concat(taggedFiles)
      }, [])
    // .filter(file => Boolean(/^(image\/)|(video\/).*/.exec(file.file_type)))

    totalCountOfDownloadingPrePosting.value = totalFiles.length
    const downloadRequests = totalFiles.map(
      file => downloadFile(
        file.url, file.username, "arraybuffer", false,
      ).then(data => {
        const createdAt = moment(file.created_at).format("YYYYMMDDHHmmss")
        const path = `${file.mission_idx}/${file.username}/${createdAt}_${file.file_name}`
        zip.file(path, data)
        countOfDownloadedPrePosting.value += 1
        return new Promise(resolve => resolve())
      }),
    )

    Promise.all(downloadRequests)
      .then(() => {
        downloadingStepOfPrePostingImageZip.value = 1
        messageOfDownloadingPrePostingImageZip.value = "압축파일 생성중..."
        zip.generateAsync({ type: "blob" }).then(content => {
          const now = moment().format("YYYYMMDDHHmmss")
          downloadFile(content, `pre_posting_images_${now}.zip`)
        })
      })
      .catch(() => {
        makeToast(
          "danger",
          "원고 파일 일괄 다운로드 실패",
          "파일 다운로드 중 실패가 발생했습니다. 개발팀에 문의해주십시오.",
        )
      })
      .finally(() => {
        messageOfDownloadingPrePostingImageZip.value = "다운로드 완료"
        downloadingStepOfPrePostingImageZip.value = 2
      })
    return null
  }

  const turnOnModal = () => {
    visibleDownloadPrePostingImgModal.value = true
    fetchAllPrePostingFiles().then(data => downloadPrePostingImgZip(data))
  }
  const turnOffModal = () => {
    visibleDownloadPrePostingImgModal.value = false
  }

  return {
    visibleDownloadPrePostingImgModal,
    countOfDownloadedPrePosting,
    totalCountOfDownloadingPrePosting,
    messageOfDownloadingPrePostingImageZip,
    downloadingStepOfPrePostingImageZip,
    turnOnModal,
    turnOffModal,
    fetchAllPrePostingFiles,
    downloadPrePostingImgZip,
  }
}

const screenshotJobList = ref([])
export function useScreenshotJob() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fields = [
    "idx", "keywords",
  ]

  const fetchScreenshotJobList = () => {
    dispatch("fetchScreenshotJobList", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      screenshotJobList.value = response.data.data
    }).catch(() => {
      makeToast("danger", "스크린샷 잡 목록을 불러오는데 실패했습니다")
    })
  }

  return {
    fields,
    screenshotJobList,
    fetchScreenshotJobList,
  }
}

const visibleAddScreenshotJobSidebar = ref(false)
const screenshotKeyword = ref()
const screenshotInterval = ref()
const screenshotStartAt = ref()
const screenshotEndAt = ref()
const maxScreenshotRanking = ref(10)
const maxRanking = ref(50)
const isSavingScreenshotJob = ref(false)
const isValidScreenshotJob = computed(
  () => screenshotKeyword.value != null
    && screenshotInterval.value != null
    && maxScreenshotRanking.value != null
    && maxRanking.value != null,
)
export function useAddScreenshotJob() {
  const { fetchPostings } = usePostings()
  const turnOnSidebar = () => {
    fetchPostings()
    screenshotKeyword.value = null
    screenshotInterval.value = null
    screenshotStartAt.value = null
    screenshotEndAt.value = null
    maxScreenshotRanking.value = 10
    maxRanking.value = 50
    visibleAddScreenshotJobSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleAddScreenshotJobSidebar.value = false
  }
  const { fetchTimelines } = useTimelines()
  const postingCount = computed(() => {
    const comfirmedPostngs = postings.value.filter(p => p.state === "CONFIRMED")
    return comfirmedPostngs.length
  })

  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { resolveUtcDateTime } = useResolveValue()
  const { fetchScreenshotJobList } = useScreenshotJob()
  const addScreenshotJob = () => {
    const params = {
      campaign_idx: routerIdx.value,
      keywords: screenshotKeyword.value,
      interval: screenshotInterval.value,
      start_at: resolveUtcDateTime(screenshotStartAt.value),
      end_at: resolveUtcDateTime(screenshotEndAt.value),
      posting_count: postingCount.value,
      max_screenshot_ranking: maxScreenshotRanking.value,
      max_ranking: maxRanking.value,
    }

    isSavingScreenshotJob.value = true
    dispatch("addScreenshotJob", params)
      .then(() => {
        fetchTimelines()
        fetchScreenshotJobList()
        turnOffSidebar()
        makeToast("primary", "스크린샷 추가에 성공했습니다")
      })
      .catch(() => {
        makeToast("danger", "스크린샷 추가에 실패했습니다")
      })
      .finally(() => {
        isSavingScreenshotJob.value = false
      })
  }
  return {
    visibleAddScreenshotJobSidebar,
    turnOnSidebar,
    turnOffSidebar,
    screenshotKeyword,
    screenshotInterval,
    screenshotStartAt,
    screenshotEndAt,
    postingCount,
    maxScreenshotRanking,
    maxRanking,
    addScreenshotJob,
    isSavingScreenshotJob,
    isValidScreenshotJob,
  }
}

const visibleGetLikeModal = ref(false)
const isProgressingGetLikeComment = ref(false)
export function useGetLikeComment() {
  const { getComptDetail } = useFetch()
  const validPostingUrl = computed(() => postings.value.filter(p => Boolean(p.url)).map(p => p.url))
  const downloadBlogLikeComment = () => {
    function downloadCsv(data) {
      const title = "url,like,comment\n"
      const mappedData = data.map(row => `${row.url},${row.like},${row.comment}`)
      const joinedData = mappedData.join("\n")
      const csvData = title.concat(joinedData)
      const blob = new Blob([csvData], { type: "text/csv" })
      const linkEl = document.createElement("a")
      linkEl.href = window.URL.createObjectURL(blob)
      linkEl.download = "like_comment.csv"
      linkEl.click()
    }
    const SNS_MODULE_URL = process.env.VUE_APP_SNS_MODULE_URL
    function getToken() {
      const TAGBY_SNS_USERNAME = process.env.VUE_APP_TAGBY_SNS_USERNAME
      const TAGBY_SNS_PASSWORD = process.env.VUE_APP_TAGBY_SNS_PASSWORD
      const USER_DATA = {
        username: TAGBY_SNS_USERNAME,
        password: TAGBY_SNS_PASSWORD,
      }
      return fetch(`${SNS_MODULE_URL}/login`, {
        method: "post",
        body: new URLSearchParams(USER_DATA),
      }).then(response => response.json())
    }
    function getBlogLikeComment(urls) {
      return getToken().then(tokenResJson => new Promise(resolve => {
        const { token } = tokenResJson
        const headers = {
          Authorization: `Bearer ${token}`,
        }
        resolve(headers)
      }).then(headers => Promise.all(urls.map(url => fetch(`${SNS_MODULE_URL}/api/v3/naver/get_post_info`, {
        method: "post",
        headers,
        body: JSON.stringify({ url }),
      }).then(response => response.json())
        .then(resJson => ({
          url,
          like: resJson.data?.like_count ?? "Error",
          comment: resJson.data?.comment_count ?? "Error",
        }))
        .catch(() => ({
          url,
          like: "Error",
          comment: "Error",
        }))))))
    }
    isProgressingGetLikeComment.value = true
    getBlogLikeComment(validPostingUrl.value)
      .then(data => {
        downloadCsv(data)
      }).finally(() => {
        isProgressingGetLikeComment.value = false
      })
  }
  const turnOnModal = () => {
    visibleGetLikeModal.value = true
  }
  const clickButton = () => {
    getComptDetail().then(() => {
      if (getters.comptSnsChannel === "BLOG") {
        downloadBlogLikeComment()
      } else if (getters.comptSnsChannel === "INSTAGRAM") {
        turnOnModal()
      }
    })
  }
  const turnOffModal = () => {
    visibleGetLikeModal.value = false
  }
  const appendedCode = computed(() => {
    const re = new RegExp(/(?<=instagram.com\/p\/)\w+(?=\/?)/)
    const targetShortcodeList = validPostingUrl.value.map(url => re.exec(url)?.[0])
    return `t(${JSON.stringify(targetShortcodeList)});`
  })

  const copiedCode = computed(() => `${code}${appendedCode.value}`)

  const { makeToast } = useToast()
  const clickCopyCode = () => {
    window.navigator.clipboard.writeText(copiedCode.value)
    makeToast("primary", "코드가 복사됐습니다")
  }

  return {
    isProgressingGetLikeComment,
    visibleGetLikeModal,
    clickButton,
    turnOffModal,
    appendedCode,
    copiedCode,
    clickCopyCode,
  }
}

export function useAdvertiser() {
  const advertiser = computed(() => state.adv)
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchAdvertiser = () => {
    dispatch("fetchAdvertiser", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      state.adv = response.data.data
    }).catch(() => {
      makeToast("danger", "광고주 정보를 가져오는데 실패했습니다")
    })
  }
  return {
    advertiser,
    fetchAdvertiser,
  }
}

const visibleTempSelectApplyModal = ref(false)
export function useTempSelectApply() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getApplyList } = useFetch()
  const { fetchTimelines } = useTimelines()

  const getIsValid = item => ["APPLY"].includes(item.state)

  const turnOnModal = item => {
    targetApply.value = item
    visibleTempSelectApplyModal.value = true
  }

  const turnOffModal = () => {
    visibleTempSelectApplyModal.value = false
  }

  const tempSelectApply = () => {
    dispatch("tempSelectApply", {
      apply_idx: targetApply.value.idx,
    }).then(() => {
      fetchTimelines()
      getApplyList()
      makeToast("primary", "신청자 임시선정에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "신청자 임시선정에 실패했습니다")
    })
  }

  return {
    visible: visibleTempSelectApplyModal,
    getIsValid,
    turnOnModal,
    turnOffModal,
    tempSelectApply,
  }
}

const visibleSelectApplyModal = ref(false)
export function useSelectApply() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getApplyList } = useFetch()
  const { fetchTimelines } = useTimelines()

  const { fetchNotificationMessage } = useNotification()

  const getIsValid = item => ["APPLY", "TEMP"].includes(item.state)

  const turnOnModal = item => {
    targetApply.value = item
    const templateType = "SELECT"
    fetchNotificationMessage(templateType)
    willBeSentNotification.value = true
    visibleSelectApplyModal.value = true
  }

  const turnOffModal = () => {
    visibleSelectApplyModal.value = false
  }

  const selectApply = () => {
    dispatch("selectApply", {
      campaign_idx: routerIdx.value,
      apply_idx: targetApply.value.idx,
      allow_notification: willBeSentNotification.value,
    }).then(() => {
      fetchTimelines()
      getApplyList()
      makeToast("primary", "신청자 선정에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "신청자 선정에 실패했습니다")
    })
  }

  return {
    visible: visibleSelectApplyModal,
    getIsValid,
    turnOnModal,
    turnOffModal,
    selectApply,
  }
}

const visibleInactivateApplyModal = ref(false)
export function useInactivateApply() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getApplyList } = useFetch()
  const { fetchTimelines } = useTimelines()

  const turnOnModal = item => {
    targetApply.value = item
    visibleInactivateApplyModal.value = true
  }

  const turnOffModal = () => {
    visibleInactivateApplyModal.value = false
  }

  const inactivateApply = () => {
    dispatch("inactivateApply", {
      apply_idx: targetApply.value.idx,
    }).then(() => {
      fetchTimelines()
      getApplyList()
      makeToast("primary", "신청자 삭제에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "신청자 삭제에 실패했습니다")
    })
  }

  return {
    visible: visibleInactivateApplyModal,
    turnOnModal,
    turnOffModal,
    inactivateApply,
  }
}

const visibleQuitApplyModal = ref(false)
const hasPAUG = ref(false)
export function useQuitApply() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getApplyList } = useFetch()
  const { fetchDeliveries } = useDeliveries()
  const { fetchInvoices } = useInvoices()
  const { fetchTimelines } = useTimelines()

  const getIsValid = item => ["SELECTED"].includes(item.state)

  const turnOnModal = item => {
    dispatch("fetchHasPAUG", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      hasPAUG.value = response.data.data.has_paug
    }).catch(() => {
      makeToast("danger", "건별결제 내역을 확인하는데 실패했습니다")
    })
    targetApply.value = item
    visibleQuitApplyModal.value = true
  }

  const turnOffModal = () => {
    visibleQuitApplyModal.value = false
  }

  const quitApply = () => {
    dispatch("quitApply", {
      campaign_idx: routerIdx.value,
      apply_idx: targetApply.value.idx,
    }).then(() => {
      fetchTimelines()
      getApplyList()
      fetchDeliveries()
      fetchInvoices()
      makeToast("primary", "신청자 이탈처리에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "신청자 이탈처리에 실패했습니다")
    })
  }

  return {
    visible: visibleQuitApplyModal,
    getIsValid,
    turnOnModal,
    turnOffModal,
    quitApply,
    hasPAUG,
  }
}

const visibleUpdateGuideSidebar = ref(false)
const targetGuide = ref({})
const targetGuideFile = ref({})
const isUploadingGuideFile = ref(false)
const isSavingUpdateGuide = ref(false)
export function useUpdateGuide() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchGuides, fetchGuideFiles } = useGuides()
  const { fetchTimelines } = useTimelines()

  const { fetchNotificationMessage } = useNotification()

  const turnOnSidebar = item => {
    targetGuide.value = JSON.parse(JSON.stringify(item))
    targetGuideFile.value = filesIndexing.value[item.guide_file_idx]
    const templateType = "UPDATE_GUIDE"
    fetchNotificationMessage(templateType)
    willBeSentNotification.value = true
    visibleUpdateGuideSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleUpdateGuideSidebar.value = false
  }

  const uploadGuideUpdate = file => {
    isUploadingGuideFile.value = true
    uploadFile(file, "campaign_guide_info", "").then(response => {
      targetGuideFile.value = response
    }).catch(() => {
      makeToast("danger", `${file.name} 파일 업로드에 실패했습니다`)
    }).finally(() => {
      isUploadingGuideFile.value = false
    })
  }

  const updateGuide = () => {
    isSavingUpdateGuide.value = true
    dispatch("updateGuide", {
      campaign_idx: routerIdx.value,
      guide_idx: targetGuide.value.idx,
      guide_file_idx: targetGuideFile.value?.idx,
      guide_link: targetGuide.value.guide_link,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      fetchGuides()
      fetchTimelines()
      fetchGuideFiles()
      turnOffSidebar()
      makeToast("primary", "가이드 수정에 성공했습니다")
    }).finally(() => {
      isSavingUpdateGuide.value = false
    })
  }

  const isNotAssigned = computed(() => state.compt.manage_step === "GUIDE")

  return {
    visibleUpdateGuideSidebar,
    targetGuide,
    targetGuideFile,
    isUploadingGuideFile,
    isSavingUpdateGuide,
    turnOnSidebar,
    turnOffSidebar,
    uploadGuideUpdate,
    updateGuide,
    isNotAssigned,
  }
}

const visibleResetDeliveryModal = ref(false)
const targetDelivery = ref({})
export function useResetDelivery() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchDeliveries } = useDeliveries()
  const { fetchSnsList } = useSnsList()
  const { fetchTimelines } = useTimelines()

  const turnOnModal = item => {
    targetDelivery.value = item
    visibleResetDeliveryModal.value = true
  }

  const turnOffModal = () => {
    visibleResetDeliveryModal.value = false
  }

  const resetDelivery = () => {
    dispatch("resetDelivery", {
      delivery_idx: targetDelivery.value.idx,
    }).then(() => {
      fetchTimelines()
      fetchDeliveries()
      fetchSnsList()
      makeToast("primary", "송장정보 초기화에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "송장정보 초기화에 실패했습니다")
    })
  }

  return {
    visibleResetDeliveryModal,
    turnOnModal,
    turnOffModal,
    resetDelivery,
  }
}

const visibleUpdateDeliverySidebar = ref(false)
const isSavingUpdateDelivery = ref(false)
const shipmentCompanyList = ref([])
export function useUpdateDelivery() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchDeliveries } = useDeliveries()
  const { fetchSnsList } = useSnsList()
  const { fetchTimelines } = useTimelines()

  const fetchShipmentCompanyList = () => {
    dispatch("fetchShipmentCompanyList").then(response => {
      shipmentCompanyList.value = response.data.data.map(s => ({
        label: s.name,
        value: s.id,
      }))
    }).catch(() => {
      makeToast("danger", "배송기업 목록을 불러오는데 실패했습니다")
    })
  }

  const turnOnSidebar = item => {
    fetchShipmentCompanyList()
    targetDelivery.value = JSON.parse(JSON.stringify(item))
    visibleUpdateDeliverySidebar.value = true
  }

  const turnOffSidebar = () => {
    visibleUpdateDeliverySidebar.value = false
  }

  const updateDelivery = () => {
    isSavingUpdateDelivery.value = true
    dispatch("updateDelivery", {
      delivery_idx: targetDelivery.value.idx,
      shipment_company: targetDelivery.value.shipment_company,
      tracking_no: targetDelivery.value.tracking_no,
    }).then(() => {
      fetchTimelines()
      fetchDeliveries()
      fetchSnsList()
      turnOffSidebar()
      makeToast("primary", "송장정보 수정에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "송장정보 수정에 실패했습니다")
    }).finally(() => {
      isSavingUpdateDelivery.value = false
    })
  }

  return {
    targetDelivery,
    shipmentCompanyList,
    visibleUpdateDeliverySidebar,
    isSavingUpdateDelivery,
    turnOnSidebar,
    turnOffSidebar,
    updateDelivery,
  }
}

const visibleSkipPrePostingModal = ref(false)
export function useSkipPrePosting() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()
  const { fetchPrePostings } = usePrePostings()
  const { fetchTimelines } = useTimelines()
  const { fetchInvoices } = useInvoices()

  const { fetchNotificationMessage } = useNotification()

  const getIsValid = item => !["SKIP", "CONFIRMED"].includes(item.state)

  const turnOnModal = item => {
    targetPrePosting.value = item
    const templateType = "SKIP_PRE_POSTING"
    fetchNotificationMessage(templateType)
    willBeSentNotification.value = true
    visibleSkipPrePostingModal.value = true
  }

  const turnOffModal = () => {
    visibleSkipPrePostingModal.value = false
  }

  const skipPrePosting = () => {
    dispatch("skipPrePosting", {
      campaign_idx: routerIdx.value,
      pre_posting_idx: targetPrePosting.value.idx,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      fetchTimelines()
      fetchSnsList()
      fetchPrePostings()
      fetchInvoices()
      makeToast("primary", "사전원고 스킵에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "사전원고 스킵에 실패했습니다")
    })
  }

  return {
    visibleSkipPrePostingModal,
    getIsValid,
    turnOnModal,
    turnOffModal,
    skipPrePosting,
  }
}

const visibleRequestPrePostingSidebar = ref(false)
const isSavingRequestPrePosting = ref(false)
const isUploadingCommentFile = ref(false)
const visibleDeleteCommentFile = ref(false)
const targetCommentFile = ref({})
export function useRequestPrePosting() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()
  const { fetchPrePostings } = usePrePostings()
  const { fetchTimelines } = useTimelines()

  const { fetchNotificationMessage } = useNotification()

  const turnOnSidebar = item => {
    targetPrePosting.value = JSON.parse(JSON.stringify(item))
    const templateType = "REQUEST_PRE_POSTING"
    fetchNotificationMessage(templateType)
    willBeSentNotification.value = true
    visibleRequestPrePostingSidebar.value = true
  }

  const turnOffSidebar = () => {
    visibleRequestPrePostingSidebar.value = false
  }

  const uploadCommentFile = file => {
    isUploadingCommentFile.value = true
    uploadFile(file, "pre_posting_info", "")
      .then(fileInfo => {
        const fileIdx = fileInfo.idx
        targetPrePosting.value.comment_file_idx_list = [
          ...targetPrePosting.value.comment_file_idx_list,
          fileIdx,
        ]
        filesIndexing.value = {
          ...filesIndexing.value,
          [fileIdx]: fileInfo,
        }
      }).catch(error => {
        console.log(error)
        makeToast("danger", `${file.name} 파일 업로드에 실패했습니다`)
      }).finally(() => {
        isUploadingCommentFile.value = false
      })
  }

  const turnOnDeleteCommentFileModal = item => {
    targetCommentFile.value = item
    visibleDeleteCommentFile.value = true
  }
  const turnOffDeleteCommentFileModal = () => {
    visibleDeleteCommentFile.value = false
  }
  const deleteCommentFile = () => {
    targetPrePosting.value.comment_file_idx_list = targetPrePosting.value.comment_file_idx_list.filter(
      fileIdx => fileIdx !== targetCommentFile.value.idx,
    )
  }

  const requestPrePosting = () => {
    isSavingRequestPrePosting.value = true
    dispatch("requestPrePosting", {
      campaign_idx: routerIdx.value,
      pre_posting_idx: targetPrePosting.value.idx,
      request_comment: targetPrePosting.value.request_comment,
      comment_file_idx_list: targetPrePosting.value.comment_file_idx_list,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      fetchTimelines()
      fetchSnsList()
      fetchPrePostings()
      turnOffSidebar()
      makeToast("primary", "사전원고 수정요청에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "사전원고 수정요청에 실패했습니다")
    }).finally(() => {
      isSavingRequestPrePosting.value = false
    })
  }

  return {
    visibleRequestPrePostingSidebar,
    targetPrePosting,
    targetPrePostingCommentFiles,
    isUploadingCommentFile,
    isSavingRequestPrePosting,
    turnOnSidebar,
    turnOffSidebar,
    uploadCommentFile,
    requestPrePosting,

    visibleDeleteCommentFile,
    targetCommentFile,
    turnOnDeleteCommentFileModal,
    turnOffDeleteCommentFileModal,
    deleteCommentFile,
  }
}

const visiblePrePostingExpiredAtSidebar = ref(false)
const isSavingUpdatePrePostingExpiredAt = ref(false)
export function usePrePostingExpiredAt() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()
  const { fetchPrePostings } = usePrePostings()
  const { fetchTimelines } = useTimelines()
  const { resolveUtcEndDateTime } = useResolveValue()

  const turnOnSidebar = item => {
    targetPrePosting.value = JSON.parse(JSON.stringify(item))
    visiblePrePostingExpiredAtSidebar.value = true
  }

  const turnOffSidebar = () => {
    visiblePrePostingExpiredAtSidebar.value = false
  }

  const updatePrePostingExpiredAt = () => {
    isSavingUpdatePrePostingExpiredAt.value = true
    dispatch("updatePrePostingExpiredAt", {
      pre_posting_idx: targetPrePosting.value.idx,
      expired_at: resolveUtcEndDateTime(targetPrePosting.value.expired_at),
    }).then(() => {
      fetchTimelines()
      fetchSnsList()
      fetchPrePostings()
      turnOffSidebar()
      makeToast("primary", "사전원고 기한수정에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "사전원고 기한수정에 실패했습니다")
    }).finally(() => {
      isSavingUpdatePrePostingExpiredAt.value = false
    })
  }

  const isValid = computed(() => Boolean(targetPrePosting.value.expired_at))

  return {
    targetPrePosting,
    visiblePrePostingExpiredAtSidebar,
    isValid,
    isSavingUpdatePrePostingExpiredAt,
    turnOnSidebar,
    turnOffSidebar,
    updatePrePostingExpiredAt,
  }
}

const targetPosting = ref({})
const visibleResetPostingModal = ref(false)
export function useResetPosting() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()
  const { fetchPostings } = usePostings()
  const { fetchTimelines } = useTimelines()

  const getIsValid = item => !["SKIP", "DELETED", "CONFIRMED"].includes(item.state)

  const turnOnModal = item => {
    targetPosting.value = item
    visibleResetPostingModal.value = true
  }

  const turnOffModal = () => {
    visibleResetPostingModal.value = false
  }

  const resetPosting = () => {
    dispatch("resetPosting", {
      posting_idx: targetPosting.value.idx,
    }).then(() => {
      fetchTimelines()
      fetchSnsList()
      fetchPostings()
      makeToast("primary", "포스팅 초기화에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "포스팅 초기화에 실패했습니다")
    })
  }

  return {
    targetPosting,
    visible: visibleResetPostingModal,
    turnOnModal,
    turnOffModal,
    resetPosting,
    getIsValid,
  }
}

const visibleRegisterPostingSidebar = ref(false)
export function useRegisterPosting() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()
  const { fetchPostings } = usePostings()
  const { fetchTimelines } = useTimelines()

  const turnOnSidebar = item => {
    targetPosting.value = JSON.parse(JSON.stringify(item))
    visibleRegisterPostingSidebar.value = true
  }

  const turnOffSidebar = () => {
    visibleRegisterPostingSidebar.value = false
  }

  const getIsValid = item => !["SKIP", "DELETED", "CONFIRMED"].includes(item.state)

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(targetPosting.value.url))
  const registerPosting = () => {
    isSaving.value = true
    dispatch("registerPosting", {
      posting_idx: targetPosting.value.idx,
      url: targetPosting.value.url,
    }).then(() => {
      fetchTimelines()
      fetchSnsList()
      fetchPostings()
      turnOffSidebar()
      makeToast("primary", "포스팅 등록에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "포스팅 등록에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    targetPosting,
    visible: visibleRegisterPostingSidebar,
    isSaving,
    isValid,
    getIsValid,
    turnOnSidebar,
    turnOffSidebar,
    registerPosting,
  }
}

const visibleSkipPostingModal = ref(false)
export function useSkipPosting() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()
  const { fetchPostings } = usePostings()
  const { fetchTimelines } = useTimelines()
  const { fetchRewards } = useRewards()
  const { fetchNotificationMessage } = useNotification()
  const {
    getInvoiceList,
  } = useFetch()
  const getIsValid = item => !["SKIP", "DELETED", "CONFIRMED"].includes(item.state)
  const hasCashReward = computed(() => (rewards.value.find(r => r.price > 0) != null))

  const turnOnModal = item => {
    targetPosting.value = item
    fetchRewards().then(() => {
      const templateType = hasCashReward.value ? "SKIP_WITH_CASH" : "SKIP_NO_CASH"
      fetchNotificationMessage(templateType)
      willBeSentNotification.value = true
    })
    visibleSkipPostingModal.value = true
  }

  const turnOffModal = () => {
    visibleSkipPostingModal.value = false
  }

  const skipPosting = () => {
    dispatch("skipPosting", {
      campaign_idx: routerIdx.value,
      posting_idx: targetPosting.value.idx,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      makeToast("primary", "포스팅 스킵 성공했습니다")
      fetchTimelines()
      fetchSnsList()
      fetchPostings()
      getInvoiceList()
    }).catch(() => {
      makeToast("danger", "포스팅 스킵에 실패했습니다")
    })
  }

  return {
    targetPosting,
    visible: visibleSkipPostingModal,
    getIsValid,
    turnOnModal,
    turnOffModal,
    skipPosting,
  }
}

const visiblePostingExpiredAtSidebar = ref(false)
export function usePostingExpiredAt() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()
  const { fetchPostings } = usePostings()
  const { fetchTimelines } = useTimelines()
  const { resolveUtcEndDateTime } = useResolveValue()

  const turnOnSidebar = item => {
    targetPosting.value = JSON.parse(JSON.stringify(item))
    visiblePostingExpiredAtSidebar.value = true
  }

  const turnOffSidebar = () => {
    visiblePostingExpiredAtSidebar.value = false
  }

  const isValid = computed(() => Boolean(targetPosting.value.expired_at))
  const isSaving = ref(false)
  const updatePostingExpiredAt = () => {
    isSaving.value = true
    dispatch("updatePostingExpiredAt", {
      posting_idx: targetPosting.value.idx,
      expired_at: resolveUtcEndDateTime(targetPosting.value.expired_at),
    }).then(() => {
      fetchTimelines()
      fetchSnsList()
      fetchPostings()
      turnOffSidebar()
      makeToast("primary", "포스팅 기한수정에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "포스팅 기한수정에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    targetPosting,
    visible: visiblePostingExpiredAtSidebar,
    isValid,
    isSaving,
    turnOnSidebar,
    turnOffSidebar,
    updatePostingExpiredAt,
  }
}

const isDownloadingApplyExcel = ref(false)
export function useApplyExcel() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const downloadApplyExcel = () => {
    isDownloadingApplyExcel.value = true
    dispatch("getApplyExcel", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      const fileName = `applier_${moment().format("YYYYMMDDHHmmss")}.xlsx`
      const file = new File([response.data], fileName, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      downloadFile(file, fileName)
    }).catch(() => {
      makeToast("danger", "엑셀파일을 다운로드하는데 실패했습니다")
    }).finally(() => {
      isDownloadingApplyExcel.value = false
    })
  }

  return {
    isDownloadingApplyExcel,
    downloadApplyExcel,
  }
}
const notificationTemplates = ref([])
const selectedNotificationTemplate = ref()
export function useNotificationMessage() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const fetchNotificationTemplates = () => {
    dispatch("fetchNotificationTemplates", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      notificationTemplates.value = response.data.data.concat("직접입력")
    }).catch(() => {
      makeToast("danger", "메시지 템플릿 목록을 가져오는데 실패했습니다")
    }).finally(() => {
      selectedNotificationTemplate.value = "직접입력"
      notificationMessage.value = null
    })
  }

  const selectTemplate = value => {
    selectedNotificationTemplate.value = value
    if (value === "직접입력") {
      notificationMessage.value = null
    } else {
      notificationMessage.value = value
    }
  }

  return {
    fetchNotificationTemplates,
    selectTemplate,
  }
}

const visibleSendNotificationSidebar = ref(false)
const isNowSendNotification = ref(true)
const notificationSendAt = ref()
export function useSendNotification() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchTimelines } = useTimelines()
  const { fetchNotificationTemplates, selectTemplate } = useNotificationMessage()
  const { resolveUtcDateTime } = useResolveValue()

  const turnOnSidebar = applyItem => {
    fetchNotificationTemplates()
    targetSns.value = snsListIndexing.value[applyItem.sns_idx]
    visibleSendNotificationSidebar.value = true
  }

  const turnOffSidebar = () => {
    visibleSendNotificationSidebar.value = false
  }

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(notificationMessage.value))

  const sendNotification = () => {
    isSaving.value = true
    const sendAt = (
      isNowSendNotification.value
        ? moment().utc().format("YYYY-MM-DD HH:mm:ss")
        : resolveUtcDateTime(notificationSendAt.value)
    )
    dispatch("sendNotification", {
      campaign_idx: routerIdx.value,
      sns_idx: targetSns.value.idx,
      message: notificationMessage.value,
      send_at: sendAt,
    }).then(() => {
      fetchTimelines()
      makeToast("primary", "알림 발송에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "알림 발송에 실패했습니다")
    }).finally(() => {
      turnOffSidebar()
      isSaving.value = false
    })
  }

  return {
    visibleSendNotificationSidebar,
    notificationTemplates,
    notificationMessage,
    isNowSendNotification,
    notificationSendAt,
    selectedNotificationTemplate,
    targetSns,
    turnOnSidebar,
    turnOffSidebar,
    sendNotification,
    selectTemplate,
    isSaving,
    isValid,
  }
}

const visibleSendBulkNotificationSidebar = ref(false)
const notificationTarget = computed(() => {
  if (state.compt?.manage_step === "SELECT") {
    return "신청자"
  }
  return "선정자"
})
export function useSendBulkNotification() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchTimelines } = useTimelines()
  const { fetchNotificationTemplates, selectTemplate } = useNotificationMessage()
  const { resolveUtcDateTime } = useResolveValue()

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(notificationMessage.value))

  const turnOnSidebar = () => {
    fetchNotificationTemplates()
    visibleSendBulkNotificationSidebar.value = true
  }

  const turnOffSidebar = () => {
    visibleSendBulkNotificationSidebar.value = false
  }

  const sendBulkNotification = () => {
    isSaving.value = true
    const sendAt = (
      isNowSendNotification.value
        ? moment().utc().format("YYYY-MM-DD HH:mm:ss")
        : resolveUtcDateTime(notificationSendAt.value)
    )
    dispatch("sendBulkNotification", {
      campaign_idx: routerIdx.value,
      message: notificationMessage.value,
      send_at: sendAt,
    }).then(() => {
      fetchTimelines()
      makeToast("primary", "알림 발송에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "알림 발송에 실패했습니다")
    }).finally(() => {
      turnOffSidebar()
      isSaving.value = false
    })
  }

  return {
    visibleSendBulkNotificationSidebar,
    notificationTarget,
    notificationTemplates,
    notificationMessage,
    isNowSendNotification,
    notificationSendAt,
    selectedNotificationTemplate,
    turnOnSidebar,
    turnOffSidebar,
    sendBulkNotification,
    selectTemplate,
    isSaving,
    isValid,
  }
}

const brandSns = ref()
export function useBrandSns() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const fetchBrandSns = () => {
    dispatch("fetchBrandSns", {
      campaign_idx: routerIdx.value,
    }).then(response => {
      brandSns.value = response.data.data
    }).catch(() => {
      makeToast("danger", "브랜드SNS 정보를 가져오는데 실패했습니다")
    })
  }
  return {
    brandSns,
    fetchBrandSns,
  }
}

const hasBrandSns = computed(() => brandSns.value != null)
const visibleAddPartnershipModal = ref(false)
export function useAddPartnership() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const { fetchTimelines } = useTimelines()

  const turnOnModal = applyItem => {
    targetSns.value = snsListIndexing.value[applyItem.sns_idx]
    visibleAddPartnershipModal.value = true
  }
  const turnOffModal = () => {
    visibleAddPartnershipModal.value = false
  }

  const addPartnership = () => {
    dispatch("addPartnership", {
      campaign_idx: routerIdx.value,
      brand_sns_idx: brandSns.value.idx,
      sns_idx: targetSns.value.idx,
    }).then(() => {
      makeToast("primary", "브랜드 파트너십 요청에 성공했습니다")
      fetchTimelines()
    }).catch(() => {
      makeToast("danger", "브랜드 파트너십 요청에 실패했습니다")
    })
  }

  return {
    visibleAddPartnershipModal,
    hasBrandSns,
    targetSns,
    turnOnModal,
    turnOffModal,
    addPartnership,
  }
}

const visibleAddBulkPartnershipModal = ref(false)
export function useAddBulkPartnership() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()

  const { fetchTimelines } = useTimelines()

  const turnOnModal = () => {
    visibleAddBulkPartnershipModal.value = true
  }
  const turnOffModal = () => {
    visibleAddBulkPartnershipModal.value = false
  }

  const addBulkPartnership = () => {
    dispatch("addBulkPartnership", {
      campaign_idx: routerIdx.value,
    }).then(() => {
      makeToast("primary", "브랜드 파트너십 요청에 성공했습니다")
      fetchTimelines()
    }).catch(() => {
      makeToast("danger", "브랜드 파트너십 요청에 실패했습니다")
    })
  }

  return {
    visibleAddBulkPartnershipModal,
    hasBrandSns,
    turnOnModal,
    turnOffModal,
    addBulkPartnership,
  }
}

const visibleSetStopModal = ref(false)
export function useSetStop() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()

  const turnOnModal = () => {
    visibleSetStopModal.value = true
  }
  const turnOffModal = () => {
    visibleSetStopModal.value = false
  }

  const isValid = computed(() => !["STOP", "PENDING", "PROGRESSED"].includes(state.compt.step))

  const setStop = () => {
    dispatch("setStop", {
      campaign_idx: routerIdx.value,
    }).then(() => {
      fetchTimelines()
      getComptDetail()
      makeToast("primary", "캠페인 모집중단에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "캠페인 모집중단에 실패했습니다")
    })
  }

  return {
    visibleSetStopModal,
    isEndless,
    isValid,
    turnOnModal,
    turnOffModal,
    setStop,
  }
}

const visibleSetAdvSidebar = ref(false)
const targetAdvIdx = ref()
export function useSetAdv() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchAdvertiser } = useAdvertiser()
  const { fetchBrandSns } = useBrandSns()
  const { fetchClaims } = useClaims()
  const { fetchTimelines } = useTimelines()

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(targetAdvIdx.value))

  const turnOnSidebar = () => {
    targetAdvIdx.value = null
    visibleSetAdvSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleSetAdvSidebar.value = false
  }

  const setAdv = () => {
    isSaving.value = true
    dispatch("setAdv", {
      campaign_idx: routerIdx.value,
      adv_idx: targetAdvIdx.value,
    }).then(() => {
      fetchAdvertiser()
      fetchBrandSns()
      fetchClaims()
      fetchTimelines()
      turnOffSidebar()
      makeToast("primary", "캠페인 이관에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "캠페인 이관에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visibleSetAdvSidebar,
    targetAdvIdx,
    isSaving,
    isValid,
    turnOnSidebar,
    turnOffSidebar,
    setAdv,
  }
}

const targetInvoice = ref()
const invoiceReward = ref(0)
const invoiceAmount = computed(() => {
  const CAMPAIGN_REWARD_FEE_RATE = 0.03
  const CAMPAIGN_VAT_RATE = 0.1
  const rewardPrice = invoiceReward.value
  const tagbyFee = Math.round(rewardPrice * CAMPAIGN_REWARD_FEE_RATE)
  const tagbyVat = Math.round((rewardPrice + tagbyFee) * CAMPAIGN_VAT_RATE)
  return rewardPrice + tagbyFee + tagbyVat
})
const visibleUpdateInvoiceAmountSidebar = ref(false)
export function useUpdateInvoiceAmount() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchInvoices } = useInvoices()
  const { fetchPointList } = usePointList()
  const { fetchTimelines } = useTimelines()

  const turnOnSidebar = item => {
    targetInvoice.value = item
    invoiceReward.value = (item?.point?.apply_point ?? 0) + (item?.point?.tax ?? 0)
    visibleUpdateInvoiceAmountSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleUpdateInvoiceAmountSidebar.value = false
  }

  const getIsValidItem = item => {
    if (item.state === "INACTIVE") {
      return false
    }
    if (item.state === "DONE" && state.adv?.member_code !== "TAGGER") {
      return false
    }
    if (item.point != null && item.point?.state !== "ADDITION_EXPECTED") {
      return false
    }
    return true
  }

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(invoiceReward.value))

  const updateInvoiceAmount = () => {
    isSaving.value = true
    dispatch("updateInvoiceAmount", {
      campaign_idx: routerIdx.value,
      invoice_idx: targetInvoice.value.idx,
      reward: invoiceReward.value,
    }).then(() => {
      fetchInvoices()
      fetchPointList()
      fetchTimelines()
      makeToast("primary", "개별 리워드 금액 변경에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "개별 리워드 금액 변경에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
      turnOffSidebar()
    })
  }

  return {
    targetInvoice,
    invoiceReward,
    invoiceAmount,
    visibleUpdateInvoiceAmountSidebar,
    turnOnSidebar,
    turnOffSidebar,
    isSaving,
    isValid,
    getIsValidItem,
    updateInvoiceAmount,
  }
}

const visiblePayInvoiceModal = ref(false)
export function usePayInvoice() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getComptDetail } = useFetch()
  const { fetchInvoices } = useInvoices()
  const { fetchTimelines } = useTimelines()

  const turnOnModal = () => {
    visiblePayInvoiceModal.value = true
  }
  const turnOffModal = () => {
    visiblePayInvoiceModal.value = false
  }

  const payInvoice = () => {
    dispatch("payInvoice", {
      campaign_idx: routerIdx.value,
    }).then(() => {
      getComptDetail()
      fetchInvoices()
      fetchTimelines()
      makeToast("primary", "리워드 결제에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "리워드 결제에 실패했습니다")
    })
  }

  return {
    visiblePayInvoiceModal,
    turnOnModal,
    turnOffModal,
    payInvoice,
  }
}

const visibleCreateClaimSidebar = ref(false)
const reasonCreateClaim = ref()
export function useCreateClaim() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchClaims } = useClaims()
  const { fetchTimelines } = useTimelines()
  const { fetchNotificationMessage } = useNotification()

  const getIsValid = applyItem => {
    const claim = claimsIndexing.value[applyItem.inf_idx]
    return claim == null || ["RESOLVED"].includes(claim.step)
  }

  const turnOnSidebar = applyItem => {
    // targetSns.value = snsListIndexing.value[applyItem.sns_idx]
    state.createClaimSidebar.infIdx = applyItem.inf_idx
    reasonCreateClaim.value = null
    visibleCreateClaimSidebar.value = true
    const templateType = "CREATE_CLAIM"
    fetchNotificationMessage(templateType)
    willBeSentNotification.value = true
  }
  const turnOffSidebar = () => {
    visibleCreateClaimSidebar.value = false
  }

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(reasonCreateClaim.value))

  const createClaim = () => {
    isSaving.value = true
    dispatch("createClaim", {
      campaign_idx: routerIdx.value,
      inf_idx: state.createClaimSidebar.infIdx,
      reason: reasonCreateClaim.value,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      fetchClaims()
      fetchTimelines()
      turnOffSidebar()
      makeToast("primary", "중재 접수에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "중재 접수에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visibleCreateClaimSidebar,
    getIsValid,
    turnOnSidebar,
    turnOffSidebar,
    createClaim,
    reasonCreateClaim,
    isSaving,
    isValid,
    targetSns,
  }
}

const visibleResolveClaimModal = ref(false)
export function useResolveClaim() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchClaims } = useClaims()
  const { fetchTimelines } = useTimelines()
  const { fetchNotificationMessage } = useNotification()

  const getIsValid = applyItem => {
    const claim = claimsIndexing.value[applyItem.inf_idx]
    return claim != null && !["RESOLVED"].includes(claim.step)
  }

  const turnOnModal = applyItem => {
    state.resolveClaimModal.infIdx = applyItem.inf_idx
    const templateType = "RESOLVE_CLAIM"
    fetchNotificationMessage(templateType)
    willBeSentNotification.value = true
    visibleResolveClaimModal.value = true
  }
  const turnOffModal = () => {
    visibleResolveClaimModal.value = false
  }

  const resolveClaim = () => {
    dispatch("resolveClaim", {
      campaign_idx: routerIdx.value,
      inf_idx: state.resolveClaimModal.infIdx,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      fetchClaims()
      fetchTimelines()
      makeToast("primary", "중재 해결처리에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "중재 해결처리에 실패했습니다")
    })
  }

  return {
    visibleResolveClaimModal,
    getIsValid,
    turnOnModal,
    turnOffModal,
    resolveClaim,
  }
}

const isDownloadingApplyDetailExcel = ref(false)
const visibleDownloadApplyExcelSidebar = ref(false)
export function useApplyDetailExcel() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()

  const applyState = ref("TOTAL")
  const applyStateOptions = [
    "TOTAL", "APPLY", "SELECTED", "TEMP",
  ]

  const downloadApplyDetailExcel = async () => {
    isDownloadingApplyDetailExcel.value = true
    await fetchSnsList()
    dispatch("getApplyDetailExcelV2", {
      campaign_idx: routerIdx.value,
      apply_state: applyState.value,
    }).then(response => {
      const fileName = `applier_${moment().format("YYYYMMDDHHmmss")}.xlsx`
      const file = new File([response.data], fileName, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      downloadFile(file, fileName)
    }).catch(() => {
      makeToast("danger", "엑셀파일을 다운로드하는데 실패했습니다")
    }).finally(() => {
      isDownloadingApplyDetailExcel.value = false
    })
  }

  const turnOnSidebar = () => {
    visibleDownloadApplyExcelSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleDownloadApplyExcelSidebar.value = false
  }

  return {
    isDownloadingApplyDetailExcel,
    downloadApplyDetailExcel,
    visibleDownloadApplyExcelSidebar,
    applyState,
    applyStateOptions,
    turnOnSidebar,
    turnOffSidebar,
  }
}

const visibleSendBulkChatSidebar = ref(false)
const messageSendBulkChat = ref()
const fileSendBulkChat = ref()
const mappedSendChatRows = ref()
const statusSendBulkChat = ref("Input File")
export function useSendBulkChat() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchChatRooms } = useChats()
  const { fetchTimelines } = useTimelines()

  const turnOnSidebar = () => {
    fileSendBulkChat.value = null
    visibleSendBulkChatSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleSendBulkChatSidebar.value = false
  }

  const isSaving = ref(false)
  const isActive = computed(() => comptStep.value !== "RECRUIT")
  const isValid = computed(() => Boolean(fileSendBulkChat.value))

  const inputFile = async value => {
    fileSendBulkChat.value = value
    if (value == null) return
    statusSendBulkChat.value = "Please Send"
    const totalRows = await readXlsxFile(fileSendBulkChat.value)
    mappedSendChatRows.value = totalRows.slice(1).map(row => ({
      username: row[0],
      message: row[1],
      state: "WAIT",
    }))
  }

  const tableFields = [
    "username", "state",
  ]

  const rowClass = (item, type) => {
    if (!item || type !== "row") { return "" }
    if (item.state === "SUCCESS") { return "table-success" }
    if (item.state === "FAIL") { return "table-danger" }
    return ""
  }

  const updatedState = (username, chatState, reason = null) => {
    mappedSendChatRows.value = mappedSendChatRows.value.map(row => {
      if (row.username === username) {
        return {
          ...row,
          chatState,
          reason,
        }
      }
      return row
    })
  }

  const sendChat = async (username, message) => dispatch("sendChat", {
    campaign_idx: routerIdx.value,
    username,
    message,
  }).then(() => {
    updatedState(username, "SUCCESS")
  }).catch(e => {
    updatedState(username, "FAIL", e)
  })

  const sendBulkChat = async () => {
    const semaphore = new Semaphore(5)
    isSaving.value = true
    statusSendBulkChat.value = "Sending"
    try {
      await Promise.all(mappedSendChatRows.value.map(async row => {
        const permit = await semaphore.acquire()
        try {
          await sendChat(row.username, row.message)
        } finally {
          await permit.release()
        }
      }))
      makeToast("primary", "채팅 일괄 전송에 성공했습니다")
    } catch {
      makeToast("danger", "채팅 일괄 전송에 실패했습니다")
    } finally {
      await dispatch("createBulkChatHistory", {
        campaign_idx: routerIdx.value,
        rows: mappedSendChatRows.value.map(row => ({
          username: row.username,
          state: row.state,
          reason: row.reason,
        })),
      })
      fetchChatRooms()
      fetchTimelines()
    }
    isSaving.value = false
    fileSendBulkChat.value = null
    statusSendBulkChat.value = "Complete"
  }

  return {
    visibleSendBulkChatSidebar,
    messageSendBulkChat,
    fileSendBulkChat,
    turnOnSidebar,
    turnOffSidebar,
    sendBulkChat,
    isSaving,
    isValid,
    isActive,
    inputFile,
    tableFields,
    mappedSendChatRows,
    rowClass,
    statusSendBulkChat,
  }
}

const visibleMoveAppliersSidebar = ref(false)
export function useMoveAppliers() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchSnsList } = useSnsList()
  const { getApplyList } = useFetch()
  const { fetchPostings } = usePostings()
  const { fetchPrePostings } = usePrePostings()
  const { fetchClaims } = useClaims()
  const { fetchChatRooms } = useChats()
  const { fetchInvoices } = useInvoices()
  const { fetchGuides } = useGuides()
  const { fetchTimelines } = useTimelines()

  const mappedApplies = computed(() => state.applyList.map(a => {
    const sns = snsListIndexing.value[a.sns_idx] ?? {}
    return {
      ...a,
      sns,
    }
  }))
  const tableFields = [
    "idx", "username", "state",
  ]
  const moveAppliersTableRef = ref()
  const turnOnSidebar = () => {
    getApplyList()
    fetchSnsList()
    visibleMoveAppliersSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleMoveAppliersSidebar.value = false
  }
  const isSaving = ref(false)
  const isValid = computed(() => {
    const tableRef = moveAppliersTableRef.value
    if (tableRef == null) return false
    const tableItems = tableRef.items
    if (tableItems == null) return false
    const selectedItems = tableItems.filter((item, i) => tableRef.selectedRows[i])
    if (selectedItems.length === 0) return false
    return true
  })
  const moveAppliers = () => {
    isSaving.value = true
    const tableRef = moveAppliersTableRef.value
    const selectedApplyIdxList = tableRef.items.filter((item, i) => tableRef.selectedRows[i]).map(item => item.idx)
    dispatch("moveAppliers", {
      campaign_idx: routerIdx.value,
      apply_idx_list: selectedApplyIdxList,
    }).then(() => {
      makeToast("primary", "신청자 이동에 성공했습니다")
      fetchSnsList()
      getApplyList()
      fetchPostings()
      fetchPrePostings()
      fetchClaims()
      fetchGuides()
      fetchInvoices()
      fetchChatRooms()
      fetchTimelines()
      turnOffSidebar()
    }).catch(() => {
      makeToast("danger", "신청자 이동에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visibleMoveAppliersSidebar,
    turnOnSidebar,
    turnOffSidebar,
    moveAppliers,
    mappedApplies,
    isSaving,
    isValid,
    tableFields,
    moveAppliersTableRef,
  }
}

const visibleCancelRewardModal = ref(false)
export function useCancelReward() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchInvoices } = useInvoices()
  const { fetchTimelines } = useTimelines()

  const turnOnModal = item => {
    visibleCancelRewardModal.value = true
    targetInvoice.value = item
  }
  const turnOffModal = () => {
    visibleCancelRewardModal.value = false
  }

  const getIsValidItem = item => !["INACTIVE", "CANCEL"].includes(item.state)

  const cancelReward = () => {
    dispatch("cancelReward", {
      invoice_idx: targetInvoice.value.idx,
    })
      .then(() => {
        fetchInvoices()
        fetchTimelines()
        makeToast("primary", "리워드 취소에 성공했습니다")
      })
      .catch(() => {
        makeToast("danger", "리워드 취소에 실패했습니다")
      })
      .finally(() => { })
  }
  return {
    visibleCancelRewardModal,
    turnOnModal,
    turnOffModal,
    cancelReward,
    getIsValidItem,
  }
}

const visibleCreateGuideSidebar = ref(false)
export function useCreateGuide() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchMissions } = useMissions()
  const { fetchSnsList } = useSnsList()
  const { fetchItems } = useItems()
  const { fetchGuides, fetchGuideFiles } = useGuides()
  const { fetchNotificationMessage } = useNotification()
  const { fetchTimelines } = useTimelines()

  const guideStateOptions = [
    { label: "공통가이드", value: "MAIN" },
    { label: "개별가이드", value: "ACTIVE" },
  ]

  const guideTypeOptions = [
    { label: "전체가이드", value: "GUIDE" },
    { label: "포스팅자료", value: "POSTING" },
  ]

  const snsOptions = computed(() => (snsList.value ?? []).map(sns => ({
    label: sns.service === "NAVER_BLOG" ? sns.uid : sns.username,
    value: sns,
  })))

  const missionOptions = computed(() => (missions.value ?? []).map(mission => ({
    label: mission.idx,
    value: mission,
  })))

  const itemListOptions = computed(() => (state.items ?? []).map(item => ({
    label: `${item.title}(${item.offer_item})`,
    value: item,
  })))

  const guideState = ref()
  const guideType = ref()
  const guideFileIdx = ref()
  const guideLink = ref()
  const targetGuideMission = ref()
  const targetGuideSns = ref()
  const targetGuideItem = ref()
  const isSaving = ref(false)
  const isValid = computed(() => true)
  const isUploading = ref(false)

  const isPersonalGuide = computed(() => guideState.value === "ACTIVE")
  const isPostingGuide = computed(() => guideType.value === "POSTING")
  const missionDescription = computed(() => targetGuideMission.value?.description)
  const isLinkItem = computed(() => isPostingGuide.value && targetGuideItem.value?.offer_item === "LINK")

  const inputGuideType = value => {
    if (value === "GUIDE") {
      fetchNotificationMessage("CREATE_GUIDE")
    } else if (value === "POSTING") {
      fetchNotificationMessage("CREATE_GUIDE_POSTING")
    }
  }

  const inputFile = file => {
    isUploading.value = true
    uploadFile(file, "guide_info", "")
      .then(fileInfo => {
        guideFileIdx.value = fileInfo.idx
      }).catch(() => {
        makeToast("danger", `${file.name} 파일 업로드에 실패했습니다`)
      }).finally(() => {
        isUploading.value = false
      })
  }

  const turnOnSidebar = () => {
    visibleCreateGuideSidebar.value = true
    fetchMissions()
    fetchSnsList()
    fetchItems()
    guideState.value = null
    guideType.value = null
    guideLink.value = null
    guideFileIdx.value = null
    targetGuideItem.value = null
    targetGuideSns.value = null
    targetGuideMission.value = null

    willBeSentNotification.value = true
    fetchNotificationMessage("CREATE_GUIDE")
  }

  const turnOffSidebar = () => {
    visibleCreateGuideSidebar.value = false
  }

  const createGuide = () => {
    isSaving.value = true
    dispatch("createGuide", {
      campaign_idx: routerIdx.value,
      state: guideState.value,
      type: guideType.value,
      mission_idx: targetGuideMission.value?.idx,
      item_idx: targetGuideItem.value?.idx,
      sns_idx: targetGuideSns.value?.idx,
      guide_file_idx: guideFileIdx.value,
      guide_link: guideLink.value,
      will_be_sent_notification: willBeSentNotification.value,
    }).then(() => {
      makeToast("primary", "가이드 배정에 성공했습니다")
      fetchGuides()
      fetchGuideFiles()
      fetchTimelines()
      turnOffSidebar()
    }).catch(() => {
      makeToast("danger", "가이드 배정에 성공했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  return {
    visibleCreateGuideSidebar,
    guideState,
    guideType,
    targetGuideSns,
    targetGuideItem,
    targetGuideMission,
    guideFileIdx,
    guideLink,
    isSaving,
    isValid,
    turnOnSidebar,
    turnOffSidebar,
    createGuide,
    guideStateOptions,
    guideTypeOptions,
    snsOptions,
    isPersonalGuide,
    missionOptions,
    isPostingGuide,
    missionDescription,
    itemListOptions,
    isLinkItem,
    inputFile,
    isUploading,
    inputGuideType,
  }
}

const visibleUpdatePayDateSidebar = ref(false)
const invoicePayDate = ref()
export function useUpdatePayDate() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchInvoices } = useInvoices()
  const { fetchTimelines } = useTimelines()
  const { resolveUtcDateTime } = useResolveValue()

  const turnOnSidebar = item => {
    targetInvoice.value = item
    invoicePayDate.value = item.expected_pay_date
    visibleUpdatePayDateSidebar.value = true
  }
  const turnOffSidebar = () => {
    visibleUpdatePayDateSidebar.value = false
  }

  const getIsValidItem = item => !["INACTIVE", "DONE"].includes(item.state)

  const isSaving = ref(false)
  const isValid = computed(() => Boolean(invoicePayDate.value))

  const updatePayDate = () => {
    isSaving.value = true
    dispatch("updatePayDate", {
      invoice_idx: targetInvoice.value.idx,
      pay_date: resolveUtcDateTime(invoicePayDate.value),
    }).then(() => {
      fetchInvoices()
      fetchTimelines()
      makeToast("primary", "결제 날짜 변경에 성공했습니다")
    }).catch(() => {
      makeToast("danger", "결제 날짜 변경에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
      turnOffSidebar()
    })
  }

  return {
    visibleUpdatePayDateSidebar,
    invoicePayDate,
    turnOnSidebar,
    turnOffSidebar,
    getIsValidItem,
    isSaving,
    isValid,
    updatePayDate,
  }
}

const visibleCancelPointModal = ref(false)
export function useCancelPoint() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { fetchInvoices } = useInvoices()
  const { fetchTimelines } = useTimelines()
  const { fetchPointList } = usePointList()

  const turnOnModal = item => {
    targetInvoice.value = item
    visibleCancelPointModal.value = true
  }
  const turnOffModal = () => {
    visibleCancelPointModal.value = false
  }

  const getIsValidItem = item => item.point != null && item.point?.state === "ADDITION_EXPECTED"

  const cancelPoint = () => {
    dispatch("cancelPoint", {
      invoice_idx: targetInvoice.value.idx,
    }).then(() => {
      makeToast("primary", "포인트 지급 취소에 성공했습니다")
      fetchInvoices()
      fetchTimelines()
      fetchPointList()
    }).catch(() => {
      makeToast("danger", "포인트 지급 취소에 실패했습니다")
    }).finally(() => {
      turnOffModal()
    })
  }

  return {
    visibleCancelPointModal,
    turnOnModal,
    turnOffModal,
    getIsValidItem,
    cancelPoint,
  }
}

const visibleUpdateHasGuideModal = ref(false)
export function useUpdateHasGuide() {
  const { dispatch } = useStore(comptReStoreModule.STORE_MODULE_NAME)
  const { makeToast } = useToast()
  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()

  const turnOnModal = () => {
    visibleUpdateHasGuideModal.value = true
  }
  const turnOffModal = () => {
    visibleUpdateHasGuideModal.value = false
  }

  const updateHasGuide = () => {
    dispatch("updateHasGuide", {
      campaign_idx: routerIdx.value,
      has_guide: false,
    }).then(() => {
      makeToast("primary", "가이드 유무 변경에 성공했습니다")
      getComptDetail()
      fetchTimelines()
    }).catch(() => {
      makeToast("danger", "가이드 유무 변경에 실패했습니다")
    }).finally(() => {
      turnOffModal()
    })
  }

  return {
    visibleUpdateHasGuideModal,
    turnOnModal,
    turnOffModal,
    updateHasGuide,
  }
}

export function useTabs() {
  const fetchCaches = {}
  const execIfFirst = func => {
    const funcName = func.name
    if (fetchCaches[funcName] == null) {
      func()
      fetchCaches[funcName] = true
    }
  }
  const { getComptDetail } = useFetch()
  const { fetchTimelines } = useTimelines()
  const { fetchImgFiles } = useImgFiles()
  const { fetchRewards } = useRewards()
  const { fetchQuestions } = useQuestions()
  const { fetchItems } = useItems()
  const { fetchMissions } = useMissions()
  const { fetchItemOptions } = useItemOptions()
  const { fetchSnsList } = useSnsList()
  const { fetchSnsCategories } = useCategories()
  const { fetchClaims } = useClaims()
  const { getApplyList } = useFetch()
  const { fetchGuides, fetchGuideFiles } = useGuides()
  const { fetchDeliveries, fetchDestinations } = useDeliveries()
  const { fetchPrePostings } = usePrePostings()
  const { fetchPostings } = usePostings()
  const { fetchInvoices } = useInvoices()
  const { fetchChatRooms } = useChats()
  const { fetchScreenshotJobList } = useScreenshotJob()
  const { fetchAdvertiser } = useAdvertiser()
  const { fetchBrandSns } = useBrandSns()
  const { fetchBrandPartnershipList } = useBrandPartnershipList()
  const { fetchBrandedContents } = useBrandedContents()
  const { fetchPointList } = usePointList()
  const { fetchPrePostingExtraList } = usePrePostingExtra()
  const { fetchPostingExtraList } = usePostingExtra()
  const { fetchDifferentSnsLog } = useDifferentSnsLog()
  const tabList = [
    {
      key: "campaign",
      title: "캠페인",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchTimelines)
        execIfFirst(fetchImgFiles)
        execIfFirst(fetchRewards)
        execIfFirst(fetchItems)
        execIfFirst(fetchQuestions)
      },
    },
    {
      key: "missions",
      title: "미션",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchMissions)
        execIfFirst(fetchItems)
        execIfFirst(fetchItemOptions)
        execIfFirst(fetchQuestions)
        execIfFirst(fetchTimelines)
      },
    },
    {
      key: "applies",
      title: "신청자",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchSnsList)
        execIfFirst(fetchSnsCategories)
        execIfFirst(fetchBrandPartnershipList)
        execIfFirst(fetchClaims)
        execIfFirst(fetchBrandSns)
        execIfFirst(getApplyList)
        execIfFirst(fetchTimelines)
        execIfFirst(fetchDifferentSnsLog)
      },
    },
    {
      key: "guides",
      title: "가이드",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchItems)
        execIfFirst(fetchSnsList)
        execIfFirst(fetchMissions)
        execIfFirst(fetchGuides)
        execIfFirst(fetchGuideFiles)
        execIfFirst(fetchTimelines)
      },
    },
    {
      key: "deliveries",
      title: "배송 관리",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchSnsList)
        execIfFirst(getApplyList)
        execIfFirst(fetchDeliveries)
        execIfFirst(fetchDestinations)
        execIfFirst(fetchTimelines)
      },
    },
    {
      key: "prePostings",
      title: "사전자료 제출",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchMissions)
        execIfFirst(fetchSnsList)
        execIfFirst(getApplyList)
        execIfFirst(fetchPrePostings)
        execIfFirst(fetchPrePostingExtraList)
        execIfFirst(fetchTimelines)
      },
    },
    {
      key: "postings",
      title: "포스팅 인증",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchMissions)
        execIfFirst(fetchSnsList)
        execIfFirst(fetchPostings)
        execIfFirst(fetchPostingExtraList)
        execIfFirst(fetchBrandedContents)
        execIfFirst(fetchTimelines)
      },
    },
    {
      key: "invoices",
      title: "정산",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchSnsList)
        execIfFirst(fetchInvoices)
        execIfFirst(fetchAdvertiser)
        execIfFirst(fetchPointList)
        execIfFirst(fetchTimelines)
      },
    },
    {
      key: "etc",
      title: "기타",
      action: () => {
        execIfFirst(getComptDetail)
        execIfFirst(fetchAdvertiser)
        execIfFirst(fetchBrandSns)
        execIfFirst(fetchSnsList)
        execIfFirst(fetchChatRooms)
        execIfFirst(fetchScreenshotJobList)
        execIfFirst(fetchTimelines)
      },
    },
  ]

  return {
    tabList,
  }
}

export function others() {

}
