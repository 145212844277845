<template>
  <TagbySidebarWithButton
    :visible="visibleAddApplySidebar"
    :isSaving="isSavingAddApply"
    :isValid="isValidAddApply"
    title="신청자 추가"
    @ok="addApply"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyOverlay
      :show="isLoadingDestinations"
    >
      <SearchSns />
      <Destinations />
      <Questions />
      <Items />
    </TagbyOverlay>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyOverlay from '@/components/TagbyOverlay.vue'
import { useAddApply } from '../../viewModel'
import SearchSns from './SearchSns.vue'
import Destinations from './Destinations.vue'
import Questions from './Questions.vue'
import Items from './Items.vue'
import NotifyNot from '../NotifyNot.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyOverlay,
    SearchSns,
    Destinations,
    Questions,
    Items,
    NotifyNot,
  },
  setup() {
    const {
      visibleAddApplySidebar,
      isSavingAddApply,
      isValidAddApply,
      isLoadingDestinations,
      addApply,
      turnOffSidebar,
    } = useAddApply()

    return {
      visibleAddApplySidebar,
      isSavingAddApply,
      isValidAddApply,
      isLoadingDestinations,
      addApply,
      turnOffSidebar,
    }
  },
})
</script>
