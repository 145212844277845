<template>
  <TagbyPrimaryModal
    :visible="visibleReleasePendingModal"
    title="보류 해제"
    @hidden="turnOffModal"
    @ok="releasePending"
  >
    <NotifyNot v-if="isEndless" />
    <NotifyApply v-else-if="isRecruitStep" />
    <NotifySelect v-else />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import { useReleasePending } from '../viewModel'
import NotifySelect from './NotifySelect.vue'
import NotifyApply from './NotifyApply.vue'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    NotifySelect,
    NotifyApply,
    NotifyNot,
  },
  setup() {
    const {
      visibleReleasePendingModal,
      turnOffModal,
      releasePending,
      isEndless,
      isRecruitStep,
    } = useReleasePending()

    return {
      visibleReleasePendingModal,
      turnOffModal,
      releasePending,
      isEndless,
      isRecruitStep,
    }
  },
})
</script>
