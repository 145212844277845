<template>
  <TagbySidebarV2
    :isVisible="state.payInvoiceSidebar.isVisible"
    :isValid="isValid"
    :isSaving="state.payInvoiceSidebar.isSaving"
    @ok="payInvoice"
    @hidden="turnOffSidebar"
  >
    <div class="mb-1">
      <div class="text-info">
        * 일반 사용자 소유 캠페인의 경우, 등록된 카드로 결제를 즉시 실행합니다.
      </div>
      <div class="text-info">
        * 태거 소유 캠페인의 경우, 실제 결제는 실행하지 않으며 상태만 결제완료로 변경합니다.
      </div>
    </div>

    <div
      v-if="isTaggerCampaign"
      class="mb-1"
    >
      (해당 캠페인은 태거 소유 캠페인이므로 결제 카드를 선택하지 않습니다)
    </div>
    <div v-else>
      <BCard
        v-for="billing in state.payInvoiceSidebar.billingList"
        :key="billing.idx"
        :border-variant="getBillingCardBorder(billing.idx)"
        style="cursor: pointer"
        @click="selectBilling(billing)"
      >
        <div>
          IDX: {{ billing.idx }} <BBadge
            v-if="billing.represent"
            variant="primary"
          >
            대표
          </BBadge>
        </div>
        <div>
          카드사: {{ billing.card_name }}
        </div>
        <div>
          만료: {{ billing.card_expire_at }}
        </div>
      </BCard>
    </div>
  </TagbySidebarV2>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BCard,
  BBadge,
} from "bootstrap-vue"
import TagbySidebarV2 from "@/components/TagbySidebarV2.vue"
import state from "../../state"
import usePayInvoiceSidebar from "../../hooks/usePayInvoiceSidebar"

export default defineComponent({
  components: {
    TagbySidebarV2,
    BCard,
    BBadge,
  },
  setup() {
    const {
      turnOffSidebar,
      payInvoice,
      getBillingCardBorder,
      selectBilling,
      isValid,
      isTaggerCampaign,
    } = usePayInvoiceSidebar()
    return {
      state,
      turnOffSidebar,
      payInvoice,
      getBillingCardBorder,
      selectBilling,
      isValid,
      isTaggerCampaign,
    }
  },
})
</script>
