<template>
  <BFormGroup :label="label">
    <BFormCheckbox
      v-model="willBeSentNotification"
      class="mb-1"
    >
      <span class="text-info">
        지정된 인원에게만 알림이 전송됩니다.
      </span>
    </BFormCheckbox>
    <BFormTextarea
      :value="message"
      readonly
    />
  </BFormGroup>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import { useNotification } from '../viewModel'

export default defineComponent({
  components: {
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    label: {
      type: String,
    },
  },
  setup() {
    const {
      message,
      willBeSentNotification,
    } = useNotification()
    return {
      message,
      willBeSentNotification,
    }
  },
})
</script>
