<template>
  <TagbyModal
    :isVisible="state.addPointModal.isVisible"
    :isBusy="state.addPointModal.isSaving"
    title="포인트 지급 실행"
    @ok="addPoint"
    @hidden="turnOffModal"
  >
    <div class="mb-1">
      <div class="text-info">
        * 포인트 지급이 이뤄지지 않은 데이터에 대해 포인트 지급을 수행합니다.
      </div>
      <div class="text-danger">
        * 포인트 지급이 적립을 뜻하지는 않습니다. 포인트 적립 예정날짜에 맞춰 적립이 이뤄집니다.
      </div>
    </div>
    <BRow>
      <BCol cols="12">
        <BFormGroup>
          <BFormCheckbox v-model="state.addPointModal.isNowAdd">
            즉시 적립
          </BFormCheckbox>
        </BFormGroup>
      </BCol>
      <BCol cols="12">
        <BFormGroup label="적립 날짜">
          <TagbyDatetimeInputV3
            v-model="state.addPointModal.addExpectedAt"
            :disabled="state.addPointModal.isNowAdd"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </TagbyModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormCheckbox,
  BFormGroup,
  BRow,
  BCol,
} from "bootstrap-vue"
import TagbyModal from "@/components/TagbyModal.vue"
import state from "../../state"
import useAddPointModal from "../../hooks/useAddPointModal"
import TagbyDatetimeInputV3 from "@/components/TagbyDatetimeInputV3.vue"

export default defineComponent({
  components: {
    TagbyModal,
    TagbyDatetimeInputV3,
    BFormCheckbox,
    BFormGroup,
    BRow,
    BCol,
  },
  setup() {
    const {
      turnOffModal,
      addPoint,
    } = useAddPointModal()
    return {
      state,
      turnOffModal,
      addPoint,
    }
  },
})
</script>
