<template>
  <TagbySidebarWithButton
    title=""
    :visible="visible"
    :isSaving="isSaving"
    :isValid="true"
    @ok="updateRewardItem"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <BFormGroup label="금액">
      <BFormInput
        v-model="selectedRewardItem.price"
        number
      />
    </BFormGroup>
    <BFormGroup
      v-if="selectedRewardItem.title != null"
      label="타이틀"
    >
      <BFormInput
        v-model="selectedRewardItem.title"
      />
    </BFormGroup>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import { useRewardItems } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    NotifyNot,
    BFormGroup,
    BFormInput,
  },
  setup() {
    const {
      visible,
      isSaving,
      turnOffSidebar,
      updateRewardItem,
      selectedRewardItem,
    } = useRewardItems()

    return {
      visible,
      isSaving,
      turnOffSidebar,
      updateRewardItem,
      selectedRewardItem,
    }
  },
})
</script>
