<template>
  <BCard class="mb-1">
    <BRow>
      <BCol
        cols="12"
        md="6"
      >
        <BFormGroup label="SEARCH">
          <BFormInput
            :value="searchText"
            placeholder="검색할 내용을 입력해주세요"
            @input="$emit('inputSearchText', $event)"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol
        cols="12"
        md="6"
      >
        <BFormGroup
          label="결제상태"
        >
          <BFormCheckboxGroup
            :checked="stateFilter"
            :options="stateOptions"
            @input="$emit('inputState', $event)"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        md="6"
      >
        <BFormGroup
          label="포인트상태"
        >
          <BFormCheckboxGroup
            :checked="pointStateFilter"
            :options="pointStateOptions"
            @input="$emit('inputPointState', $event)"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
} from "bootstrap-vue"
import state from "../../state"
import getters from "../../getters"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
  },
  props: {
    searchText: {
      type: String,
    },
    stateFilter: {
      type: Array,
    },
    pointStateFilter: {
      type: Array,
    },
  },
  setup() {
    const stateOptions = [
      { text: "결제예정", value: "APPLY" },
      { text: "결제완료", value: "DONE" },
      { text: "결제실패", value: "FAIL" },
      { text: "리워드취소", value: "CANCEL" },
      { text: "삭제", value: "INACTIVE" },
    ]
    const pointStateOptions = [
      { text: "미지급", value: "NOT_YET" },
      { text: "적립예정", value: "ADDITION_EXPECTED" },
      { text: "적립완료", value: "ADDITION" },
    ]
    return {
      state,
      getters,
      stateOptions,
      pointStateOptions,
    }
  },
})
</script>
