<template>
  <TagbyActionButtonV2
    text="가이드 배정"
    variant="outline-secondary"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import router from '@/router'
import { useCreateGuide } from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const linkToAddGuidePage = () => {
      router.push({
        name: 'campaign-add-guide-view',
        params: { campaignIdx: router.currentRoute.params.idx },
      })
    }
    return {
      linkToAddGuidePage,
      ...useCreateGuide(),
    }
  },
})
</script>
