<template>
  <TagbySidebarWithButton
    :visible="visibleUpdateDeliverySidebar"
    title="송장정보 수정"
    :isSaving="isSavingUpdateDelivery"
    :isValid="true"
    @ok="updateDelivery"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <BRow>
      <BCol>
        <BFormGroup
          label="배송업체"
        >
          <VSelect
            v-model="targetDelivery.shipment_company"
            :options="shipmentCompanyList"
            :reduce="option => option.label"
          >
            <template #selected-option="{ label }">
              <span class="text-truncate overflow-hidden">
                {{ label }}
              </span>
            </template>
          </VSelect>
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup
          label="송장번호"
        >
          <BFormInput
            v-model="targetDelivery.tracking_no"
            trim
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import { useUpdateDelivery } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormTextarea,

    VSelect,
    TagbySidebarWithButton,
    NotifyNot,
  },
  setup() {
    const {
      targetDelivery,
      shipmentCompanyList,
      visibleUpdateDeliverySidebar,
      isSavingUpdateDelivery,
      updateDelivery,
      turnOffSidebar,
    } = useUpdateDelivery()

    return {
      targetDelivery,
      shipmentCompanyList,
      visibleUpdateDeliverySidebar,
      isSavingUpdateDelivery,
      updateDelivery,
      turnOffSidebar,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
