<template>
  <BCard
    :border-variant="isSelected ? 'primary': 'secondary'"
    style="cursor: pointer"
  >
    <BRow>
      <BCol
        cols="12"
        sm="4"
        md="3"
      >
        <div
          class="mr-1 w-100"
          style="cursor: pointer"
          @click="pageOpen(sns.landing_url)"
        >
          <BAvatar
            size="100%"
            variant="light"
            :src="sns.profile_img_url"
          />
        </div>
      </BCol>
      <BCol
        cols="12"
        sm="8"
        md="9"
        @click="$emit('select', sns)"
      >
        <div>
          <BBadge :variant="resolveState(sns.state)">
            {{ sns.state }}
          </BBadge>
        </div>
        <div>
          sns_idx: {{ sns.idx }}
        </div>
        <div>
          service: {{ sns.service }}
        </div>
        <div>
          username: {{ sns.username }}
        </div>
        <div>
          uid: {{ sns.uid }}
        </div>
        <div>
          follower_count: {{ sns.follower_count }}
        </div>
        <div>
          avg_likes: {{ sns.avg_likes }}
        </div>
        <div>
          avg_reach: {{ sns.avg_reach }}
        </div>
        <div>
          inf_name: {{ sns.inf_name }}
        </div>
        <div>
          inf_phone: {{ sns.inf_phone }}
        </div>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BAvatar,
  BBadge,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BCard,
    BAvatar,
    BBadge,
    BRow,
    BCol,
  },
  props: {
    sns: {
      type: Object,
    },
    isSelected: {
      type: Boolean,
    },
  },
  setup() {
    const pageOpen = url => window.open(url, '_blank')
    const resolveState = state => {
      if (state === 'ACTIVE') {
        return 'primary'
      }
      if (state === 'RELOGIN') {
        return 'info'
      }
      if (state === 'WAIT') {
        return 'warning'
      }
      return 'secondary'
    }
    return {
      pageOpen,
      resolveState,
    }
  },
})
</script>
