<template>
  <TagbyActionButtonV2
    text="신청자 엑셀 다운로드"
    variant="outline-success"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import {
  useApplyDetailExcel,
} from '../../viewModel'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useApplyDetailExcel()

    return {
      turnOnSidebar,
    }
  },
})
</script>
