<template>
  <TagbyPrimaryModal
    title="가이드 없음으로 변경"
    :visible="visibleUpdateHasGuideModal"
    @ok="updateHasGuide"
    @hidden="turnOffModal"
  >
    <NotifyNot />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import NotifyNot from './NotifyNot.vue'
import {
  useUpdateHasGuide,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    NotifyNot,
  },
  setup() {
    const {
      visibleUpdateHasGuideModal,
      turnOffModal,
      updateHasGuide,
    } = useUpdateHasGuide()

    return {
      visibleUpdateHasGuideModal,
      turnOffModal,
      updateHasGuide,
    }
  },
})
</script>
