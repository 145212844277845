<template>
  <TagbySidebarWithButton
    :visible="visibleRequestPrePostingSidebar"
    title="사전원고 수정요청"
    :isSaving="isSavingRequestPrePosting"
    :isValid="true"
    @ok="requestPrePosting"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyOverlay :show="isUploadingCommentFile">
      <BRow>
        <BCol>
          <BFormGroup
            label="수정요청을 자세하게 입력해주세요"
          >
            <BFormTextarea
              v-model="targetPrePosting.request_comment"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup
            label="파일 업로드"
          >
            <BFormFile
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @input="uploadCommentFile"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow class="mb-1">
        <BCol>
          <label>파일 목록</label>
          <BListGroup>
            <BListGroupItem
              v-for="commentFile in targetPrePostingCommentFiles"
              :key="commentFile.idx"
              class="d-flex justify-content-between align-items-center"
            >
              <div
                class="dot-ellipsis"
                style="width:80%; display:inline-block;"
              >
                {{ commentFile.file_name }}
              </div>
              <BBadge
                href="#"
                variant="danger"
                pill
                class="badge-round"
                @click="turnOnDeleteCommentFileModal(commentFile)"
              >
                <FeatherIcon
                  icon="XIcon"
                />
              </BBadge>
            </BListGroupItem>
          </BListGroup>
        </BCol>
      </BRow>
      <NotifyTarget />
    </TagbyOverlay>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormTextarea,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyOverlay from '@/components/TagbyOverlay.vue'
import {
  useRequestPrePosting,
} from '../viewModel'
import NotifyTarget from './NotifyTarget.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormTextarea,
    NotifyTarget,
  },
  setup() {
    const {
      targetPrePosting,
      isUploadingCommentFile,
      targetPrePostingCommentFiles,
      visibleRequestPrePostingSidebar,
      isSavingRequestPrePosting,
      turnOnDeleteCommentFileModal,
      uploadCommentFile,
      turnOffSidebar,
      requestPrePosting,
    } = useRequestPrePosting()

    return {
      targetPrePosting,
      isUploadingCommentFile,
      targetPrePostingCommentFiles,
      visibleRequestPrePostingSidebar,
      isSavingRequestPrePosting,
      turnOnDeleteCommentFileModal,
      uploadCommentFile,
      turnOffSidebar,
      requestPrePosting,
    }
  },
})
</script>

<style scoped>
.dot-ellipsis{
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
</style>
