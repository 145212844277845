<template>
  <TagbySidebarWithButton
    :visible="state.updateInvoiceAmountSidebar.isVisible"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="updateInvoiceAmount"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <div class="text-info">
      <div class="mb-1">
        * 일반 사용자 소유 캠페인의 경우, 결제가 완료된 상태라면 결제금액 수정을 할 수 없습니다
      </div>
      <div class="mb-1">
        * 태거 소유 캠페인의 경우, 인플루언서에게 포인트 적립완료하기 전까지는 결제금액 수정이 가능합니다
      </div>
      <div class="mb-1">
        * 결제금액 수정을 실행하면, 지급예정 상태의 포인트 금액도 수정이 됩니다
      </div>
    </div>
    <TagbyInputRow label="인플루언서에게 지급되는 리워드 포인트">
      <TagbyMoneyInput v-model="rewardPoint" />
    </TagbyInputRow>
    <TagbyInputRow label="사용자가 실제 결제하는 금액(태거의 경우 실제 결제가 일어나진 않음)">
      <TagbyMoneyInput
        :value="calculatedAmount"
        :disabled="true"
      />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BFormInput,
} from "bootstrap-vue"
import TagbySidebarWithButton from "@/components/TagbySidebarWithButton.vue"
import TagbyInputRow from "@/components/TagbyInputRow.vue"
import state from "../../state"
import useUpdateInvoiceAmount from "../../hooks/useUpdateInvoiceAmount"
import TagbyMoneyInput from "@/components/TagbyMoneyInput.vue"

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    TagbyMoneyInput,
  },
  setup() {
    const {
      rewardPoint,
      calculatedAmount,
      updateInvoiceAmount,
      turnOffSidebar,
      isValid,
      isSaving,
    } = useUpdateInvoiceAmount()

    return {
      state,
      rewardPoint,
      calculatedAmount,
      updateInvoiceAmount,
      turnOffSidebar,
      isValid,
      isSaving,
    }
  },
})
</script>
