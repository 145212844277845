<template>
  <BRow class="mb-1">
    <BCol cols="3">
      <BInputGroup size="sm">
        <BFormInput
          v-model="filterText"
          type="search"
          placeholder="Type to Search"
        />
      </BInputGroup>
    </BCol>
    <BCol cols="9">
      <BFormRadioGroup
        v-model="fieldsSelected"
        :options="fieldsOptions"
      />
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { useInvoices } from '../../viewModel'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
  },
  setup() {
    const {
      filterText,
      fieldsOptions,
      fieldsSelected,
    } = useInvoices()

    return {
      filterText,
      fieldsOptions,
      fieldsSelected,
    }
  },
})
</script>
