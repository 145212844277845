import state from './state'

export default () => {
  const turnOnModal = err => {
    state.errorReason.isVisible = true
    if (err?.response?.data != null) {
      try {
        state.errorReason.reason = JSON.stringify(err?.response?.data, null, 4)
      } catch {
        state.errorReason.reason = err?.response?.data
      }
    } else {
      state.errorReason.reason = err.toString()
    }
  }

  const turnOffModal = () => {
    state.errorReason.isVisible = false
  }

  return {
    turnOnModal,
    turnOffModal,
  }
}
