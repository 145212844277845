<template>
  <BCard>
    <BTable
      responsive
      small
      :fields="fields"
      :items="mappedApplies"
      :filter="filterText"
      style="overflow-x: visible;"
    >
      <template #cell(idx)="data">
        <a
          href="#"
          @click="turnOnSidebar(data.item)"
        >
          {{ data.value }}
        </a>
      </template>

      <template #cell(username)="data">
        <div>
          <TagbySnsColumn :sns="data.item.sns" />
          <div
            v-if="data.item.differentLog != null"
            class="text-danger"
          >
            변경 전: {{ data.item.differentLog.sns_id }}
          </div>
        </div>
      </template>

      <template #cell(state)="data">
        <div style="width:90px">
          {{ resolveApplyState(data.value) }}
        </div>
      </template>

      <template #cell(sns_info)="data">
        <div>
          follower : {{ data.item.sns.follower_count }}
        </div>
        <div>
          post : {{ data.item.sns.post_count }}
        </div>
      </template>

      <template #cell(isi_info)="data">
        <div>
          isi : {{ data.item.sns.isi }}
        </div>
        <div>
          ad_rate : {{ data.item.sns.ad_rate }}
        </div>
      </template>

      <template #cell(report_info)="data">
        <div>
          engage : {{ data.item.sns.avg_engagement }}
        </div>
        <div>
          reach : {{ data.item.sns.avg_reach }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(inf)="data">
        <InfLinkColumn :inf="data.item.inf" />
      </template>

      <template #cell(inf_phone)="data">
        {{ data.item.inf_phone }}
      </template>

      <template #cell(inf_caution)="data">
        {{ data.item.inf_caution_count }}
      </template>

      <template #cell(categories)="data">
        <SnsCategoriesColumn :categories="data.item.categories" />
      </template>

      <template #cell(claim_step)="data">
        <span>
          {{ data.item.claim.step }}
        </span>
      </template>

      <template #cell(naver_inf_idx)="data">
        {{ data.item.sns.naver_inf_idx != null }}
      </template>

      <template #cell(permission_status)="data">
        <BrandPartnershipColumn :status="data.item.partnership.permission_status" />
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            :disabled="!hasBrandSns"
            icon="UserPlusIcon"
            text="파트너십 요청"
            @click="turnOnAddPartnershipModal(data.item)"
          />
          <TagbyTableActionItem
            icon="MessageSquareIcon"
            text="알림전송"
            @click="turnOnNotificationSidebar(data.item)"
          />
          <TagbyTableActionItem
            :disabled="!getIsValidTempSelect(data.item)"
            icon="AnchorIcon"
            text="임시 선정"
            @click="turnOnTempSelectModal(data.item)"
          />
          <TagbyTableActionItem
            :disabled="!getIsValidSelect(data.item)"
            icon="UserCheckIcon"
            text="추가 선정"
            @click="turnOnSelectModal(data.item)"
          />
          <TagbyTableActionItem
            :disabled="!getIsValidQuit(data.item)"
            icon="UserXIcon"
            text="중도 이탈"
            @click="turnOnQuitModal(data.item)"
          />
          <TagbyTableActionItem
            :disabled="!getIsValidCreateClaim(data.item)"
            icon="FrownIcon"
            text="중재접수"
            @click="turnOnCreateClaimSidebar(data.item)"
          />
          <TagbyTableActionItem
            :disabled="!getIsValidResolveClaim(data.item)"
            icon="SmileIcon"
            text="중재해결"
            @click="turnOnResolveClaimModal(data.item)"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BCard,
  BTable,
  BLink,
} from "bootstrap-vue"
import {
  useApplies,
  useApplyDetail,
  useSelectApply,
  useTempSelectApply,
  useInactivateApply,
  useQuitApply,
  useSendNotification,
  useAddPartnership,
  useCreateClaim,
  useResolveClaim,
} from "../../viewModel"
import TagbySnsColumn from "@/components/TagbySnsColumn.vue"
import TagbyDatetimeColumn from "@/components/TagbyDatetimeColumn.vue"
import TagbyTableAction from "@/components/TagbyTableAction.vue"
import TagbyTableActionItem from "@/components/TagbyTableActionItem.vue"
import InfLinkColumn from "../../components/InfLinkColumn.vue"
import SnsCategoriesColumn from "../../components/SnsCategoriesColumn.vue"
import BrandPartnershipColumn from "../../components/BrandPartnershipColumn.vue"

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    TagbySnsColumn,
    TagbyDatetimeColumn,
    InfLinkColumn,
    SnsCategoriesColumn,
    TagbyTableAction,
    TagbyTableActionItem,
    BrandPartnershipColumn,
  },
  props: {
    fields: {
      type: Array,
    },
  },
  setup() {
    const {
      filterText,
      mappedApplies,
    } = useApplies()

    const resolveApplyState = state => {
      if (state === "APPLY") {
        return "지원"
      }
      if (state === "TEMP") {
        return "임시선정"
      }
      if (state === "SELECTED") {
        return "선정"
      }
      if (state === "QUIT") {
        return "이탈"
      }
      if (state === "CANCELED") {
        return "신청취소"
      }
      return "에러"
    }

    const { turnOnSidebar } = useApplyDetail()

    const {
      turnOnModal: turnOnSelectModal,
      getIsValid: getIsValidSelect,
    } = useSelectApply()

    const {
      turnOnModal: turnOnTempSelectModal,
      getIsValid: getIsValidTempSelect,
    } = useTempSelectApply()

    const {
      turnOnModal: turnOnInactivateModal,
    } = useInactivateApply()

    const {
      turnOnModal: turnOnQuitModal,
      getIsValid: getIsValidQuit,
    } = useQuitApply()

    const {
      turnOnSidebar: turnOnNotificationSidebar,
    } = useSendNotification()

    const {
      hasBrandSns,
      turnOnModal: turnOnAddPartnershipModal,
    } = useAddPartnership()

    const {
      getIsValid: getIsValidCreateClaim,
      turnOnSidebar: turnOnCreateClaimSidebar,
    } = useCreateClaim()

    const {
      getIsValid: getIsValidResolveClaim,
      turnOnModal: turnOnResolveClaimModal,
    } = useResolveClaim()

    return {
      filterText,
      mappedApplies,

      resolveApplyState,

      turnOnSidebar,

      turnOnSelectModal,
      getIsValidSelect,

      turnOnTempSelectModal,
      getIsValidTempSelect,

      turnOnInactivateModal,

      turnOnQuitModal,
      getIsValidQuit,

      turnOnNotificationSidebar,
      turnOnAddPartnershipModal,
      hasBrandSns,

      getIsValidCreateClaim,
      turnOnCreateClaimSidebar,

      getIsValidResolveClaim,
      turnOnResolveClaimModal,
    }
  },
})
</script>
