<template>
  <TagbySidebarWithButton
    :visible="visible"
    title="포스팅 제출기한 수정"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="updatePostingExpiredAt"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <BRow>
      <BCol>
        <BFormGroup label="expired_at">
          <TagbyDatetimeInputV2 v-model="targetPosting.expired_at" />
        </BFormGroup>
      </BCol>
    </BRow>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import { usePostingExpiredAt } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    TagbySidebarWithButton,
    TagbyDatetimeInputV2,
    NotifyNot,
  },
  setup() {
    const {
      targetPosting,
      visible,
      isValid,
      isSaving,
      turnOffSidebar,
      updatePostingExpiredAt,
    } = usePostingExpiredAt()

    return {
      targetPosting,
      visible,
      isValid,
      isSaving,
      turnOffSidebar,
      updatePostingExpiredAt,
    }
  },
})
</script>
