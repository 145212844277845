<template>
  <TagbyActionButtonV2
    variant="outline-secondary"
    text="제안하기"
    :isValid="true"
    :isSaving="false"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import useCreateSuggestion from '../../useCreateSuggestion'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      turnOnSidebar,
    } = useCreateSuggestion()

    return {
      turnOnSidebar,
    }
  },
})
</script>
