<template>
  <div v-if="hasItems">
    <h4>
      상품
    </h4>
    <BRow
      v-for="(data, i) in selectedItemsForAddApply"
      :key="i"
    >
      <BCol>
        <div>
          <b>{{ i+1 }}. {{ data.offer_item }}</b>
          <span> {{ data.title }}</span>
          <span v-if="data.is_reward">
            (REWARD)
          </span>
        </div>
        <div v-if="data.options.length > 0">
          <BFormGroup
            v-for="option in data.options"
            :key="option.idx"
          >
            <template #label>
              <span>옵션: {{ option.option_name }}</span>
            </template>

            <BFormInput
              v-if="option.option_type === 'INPUT'"
              v-model="option.selectValue"
            />
            <vSelect
              v-if="option.option_type === 'SELECT'"
              v-model="option.selectValue"
              :options="option.option_value"
            />
          </BFormGroup>
        </div>
        <EmptyText
          v-else
          text="-- 옵션 없음 --"
        />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useAddApply } from '../../viewModel'
import EmptyText from '../../components/EmptyText.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    EmptyText,
  },
  setup() {
    const {
      selectedItemsForAddApply,
    } = useAddApply()

    const hasItems = computed(() => selectedItemsForAddApply.value?.length > 0)

    return {
      hasItems,
      selectedItemsForAddApply,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
