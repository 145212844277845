<template>
  <TagbySidebarWithButton
    :visible="visibleUpdateInvoiceAmountSidebar"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="updateInvoiceAmount"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <TagbyInputRow label="리워드 금액">
      <BFormInput
        v-model="invoiceReward"
        number
        type="number"
      />
    </TagbyInputRow>
    <TagbyInputRow label="결제 금액">
      <BFormInput
        :value="invoiceAmount"
        number
        type="number"
        readonly
      />
    </TagbyInputRow>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import {
  useUpdateInvoiceAmount,
} from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormInput,
    NotifyNot,
  },
  setup() {
    const {
      visibleUpdateInvoiceAmountSidebar,
      invoiceReward,
      invoiceAmount,
      isSaving,
      isValid,
      updateInvoiceAmount,
      turnOffSidebar,
    } = useUpdateInvoiceAmount()

    return {
      visibleUpdateInvoiceAmountSidebar,
      invoiceReward,
      invoiceAmount,
      isSaving,
      isValid,
      updateInvoiceAmount,
      turnOffSidebar,
    }
  },
})
</script>
