<template>
  <BTable
    responsive
    small
    :fields="fields"
    :items="mappedPrePostings"
    :filter="filterText"
    style="overflow-x: visible;"
  >
    <template #cell(idx)="data">
      <a
        href="#"
        @click="turnOnSidebar(data.item)"
      >
        {{ data.value }}
      </a>
    </template>

    <template #cell(username)="data">
      <TagbySnsColumn
        v-if="data.item.sns != null"
        :sns="data.item.sns"
      />
      <div v-else>
        {{ data.item.inf.name }}
      </div>
    </template>

    <template #cell(mission_idx)="data">
      <a
        href="#"
        @click="turnOnMissionSidebar(data.item.mission)"
      >
        {{ data.value }}
      </a>
    </template>

    <template #cell(read_at)="data">
      <TagbyDatetimeColumn :value="data.value" />
    </template>

    <template #cell(created_at)="data">
      <TagbyDatetimeColumn :value="data.value" />
    </template>

    <template #cell(confirmed_at)="data">
      <TagbyDatetimeColumn :value="data.value" />
    </template>

    <template #cell(request_at)="data">
      <TagbyDatetimeColumn :value="data.value" />
    </template>

    <template #cell(expired_at)="data">
      <div>
        <TagbyDateColumn :value="data.value" />
        <div
          v-if="data.item.extra != null"
          class="d-flex"
        >
          <div>연장: </div>
          <TagbyDateColumn
            :value="data.item.extra.expired_at"
          />
        </div>
      </div>
    </template>

    <template #cell(state)="data">
      {{ resolveState(data.value) }}
    </template>

    <template #cell(inf)="data">
      <InfLinkColumn :inf="data.item.inf" />
    </template>

    <template #cell(inf_phone)="data">
      {{ data.item.inf.phone }}
    </template>

    <template #cell(inf_caution)="data">
      {{ data.item.inf.caution_count }}
    </template>

    <template #cell(actions)="data">
      <TagbyTableAction>
        <TagbyTableActionItem
          icon="SkipForwardIcon"
          text="스킵"
          @click="turnOnSkipModal(data.item)"
        />
        <TagbyTableActionItem
          icon="EditIcon"
          text="수정 요청"
          @click="turnOnRequestSidebar(data.item)"
        />
        <TagbyTableActionItem
          icon="WatchIcon"
          text="제출기한 수정"
          @click="turnOnPrePostingExpiredAtSidebar(data.item)"
        />
      </TagbyTableAction>
    </template>
  </BTable>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BTable,
} from "bootstrap-vue"
import {
  usePrePostings,
  useUpdateMission,
  usePrePostingDetail,
  useSkipPrePosting,
  useRequestPrePosting,
  usePrePostingExpiredAt,
} from "../../viewModel"
import TagbySnsColumn from "@/components/TagbySnsColumn.vue"
import InfLinkColumn from "../../components/InfLinkColumn.vue"
import useResolveValue from "@/utils/resolveValue"
import TagbyDatetimeColumn from "@/components/TagbyDatetimeColumn.vue"
import TagbyDateColumn from "@/components/TagbyDateColumn.vue"
import TagbyTableAction from "@/components/TagbyTableAction.vue"
import TagbyTableActionItem from "@/components/TagbyTableActionItem.vue"

export default defineComponent({
  components: {
    BTable,
    TagbySnsColumn,
    InfLinkColumn,
    TagbyDatetimeColumn,
    TagbyDateColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      mappedPrePostings,
      fields,
      filterText,
    } = usePrePostings()

    const { turnOnSidebar: turnOnMissionSidebar } = useUpdateMission()
    const { resolveDate } = useResolveValue()
    const { turnOnSidebar } = usePrePostingDetail()

    const {
      turnOnModal: turnOnSkipModal,
    } = useSkipPrePosting()

    const {
      turnOnSidebar: turnOnRequestSidebar,
    } = useRequestPrePosting()

    const {
      turnOnSidebar: turnOnPrePostingExpiredAtSidebar,
    } = usePrePostingExpiredAt()

    const resolveState = state => {
      switch (state) {
        case "NOT_YET":
          return "미등록"
        case "WAITING":
          return "승인대기"
        case "REQUEST":
          return "수정요청"
        case "CONFIRMED":
          return "승인완료"
        case "SKIP":
          return "스킵"
        default:
          return "-"
      }
    }

    return {
      mappedPrePostings,
      fields,
      filterText,
      turnOnMissionSidebar,
      turnOnSidebar,
      resolveDate,
      resolveState,
      turnOnSkipModal,
      turnOnRequestSidebar,
      turnOnPrePostingExpiredAtSidebar,
    }
  },
})
</script>
