<template>
  <TagbySidebarWithButton
    :visible="visiblePrePostingExpiredAtSidebar"
    title="사전원고 제출기한 수정"
    :isSaving="isSavingUpdatePrePostingExpiredAt"
    :isValid="isValid"
    @ok="updatePrePostingExpiredAt"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <BRow>
      <BCol>
        <BFormGroup label="expired_at">
          <TagbyDatetimeInputV2 v-model="targetPrePosting.expired_at" />
        </BFormGroup>
      </BCol>
    </BRow>
    <NotifyNot />
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import { usePrePostingExpiredAt } from '../viewModel'
import NotifyNot from './NotifyNot.vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    TagbySidebarWithButton,
    TagbyDatetimeInputV2,
    NotifyNot,
  },
  setup() {
    const {
      visiblePrePostingExpiredAtSidebar,
      targetPrePosting,
      isValid,
      isSavingUpdatePrePostingExpiredAt,
      turnOffSidebar,
      updatePrePostingExpiredAt,
    } = usePrePostingExpiredAt()

    return {
      visiblePrePostingExpiredAtSidebar,
      targetPrePosting,
      isValid,
      isSavingUpdatePrePostingExpiredAt,
      turnOffSidebar,
      updatePrePostingExpiredAt,
    }
  },
})
</script>
