import {
  computed,
} from "@vue/composition-api"
import moment from "moment"
import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"
import useFetch from "./useFetch"
import useErrorReason from "../useErrorReason"
import { any } from "@/utils/value"
import useResolveValue from "@/utils/resolveValue"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    resolveUtcDateTimeTz,
  } = useResolveValue()

  const {
    getTimelineList,
    getInvoiceList,
  } = useFetch()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErrorReason()

  const turnOnSidebar = items => {
    state.selectedItems = items
    state.updateInvoicePayDateSidebar.isVisible = true
  }
  const turnOffSidebar = () => {
    state.updateInvoicePayDateSidebar.isVisible = false
  }

  const isValid = computed(() => {
    if (state.selectedItems == null || state.selectedItems.length === 0) {
      return false
    }
    if (!state.updateInvoicePayDateSidebar.expectedPayDate) {
      return false
    }
    return true
  })

  const getIsAvailable = items => {
    if (items == null || items.length === 0) {
      return false
    }
    if (any(items.map(e => ["DONE", "INACTIVE"].includes(e.state)))) {
      return false
    }
    return true
  }

  const updateInvoicePayDate = () => {
    state.updateInvoicePayDateSidebar.isSaving = true
    const expectedPayDateStr = state.updateInvoicePayDateSidebar.expectedPayDate
    const expectedPayDate = moment(expectedPayDateStr)
    const kor9AMPayDate = expectedPayDate.add(9, "hours")
    const kor9AMPayDateStr = resolveUtcDateTimeTz(kor9AMPayDate)
    return api.updateInvoicePayDate({
      campaign_idx: state.idx,
      invoice_idx_list: state.selectedItems.map(e => e.idx),
      expected_pay_date: kor9AMPayDateStr,
    }).then(() => {
      makeToast("primary", "리워드 결제날짜 변경에 성공했습니다")
      turnOffSidebar()
      getInvoiceList()
      getTimelineList()
    }).catch(err => {
      makeToast("danger", "리워드 결제날짜 변경에 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.updateInvoicePayDateSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    isValid,
    getIsAvailable,
    updateInvoicePayDate,
  }
}
