import {
  ref,
  computed,
} from "@vue/composition-api"
import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"
import useFetch from "./useFetch"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getInvoiceList,
    getTimelineList,
  } = useFetch()

  const rewardPoint = ref()
  const isSaving = ref(false)

  const isValid = computed(() => rewardPoint.value != null)

  const turnOnSidebar = items => {
    state.selectedItems = items
    state.updateInvoiceAmountSidebar.isVisible = true
  }

  const turnOffSidebar = () => {
    state.updateInvoiceAmountSidebar.isVisible = false
  }

  const calculatedAmount = computed(() => {
    const CAMPAIGN_REWARD_FEE_RATE = 0.03
    const CAMPAIGN_VAT_RATE = 0.1
    const tagbyFee = Math.round(rewardPoint.value * CAMPAIGN_REWARD_FEE_RATE)
    const tagbyVat = Math.round((rewardPoint.value + tagbyFee) * CAMPAIGN_VAT_RATE)
    return rewardPoint.value + tagbyFee + tagbyVat
  })

  const invoiceIdxList = computed(() => state.selectedItems?.map(e => e.idx))

  const updateInvoiceAmount = () => {
    isSaving.value = true
    return api.updateInvoiceAmount({
      campaign_idx: state.compt.idx,
      invoice_idx_list: invoiceIdxList.value,
      amount: calculatedAmount.value,
    }).then(() => {
      makeToast("primary", "리워드 금액 변경에 성공했습니다")
      getInvoiceList()
      getTimelineList()
      turnOffSidebar()
    }).catch(() => {
      makeToast("danger", "리워드 금액 변경에 실패했습니다")
    }).finally(() => {
      isSaving.value = false
    })
  }

  const getIsAvailable = items => {
    if (items == null || items.length === 0) {
      return false
    }
    if (state.adv.member_code !== "TAGGER") {
      return items.reduce((p, c) => p && (c.state === "APPLY"), true)
    }
    return items.reduce((p, c) => p && (c.point == null || c.point.state === "ADDITION_EXPECTED"), true)
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    rewardPoint,
    calculatedAmount,
    updateInvoiceAmount,
    isValid,
    isSaving,
    getIsAvailable,
  }
}
