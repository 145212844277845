<template>
  <div>
    <UpdateInvoiceAmount />
    <AddApplySimpleRecruitSidebar />
    <PayInvoiceSidebar />
    <UpdateInvoicePayDate />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import UpdateInvoiceAmount from "./UpdateInvoiceAmount.vue"
import AddApplySimpleRecruitSidebar from "./AddApplySimpleRecruitSidebar.vue"
import PayInvoiceSidebar from "./PayInvoiceSidebar.vue"
import UpdateInvoicePayDate from "./UpdateInvoicePayDate.vue"

export default defineComponent({
  components: {
    UpdateInvoiceAmount,
    AddApplySimpleRecruitSidebar,
    PayInvoiceSidebar,
    UpdateInvoicePayDate,
  },
  setup() {

  },
})
</script>
