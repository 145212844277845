<template>
  <BCard title="브랜드 SNS 정보">
    <div v-if="brandSns != null">
      <BRow>
        <BCol cols="4">
          <BFormGroup label="IDX">
            <BFormInput
              :value="brandSns.idx"
              class="custom-hyperlink"
              readonly
              @click="linkToBrandSns"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="USERNAME">
            <BFormInput
              :value="brandSns.username"
              class="custom-hyperlink"
              readonly
              @click="linkToSnsLanding"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </div>
    <div v-else>
      <EmptyText
        text="--존재하지 않음--"
      />
    </div>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import router from '@/router'
import {
  useBrandSns,
} from '../viewModel'
import EmptyText from '../components/EmptyText.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    EmptyText,
  },
  setup() {
    const {
      brandSns,
    } = useBrandSns()

    const linkToBrandSns = () => {
      router.push({ name: 'brand-sns-detail', params: { idx: brandSns.value.idx } })
    }
    const linkToSnsLanding = () => {
      if (brandSns.value.landing_url != null) {
        window.open(brandSns.value.landing_url, '__blank')
      }
    }
    return {
      brandSns,
      linkToBrandSns,
      linkToSnsLanding,
    }
  },
})
</script>

<style>
.custom-hyperlink {
  font-weight: bold;
  color: var(--primary) !important;
}
.custom-hyperlink:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
