<template>
  <BCard
    style="height: 500px"
    no-body
  >
    <BRow class="h-100 no-gutters">
      <BCol cols="4">
        <CFilter />
        <CRooms />
      </BCol>
      <BCol
        cols="8"
        class="d-flex flex-column"
      >
        <Chats />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import CFilter from './Filter.vue'
import CRooms from './Rooms.vue'
import Chats from './Chats.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    CFilter,
    CRooms,
    Chats,
  },
  setup() {

  },
})
</script>
