<template>
  <BCard title="개별 가이드">
    <BTable
      :fields="individualGuideFields"
      :items="individualGuides"
      responsive
      small
      style="overflow-x: visible"
    >
      <template #cell(item)="data">
        <div v-if="data.item.item != null">
          {{ data.item.item.title }}({{ data.item.item.offer_item }})
        </div>
      </template>

      <template #cell(guide_file)="data">
        <DownloadFileColumn
          v-if="data.item.file != null"
          :file="data.item.file"
        />
      </template>

      <template #cell(guide_link)="data">
        <BLink
          target="_blank"
          :href="data.item.guide_link"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(sns)="data">
        <TagbySnsColumn :sns="data.item.sns" />
      </template>

      <template #cell(mission_idx)="data">
        <a
          href="#"
          @click="turnOnMissionSidebar(data.item.mission)"
        >
          {{ data.value }}
        </a>
      </template>

      <template #cell(actions)="data">
        <TagbyTableAction>
          <TagbyTableActionItem
            icon="EditIcon"
            text="수정"
            @click="turnOnGuideSidebar(data.item)"
          />
        </TagbyTableAction>
      </template>
    </BTable>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BLink,
} from 'bootstrap-vue'
import { useGuides, useUpdateMission, useUpdateGuide } from '../../viewModel'
import DownloadFileColumn from '../../components/DownloadFileColumn.vue'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BLink,
    DownloadFileColumn,
    TagbySnsColumn,
    TagbyTableAction,
    TagbyTableActionItem,
  },
  setup() {
    const {
      individualGuideFields,
      individualGuides,
    } = useGuides()

    const { turnOnSidebar: turnOnMissionSidebar } = useUpdateMission()

    const {
      turnOnSidebar: turnOnGuideSidebar,
    } = useUpdateGuide()

    return {
      individualGuideFields,
      individualGuides,
      turnOnMissionSidebar,
      turnOnGuideSidebar,
    }
  },
})
</script>
