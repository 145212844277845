<template>
  <TagbyPrimaryModal
    :visible="visibleAddPartnershipModal"
    title="브랜드 파트너십 요청"
    @ok="addPartnership"
    @hidden="turnOffModal"
  >
    <TagbySnsColumn :sns="targetSns" />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'
import {
  useAddPartnership,
} from '../viewModel'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    TagbySnsColumn,
  },
  setup() {
    const {
      visibleAddPartnershipModal,
      targetSns,
      addPartnership,
      turnOffModal,
    } = useAddPartnership()

    return {
      visibleAddPartnershipModal,
      targetSns,
      addPartnership,
      turnOffModal,
    }
  },
})
</script>
