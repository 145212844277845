<template>
  <TagbyPrimaryModal
    :visible="visibleGetLikeModal"
    title="좋아요/댓글 가져오기"
    @hidden="turnOffModal"
  >
    <BButton
      variant="outline-primary"
      class="btn-icon"
      @click="clickCopyCode"
    >
      <FeatherIcon icon="CopyIcon" />
    </BButton>
    <Prism
      language="javascript"
      :code="copiedCode"
    />
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import { BButton } from 'bootstrap-vue'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import { useGetLikeComment } from '../viewModel'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    BButton,
    Prism,
  },
  setup() {
    return {
      ...useGetLikeComment(),
    }
  },
})
</script>
