<template>
  <div>
    <ItemCard
      v-for="(item, i) in items"
      :key="i"
      :value="item"
      :fields="fields"
      @click="$emit('select', $event)"
    />
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import ItemCard from "./ItemCard.vue"

export default defineComponent({
  components: {
    ItemCard,
  },
  emits: [
    "select",
  ],
  props: {
    items: {
      type: Array,
    },
    fields: {
      type: Array,
    },
  },
  setup() {

  },
})
</script>
