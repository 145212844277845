<template>
  <TagbyPrimaryModal
    :visible="state.revertConfirmedPostingModal.isVisible"
    title="스킵 포스팅 되돌리기"
    @hidden="turnOffModal"
    @ok="revertConfirmedPosting"
  >
    <div class="text-danger">
      인플루언서의 적립예정인 포인트는 회수됩니다.
    </div>
    <div class="text-danger">
      만약 포인트 적립이 완료됐다면 기능을 실행할 수 없습니다.
    </div>
    <div class="text-danger">
      신청자에게 별다른 알림이 전송되지 않는 기능입니다.
    </div>
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyPrimaryModal from "@/components/TagbyPrimaryModal.vue"
import state from "../../state"
import useRevertConfirmedPostingModal from "../../useRevertConfirmedPostingModal"

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      turnOffModal,
      revertConfirmedPosting,
    } = useRevertConfirmedPostingModal()

    return {
      state,
      turnOffModal,
      revertConfirmedPosting,
    }
  },
})
</script>
