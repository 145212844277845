<template>
  <TagbySidebarWithButton
    title="가이드 배정"
    :visible="visibleCreateGuideSidebar"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="createGuide"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <TagbyOverlay
      :show="isUploading"
    >
      <TagbyInputRow label="가이드 타겟">
        <TagbySelect
          v-model="guideState"
          :options="guideStateOptions"
          :reduce="opt => opt.value"
        />
      </TagbyInputRow>

      <TagbyInputRow
        v-if="isPersonalGuide"
        label="SNS 선택"
      >
        <TagbySelect
          v-model="targetGuideSns"
          :options="snsOptions"
          :reduce="opt => opt.value"
        />
      </TagbyInputRow>

      <TagbyInputRow label="가이드 타입">
        <TagbySelect
          v-model="guideType"
          :options="guideTypeOptions"
          :reduce="opt => opt.value"
          @input="inputGuideType"
        />
      </TagbyInputRow>

      <TagbyInputRow
        v-if="isPostingGuide"
        label="미션 IDX"
      >
        <TagbySelect
          v-model="targetGuideMission"
          :options="missionOptions"
          :reduce="opt => opt.value"
        />
      </TagbyInputRow>

      <TagbyInputRow
        v-if="isPostingGuide"
        label="미션 상세내용"
      >
        <BFormTextarea
          :value="missionDescription"
          readonly
        />
      </TagbyInputRow>

      <TagbyInputRow
        v-if="isPostingGuide"
        label="관련 항목"
      >
        <TagbySelect
          v-model="targetGuideItem"
          :options="itemListOptions"
          :reduce="opt => opt.value"
        />
      </TagbyInputRow>

      <TagbyInputRow
        label="가이드 내용"
      >
        <BFormInput
          v-if="isLinkItem"
          v-model="guideLink"
        />
        <BFormFile
          v-else
          @input="inputFile"
        />
      </TagbyInputRow>

      <NotifyTarget v-if="isPersonalGuide" />
      <NotifySelect v-else />
    </TagbyOverlay>
  </TagbySidebarWithButton>
</template>

<script>
import {
  BFormInput,
  BFormFile,
  BFormTextarea,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import TagbyOverlay from '@/components/TagbyOverlay.vue'
import {
  useCreateGuide,
} from '../viewModel'
import NotifySelect from './NotifySelect.vue'
import NotifyTarget from './NotifyTarget.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbySelect,
    TagbyInputRow,
    TagbyOverlay,
    BFormInput,
    BFormFile,
    BFormTextarea,
    NotifySelect,
    NotifyTarget,
  },
  setup() {
    return {
      ...useCreateGuide(),
    }
  },
})
</script>
