<template>
  <BCard no-body>
    <BTable
      ref="tgTableRef"
      :items="items"
      :fields="fields"
      show-empty
      small
      responsive
      :selectable="selectable"
      select-mode="multi"
    >
      <template #head(checkbox)>
        <BFormCheckbox
          @input="inputTableCheckbox"
        />
      </template>

      <template #cell(checkbox)="data">
        <BFormCheckbox
          :checked="data.rowSelected"
          @input="inputRowCheckbox(data.item.idx, $event)"
        />
      </template>

      <template #cell(username)="data">
        <TagbySnsColumn
          v-if="data.item.sns != null"
          :sns="data.item.sns"
        />
        <div v-else>
          {{ data.item.inf.name }}
        </div>
      </template>

      <template #cell(has_ssn)="data">
        {{ data.item.has_ssn ? '비인증' : '인증' }}
      </template>

      <template #cell(point_addition_expected_at)="data">
        <TagbyDateColumn :value="data.value" />
      </template>

      <template #cell(expected_pay_date)="data">
        <TagbyDateColumn :value="data.value" />
      </template>

      <template #cell(point_created_at)="data">
        <TagbyDateColumn :value="data.value" />
      </template>

      <template #cell(inf)="data">
        <InfLinkColumn :inf="data.item.inf" />
      </template>

      <template #cell(inf_phone)="data">
        {{ data.item.inf.phone }}
      </template>

      <template #cell(inf_caution)="data">
        {{ data.item.inf.caution_count }}
      </template>

      <template #cell(state)="data">
        {{ resolveState(data.value) }}
      </template>

      <template #cell(point_state)="data">
        {{ resolvePointState(data.value) }}
      </template>

      <template #cell(amount)="data">
        {{ resolveMoney(data.value) }}
      </template>

      <template #cell(point_amount)="data">
        {{ resolveMoney(data.value) }}
      </template>
    </BTable>
    <!-- <BRow>
      <BCol
        cols="6"
        class="d-flex align-items-center justify-content-star pl-2"
      >
        <span class="text-muted">Showing {{ fromRow }} to {{ toRow }} of {{ ofRow }} entries</span>
      </BCol>
      <BCol
        :cols="6"
        class="d-flex align-items-center justify-content-end"
      >
        <BPagination
          :value="currentPage"
          :total-rows="ofRow"
          :per-page="perPage"
          align="center"
          size="sm"
          first-number
          last-number
        />
      </BCol>
    </BRow> -->
  </BCard>
</template>

<script>
import {
  defineComponent, ref, onMounted, computed,
} from "@vue/composition-api"
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
  BLink,
  BAvatarGroup,
  BAvatar,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue"
import ComptIdxColumn from "@/components/ComptIdxColumn.vue"
import TagbyDatetimeColumn from "@/components/TagbyDatetimeColumn.vue"
import TagbyDateColumn from "@/components/TagbyDateColumn.vue"
import TagbySnsColumn from "@/components/TagbySnsColumn.vue"
import TagbySnsAvatar from "@/components/TagbySnsAvatar.vue"
import TagbyConditionalLink from "@/components/TagbyConditionalLink.vue"
import state from "../../state"
import getters from "../../getters"
import useResolveValue from "@/utils/resolveValue"
import TagbyTableAction from "@/components/TagbyTableAction.vue"
import TagbyTableActionItem from "@/components/TagbyTableActionItem.vue"
import InfLinkColumn from "../../components/InfLinkColumn.vue"

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
    TagbySnsColumn,
    TagbyConditionalLink,
    TagbySnsAvatar,
    BRow,
    BCol,
    BPagination,
    BLink,
    BAvatarGroup,
    BAvatar,
    TagbyTableAction,
    TagbyTableActionItem,
    BFormCheckbox,
    TagbyDateColumn,
    BDropdown,
    BDropdownItem,
    InfLinkColumn,
  },
  props: {
    selectable: {
      type: Boolean,
    },
    fields: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  setup(props) {
    const {
      resolveMoney,
    } = useResolveValue()

    const tgTableRef = ref()

    const inputRowCheckbox = (itemIdx, checked) => {
      const tableRef = tgTableRef.value
      if (tableRef == null) return
      const i = tableRef.items.findIndex(item => item.idx === itemIdx)
      if (checked) {
        tableRef.selectRow(i)
      } else {
        tableRef.unselectRow(i)
      }
    }

    const inputTableCheckbox = checked => {
      const tableRef = tgTableRef.value
      if (tableRef == null) return
      if (checked) {
        tableRef.selectAllRows()
      } else {
        tableRef.clearSelected()
      }
    }

    const perPage = ref(10)
    const currentPage = ref(1)

    // onMounted(() => {
    //   state.postingTable.selectable = false
    // })

    const fromRow = computed(() => (currentPage.value - 1) * perPage.value + 1)
    const ofRow = computed(() => props.items?.length ?? 0)
    const toRow = computed(() => {
      const endRow = fromRow.value + perPage.value
      if (endRow < ofRow.value) {
        return endRow
      }
      return ofRow.value
    })

    const getSelectedItems = () => {
      const tableRef = tgTableRef.value
      if (tableRef == null) {
        return 0
      }
      const selectedItemList = tableRef.items.filter((_, i) => tableRef.isRowSelected(i))
      return selectedItemList
    }

    const getSelectedItemLength = () => getSelectedItems()?.length

    const resolveState = value => {
      if (value === "APPLY") {
        return "결제예정"
      }
      if (value === "DONE") {
        return "결제완료"
      }
      if (value === "FAIL") {
        return "결제실패"
      }
      if (value === "CANCEL") {
        return "리워드취소"
      }
      if (value === "INACTIVE") {
        return "삭제"
      }
      return "잘못된데이터"
    }

    const resolvePointState = value => {
      if (value === "NOT_YET") {
        return "미지급"
      }
      if (value === "ADDITION") {
        return "적립완료"
      }
      if (value === "ADDITION_EXPECTED") {
        return "적립예정"
      }
      return "잘못된데이터"
    }

    return {
      state,
      getters,
      resolveMoney,
      tgTableRef,
      inputRowCheckbox,
      inputTableCheckbox,
      perPage,
      currentPage,
      fromRow,
      ofRow,
      toRow,
      getSelectedItems,
      getSelectedItemLength,
      resolveState,
      resolvePointState,
    }
  },
})
</script>
