<template>
  <TagbyModal
    :isVisible="state.revertPointModal.isVisible"
    :isBusy="state.revertPointModal.isSaving"
    :isDanger="true"
    title="포인트 지급 취소"
    @ok="revertPoint"
    @hidden="turnOffModal"
  >
    <div class="mb-1">
      <div class="text-info">
        * "적립예정" 상태의 포인트를 취소처리합니다.
      </div>
      <div class="text-danger">
        * "적립완료"가 된 포인트는 취소하지 못합니다.
      </div>
      <div class="text-info">
        * 포스팅을 "확인완료" 처리할 때만 포인트 지급이 생성되므로 만약 포스팅을 확인완료 처리할 일이 없다면,
      </div>
      <div class="text-info">
        * 만약 포인트 지급 취소후에 따로 포스팅 확인완료 처리없이 포인트 지급이 필요하다면, 인플루언서 상페 페이지에서 "포인트 관리" 기능을 이용해주세요.
      </div>
    </div>
  </TagbyModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyModal from "@/components/TagbyModal.vue"
import state from "../../state"
import useRevertPointModal from "../../hooks/useRevertPointModal"

export default defineComponent({
  components: {
    TagbyModal,
  },
  setup() {
    const {
      turnOffModal,
      revertPoint,
    } = useRevertPointModal()
    return {
      state,
      turnOffModal,
      revertPoint,
    }
  },
})
</script>
