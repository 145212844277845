import {
  computed,
} from "@vue/composition-api"
import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"
import useFetch from "./useFetch"
import getStateOrigin from "../stateOrigin"
import useErrorReason from "../useErrorReason"
import { any } from "@/utils/value"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    getAdv,
    getTimelineList,
    getInvoiceList,
  } = useFetch()

  const {
    turnOnModal: turnOnErrorReasonModal,
  } = useErrorReason()

  const getBillingList = () => api.getBillingList({
    campaign_idx: state.idx,
  }).then(response => {
    state.payInvoiceSidebar.billingList = response.data.data
  }).catch(() => {
    makeToast("danger", "카드 목록을 가져오는데 실패했습니다")
  })

  const getBillingCardBorder = billingIdx => (state.payInvoiceSidebar.targetBilling?.idx === billingIdx ? "primary" : "secondary")

  const selectBilling = billing => {
    state.payInvoiceSidebar.targetBilling = billing
  }

  const isTaggerCampaign = computed(() => state.adv.member_code === "TAGGER")

  const isValid = computed(() => {
    if (state.selectedItems == null || state.selectedItems.length === 0) {
      return false
    }
    if (isTaggerCampaign.value) {
      return true
    }
    if (state.payInvoiceSidebar.billingList.length === 0) {
      return false
    }
    if (state.payInvoiceSidebar.targetBilling === null) {
      return false
    }
    return true
  })

  const turnOnSidebar = items => {
    getAdv()
    state.payInvoiceSidebar.billingList = []
    state.payInvoiceSidebar.targetBilling = null
    getBillingList()
    state.selectedItems = items
    state.payInvoiceSidebar.isVisible = true
  }
  const turnOffSidebar = () => {
    state.payInvoiceSidebar.isVisible = false
  }
  const payInvoice = () => {
    state.payInvoiceSidebar.isSaving = true
    return api.payInvoice({
      campaign_idx: state.idx,
      billing_idx: state.payInvoiceSidebar.targetBilling?.idx,
      invoice_idx_list: state.selectedItems.map(e => e.idx),
    }).then(() => {
      makeToast("primary", "리워드 결제 실행에 성공했습니다")
      turnOffSidebar()
      getInvoiceList()
      getTimelineList()
    }).catch(err => {
      makeToast("danger", "리워드 결제 실행에 실패했습니다")
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.payInvoiceSidebar.isSaving = false
    })
  }

  const getIsAvailable = items => {
    if (items == null || items.length === 0) {
      return false
    }
    if (any(items.map(e => ["DONE", "INACTIVE"].includes(e.state)))) {
      return false
    }
    return true
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    payInvoice,
    getBillingCardBorder,
    selectBilling,
    isValid,
    getIsAvailable,
    isTaggerCampaign,
  }
}
