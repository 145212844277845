import api from "./api"
import state from "./state"
import useToast from "@/utils/toast"

export default () => {
  const {
    makeToast,
  } = useToast()

  const getCategoryList = () => {
    api.getCategoryList().then(response => {
      state.categoryList = response.data.data
    })
  }

  const startEditCategory = item => {
    state.inputCategory.targetItem = item
  }

  const endEditCategory = () => {
    state.inputCategory.targetItem = {}
  }

  const updateCategory = () => api.updateCategory({
    campaign_idx: state.idx,
    item_idx: state.inputCategory.targetItem.idx,
    category_idx: state.inputCategory.targetItem.category_idx,
  }).then(() => {
    makeToast("primary", "카테고리를 업데이트하는데 성공했습니다")
  }).catch(() => {
    makeToast("danger", "카테고리를 업데이트하는데 실패했습니다")
  })

  const inputCategory = (itemIdx, categoryIdx) => {
    const category = state.categoryList.find(c => c.idx === categoryIdx)
    state.items = state.items.map(i => {
      if (i.idx === itemIdx) {
        return {
          ...i,
          category_idx: categoryIdx,
          category: category.class_2,
        }
      }
      return i
    })
    updateCategory()
    endEditCategory()
  }

  return {
    getCategoryList,
    inputCategory,
    startEditCategory,
    endEditCategory,
  }
}
